import { ConfigVariables } from "adapters/ports/config-variables"
import solicitudHubspotNuevo from "app/analista/page-analist/adapters/ports/solicitudes-hubspot"
import { useCognitoUserAttributes } from "app/login/adapters/useCognitoUserAttributes"
import { useCallback, useEffect, useState } from "react"
import Logout from "./logout"

interface BannerSolicitudesProps {
    estadoSolicitud: string
}  

export default function BannerSolicitudes({ estadoSolicitud }: BannerSolicitudesProps) {

    const [total, setTotal] = useState<number>(0)
    const { analista_de_credito_asignado } = useCognitoUserAttributes()    

    const fetchSolicitudes = useCallback(
        async (after: string | null = null) => {
          try {
            const params: any = {
              analista_de_credito_asignado,
            }
    
            if (after !== null) {
              params.after = Number(after)
            }
    
            const { total: newTotal } = await solicitudHubspotNuevo.obtener(params)
            setTotal(newTotal)
          } catch (error) {
            console.error("Error fetching solicitudes:", error)
          }
        },
        [analista_de_credito_asignado],
    )

    
    
      useEffect(() => {
        if (analista_de_credito_asignado) {
          fetchSolicitudes()
        }
      }, [fetchSolicitudes, analista_de_credito_asignado])
    

    return (
        <div>
            <div className="relative ml-6">
                <div className="flex items-center justify-between">
                    <div>
                        <div className="text-xs text-[#718096]">
                            Solicitudes {estadoSolicitud}
                        </div>
                        <div className="text-xl text-[#4A5568] font-semibold">
                            Solicitudes {estadoSolicitud}
                        </div>
                    </div>
                    <Logout></Logout>
                </div>
            </div>
            <div className="mt-4">
                <div className="w-full h-28 rounded-[20px] bg-cover bg-center flex flex-col justify-start" style={{ backgroundImage: `url(${ConfigVariables.BACKGROUND_ANALISTA})`, backgroundSize: 'cover' }}>
                    <div className="ml-14 mt-3 text-white font-semibold text-[40px]">{total}</div>
                    <div className="ml-14 text-white font-normal text-xl -mt-3">Solicitudes {estadoSolicitud}</div>
                </div>
            </div>
        </div>
    )
}