import { SolicitudesHubspotDTO } from "app/analista/page-analist/adapters/dto/solicitudes-hubspot-dto";
import { filterStages, FilterStageProps } from "app/analista/page-analist/infrastructure/dealstages";
import { useCallback, useEffect, useRef, useState } from "react";

type FilterProps = "stage" | "client" | "document" | null;

interface FiltroEscrituraProps {
    onSearchStages: (dealstage?: any[], ingresar_a_standby?: string ) => Promise<SolicitudesHubspotDTO[]>
}

const filterLabels = {
    "stage":  "Estado",
    "client": "Cliente / Negocio",
    "document": "NIT / CC"
}

const truncate = (str: string = "", maxLength: number = 0) => 
    str.length > maxLength ? str.slice(0, maxLength) + "..." : str;

export default function VariosFiltros({ onSearchStages }: FiltroEscrituraProps) {
    const [onClick, setonClick] = useState(false);
    const [currentFilter, setCurrentFilter] = useState<FilterProps>(null);
    const [selectedFilters, setSelectedFilters] = useState<FilterStageProps[]>([]);
    
    const containerRef = useRef(null);

    const handleStageChange = (event: React.ChangeEvent<HTMLInputElement>, stage: FilterStageProps) => {
        const { checked } = event.target;

        setSelectedFilters((prevFilters) => {
            if (checked) {
                return [...prevFilters, stage]; 
            } else {
                return prevFilters.filter((item) => item.property !== stage.property || item.value !== stage.value);
            }
        });
    };

    const handleResetFilters = ()=>{
        setSelectedFilters([]);
        setonClick(false);
        (async()=>{
            await onSearchStages();
        })()
    }

    const handleFetch = useCallback(async()=>{
        const dealstages = selectedFilters
            .filter(item => item.property === "dealstage")
            .map(item => item.value);

        const ingresar_a_standby = selectedFilters.some(item => item.property === "ingresar_a_standby") ? "true" : null;

        await onSearchStages(dealstages, ingresar_a_standby);
        setonClick(false);
    },[onSearchStages, selectedFilters])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setonClick(false);
                handleFetch()
            }
        }
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectedFilters, onSearchStages, handleFetch]);

    return (
        <div className="relative mx-1 inline-block"> 
            <div className="bg-[#EDF2F7] rounded-[10px] w-[80px] h-[36px]">
                <div className="flex justify-center items-center gap-1 h-full" onClick={() => setonClick(true)}>
                    <div className="relative">
                        <svg className="text-[#718096] w-[24px] h-[24px]" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.38589 5.66687C2.62955 4.82155 2.25138 4.39889 2.23712 4.03968C2.22473 3.72764 2.35882 3.42772 2.59963 3.22889C2.87684 3 3.44399 3 4.57828 3H19.4212C20.5555 3 21.1227 3 21.3999 3.22889C21.6407 3.42772 21.7748 3.72764 21.7624 4.03968C21.7481 4.39889 21.3699 4.82155 20.6136 5.66687L14.9074 12.0444C14.7566 12.2129 14.6812 12.2972 14.6275 12.3931C14.5798 12.4781 14.5448 12.5697 14.5236 12.6648C14.4997 12.7721 14.4997 12.8852 14.4997 13.1113V18.4584C14.4997 18.6539 14.4997 18.7517 14.4682 18.8363C14.4403 18.911 14.395 18.9779 14.336 19.0315C14.2692 19.0922 14.1784 19.1285 13.9969 19.2012L10.5969 20.5612C10.2293 20.7082 10.0455 20.7817 9.89802 20.751C9.76901 20.7242 9.6558 20.6476 9.583 20.5377C9.49975 20.4122 9.49975 20.2142 9.49975 19.8184V13.1113C9.49975 12.8852 9.49975 12.7721 9.47587 12.6648C9.45469 12.5697 9.41971 12.4781 9.37204 12.3931C9.31828 12.2972 9.2429 12.2129 9.09213 12.0444L3.38589 5.66687Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {selectedFilters.length >= 1 &&
                            <sup className="absolute text-[7px] pt-[5px] font-bold top-0 left-[15px] bg-[tomato] w-[9px] h-[9px] rounded-full text-white text-center">1</sup>
                        }
                    </div>
                    <div className="text-[#718096] text-sm">Filtros</div>
                </div>
                {onClick && (
                    <div ref={containerRef} className="absolute top-full mt-[10px] left-1/2 transform -translate-x-1/2 w-[400px] h-[320px] bg-white shadow-2xl rounded-[30px] z-50 outline:none flex">
                        <div className={`absolute top-[30px] right-[40px] text-xs font-semibold border-[2px] rounded-full w-[100px] h-[24px] px-[8px] py-[3px] cursor-pointer ${selectedFilters.length >= 1 ? "text-[#390094] border-[#390094]": "text-[#A0AEC0] border-[#A0AEC0]"}`} onClick={handleResetFilters}>
                            Limpiar filtros
                        </div>
                        {!currentFilter && (
                            <div className="m-[30px] w-full">
                                <div className="flex items-center justify-between mb-5">
                                    <div className="text-md text-[#171923] font-semibold">Filtros</div>
                                </div>
                                <div className="flex justify-between items-center h-[61px] border-b-[1px] border-[#E9EDF7] text-sm text-[#171923] font-normal cursor-pointer" onClick={()=>setCurrentFilter("stage")}>
                                    Estado
                                    <div>
                                        <span className="text-[#A3AED0]">
                                            {truncate(selectedFilters[0]?.label, 20)}
                                            {selectedFilters?.length > 1 ? `, +${selectedFilters?.length - 1}` : ""}
                                        </span>
                                        <svg className="text-[#171923] w-[24px] h-[24px] inline-block" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="hidden flex justify-between items-center h-[61px] border-b-[1px] border-[#E9EDF7] text-sm text-[#171923] font-normal cursor-pointer" onClick={()=>setCurrentFilter("client")}>
                                    Cliente / Negocio
                                    <svg className="text-[#171923] w-[24px] h-[24px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="hidden flex justify-between items-center h-[61px] border-b-[1px] border-[#E9EDF7] text-sm text-[#171923] font-normal cursor-pointer" onClick={()=>setCurrentFilter("document")}>
                                    NIT / CC
                                    <svg className="text-[#171923] w-[24px] h-[24px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        )}
                        {currentFilter && (
                            <div className="m-[30px] w-full">
                                <div className="flex items-center justify-between">
                                    <div className="text-md text-[#171923] font-semibold cursor-pointer" onClick={()=>setCurrentFilter(null)}>
                                        <svg className="inline-block mr-4" xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                                            <path d="M7 13L1 7L7 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <span>{filterLabels[currentFilter]}</span>
                                    </div>
                                </div>
                                
                                {currentFilter === "stage" && (
                                    <div className="px-2 w-full mt-2">
                                        {
                                            filterStages.map((stage)=>
                                                <div className="flex items-center h-[61px] border-b-[1px] border-[#E9EDF7] text-sm text-[#171923] font-normal cursor-pointer" key={stage.value}>
                                                    <input 
                                                        id={`${stage.label}_${stage.value}`}
                                                        className="relative multistep-checkbox appearance-none border-2 border-[#A0AEC0] rounded w-[19px] h-[19px] checked:bg-[#390094] checked:border-[#390094] cursor-pointer bg-white mr-2" 
                                                        type="checkbox" 
                                                        value={stage.value} 
                                                        checked={selectedFilters.some((item) => item.property === stage.property && item.value === stage.value)}
                                                        onChange={(e) => handleStageChange(e, stage)}/>
                                                    <label htmlFor={`${stage.label}_${stage.value}`}><span className="text-[#171923]">{stage.label}</span></label>
                                                </div>
                                            )
                                        }
                                    </div>
                                )}
                            </div>    
                        )} 
                        {currentFilter &&
                            <button className="absolute bottom-[15px] right-[30px] w-auto h-[40px] font-sans text-center py-1 shadow-md shadow-gray-900/10 rounded-3xl text-md font-semibold normal-case px-7 bg-[#390094] text-white" onClick={handleFetch}>Filtrar</button>
                        }
                    </div>
                )}
            </div>
        </div>
    );
}
