import { useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useHandleBeforeUnload = (isAnyFieldFilled: boolean, callback?: ()=>void )=>{
    const navigate = useNavigate();
    const { productIndex } = useParams();

    const handleOpenModal = useCallback(() => {
        if (isAnyFieldFilled && callback) {
            callback();
        } else {
            navigate(`/carga-documentos/${productIndex}`);
        }
    }, [isAnyFieldFilled, navigate, productIndex, callback]);

    useEffect(()=>{
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isAnyFieldFilled) {
                event.preventDefault();
                handleOpenModal();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    },[isAnyFieldFilled, handleOpenModal]);

    return { handleOpenModal };
}