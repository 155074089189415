import { useState, useEffect } from 'react';
import { userPool } from './userPoolCognito';
import { useNavigate } from "react-router-dom";

interface Attribute {
  Name: string;
  Value: string | boolean;
}

export interface extractAttributeProps {
  userID?: string | null;
  email?: string | null;
  cognitoUUID?: string | null;
  role?: string | null;
  firstLogin?: string | null;
  analista_de_credito_asignado?: string
  nameUser?: string
}

export const extractAttribute = (attributes: Attribute[], name: string, defaultValue: any = null): any => {
  const attr = attributes.find(attr => attr.Name === name);
  return attr ? attr.Value : defaultValue;
};

export const useCognitoUserAttributes = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [person_type, setPersonType] = useState<string | null>(null);
  const [identity_type, setIdentityType] = useState<string | null>(null);
  const [identity_number, setIdentityNumber] = useState<string | null>(null);
  const [cognitoUUID, setCognitoUUID] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [firstLogin, setFirstLogin] = useState<string | null>("false");
  const [analista_de_credito_asignado, setAnalistaDeCreditoAsignado] = useState<string |null>(null);
  const [nameUser, setNameUser] = useState<string |null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession((err: any) => {
        if (err) {
          console.error("Error getting session:", err);
          window.location.href = "/#revoked-token";
          return;
        }
        cognitoUser.getUserAttributes((err: any, attributes: any) => {
          if (err) {
            console.error("Error getting user attributes:", err);
            window.location.href = "/#revoked-token";
            return;
          }
          const { userID, email, cognitoUUID, role, firstLogin, analista_de_credito_asignado, nameUser }: extractAttributeProps = {
            userID: extractAttribute(attributes, 'custom:userID', null),
            email: extractAttribute(attributes, 'email', null),
            cognitoUUID: extractAttribute(attributes, 'sub', null),
            role: extractAttribute(attributes, 'custom:role', ''),
            firstLogin: extractAttribute(attributes, 'custom:firstLogin', false), 
            analista_de_credito_asignado: extractAttribute(attributes, 'custom:user_id', null),
            nameUser: extractAttribute(attributes, 'custom:nameUser', null)
          };
          setEmail(email);
          setCognitoUUID(cognitoUUID);
          setRole(role);
          setFirstLogin(firstLogin);
          setAnalistaDeCreditoAsignado(analista_de_credito_asignado);
          setNameUser(nameUser);

          if (userID && userID.includes('/')) {
            const [person_type, identity_type, identity_number] = userID.split('/');
            setPersonType(person_type);
            setIdentityType(identity_type);
            setIdentityNumber(identity_number);
          }
        });
      });
    }else{
      navigate("/");
    }
  }, [navigate]);

  return { person_type, identity_type, identity_number, email, cognitoUUID, role, firstLogin, analista_de_credito_asignado, nameUser };
};
