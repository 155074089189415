import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { ConfigVariables } from '../../../adapters/ports/config-variables';

const userPoolId = ConfigVariables.COGNITO_USER_POOL_ID;
const clientId = ConfigVariables.COGNITO_CLIENT;

const poolData = {
    UserPoolId: userPoolId,
    ClientId: clientId
};

export const userPool = new CognitoUserPool(poolData);