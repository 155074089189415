import { ConfigVariables } from "adapters/ports/config-variables";
import React from "react";

interface SideBarProps {
    isOpen: boolean
    onToggle: () => void
  }
  
  const SideBar: React.FC<SideBarProps> = ({ isOpen, onToggle }) => {
    const [activeMenu, setActiveMenu] = React.useState("solicitudes-revisar")
  
    const menuItems = [
        {
            key: "solicitudes-revisar",
            path: "/solicitudes-revisar",
            icon: ConfigVariables.SOLICITUDES_REVISAR,
            activeIcon: ConfigVariables.SOLICITUDES_POR_REVISAR_ACTIVO,
            label: "Solicitudes por revisar",
        },
        {
            key: "solicitudes-aprobadas",
            path: "/solicitudes-aprobadas",
            icon: ConfigVariables.SOLICITUDES_APROBADAS,
            activeIcon: ConfigVariables.SOLICITUDES_APROBADAS_ACTIVO,
            label: "Solicitudes aprobadas",
        },
        {
            key: "solicitudes-rechazadas",
            path: "/solicitudes-rechazadas",
            icon: ConfigVariables.SOLICITUDES_RECHAZADAS,
            activeIcon: ConfigVariables.SOLICITUDES_RECHAZADAS_ACTIVO,
            label: "Solicitudes rechazadas",
        }
    ];

    return (
        <div className="h-full">
            <div className={`fixed left-5 top-5 bottom-5 ${isOpen ? "w-[65px]" : "w-[295px]"} bg-white shadow-2xl rounded-[24px]`}>
                <div className="flex flex-col items-center">
                    <img className="mt-9" src={isOpen ? ConfigVariables.IMAGEN_LOGO_FINMAQ_MOBILE : ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} alt="Logo" style={{ width: isOpen ? "43px" : "141px", height: "39px" }}/>
                </div>
                <div className="mt-9 mx-3">
                    <div className="h-[1px] bg-[#E9EDF7] w-full"></div>
                    <div className="mt-9">
                        {menuItems.map((item) => {
                            const isActive = activeMenu === item.key;
                            return (
                                <a
                                    key={item.key}
                                    href={item.path}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setActiveMenu(item.key);
                                    }}
                                    className={`flex ${isOpen ? "flex-col items-center" : "items-center"} mt-6 relative`}
                                >
                                    {isActive && <div className="absolute right-0 h-[36px] w-[4px] -mt-1 bg-[#390094] rounded-lg ml-5"></div>}
                                    
                                    <img
                                        src={isActive ? item.activeIcon : item.icon}
                                        alt={item.label}
                                        className="w-[24px] h-[24px] lg:ml-3 mr-4"
                                    />
                                    
                                    {!isOpen && (
                                        <div className={`text-[14px] mt-1 ${isActive ? "font-semibold text-[#390094]" : "font-normal text-[#718096]"}`}>
                                            {item.label}
                                        </div>
                                    )}
                                </a>
                            );
                        })}
                    </div>
                </div>
                <svg onClick={onToggle} className="absolute bottom-5 right-5 cursor-pointer text-[#718096] w-[24px] h-[24px]" width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d={isOpen ? "M4 12H20M20 12L14 6M20 12L14 18" : "M19 12H5M5 12L12 19M5 12L12 5"} stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    );
}

export default SideBar
