import { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Button } from "@material-tailwind/react";
import CardUploadFile from "app/components-finmaq/cardUploadFile";
import ModalGuardadoParcial from "app/components-finmaq/modal-guardado-parcial";
import Card from "components/card/";
import FormularioPreguntasInfoMaquina from "app/formulario-info-maquina/formulario/formulario-preguntas-info-maquina";
import NavBar from "app/components-finmaq/nav-bar";
import formularioInfoMaquinaService from "app/formulario-info-maquina/adapters/ports/http-formulario-info-maquina";
import useDocumentState from "app/carga-documentos/utilities/useDocumentState";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import { useGetContactWithProducts } from "app/carga-documentos/utilities/useGetContactWithProducts";
import { FormularioInfoMaquinaPreguntasDTO } from "app/formulario-info-maquina/adapters/dto/formulario-info-maquina-preguntas-dto";
import { useCognitoUserAttributes } from "app/login/adapters/useCognitoUserAttributes";
import { handleTagManagerSteps } from "app/utilities/gtm";
import { useHandleBeforeUnload } from "app/carga-documentos/utilities/useHandleBeforeUnload";
import { useHandleProductDone } from "app/carga-documentos/utilities/useHandleProductDone";
import DropdownSelect from "react-dropdown-select";
import plazos from "app/formulario-info-maquina/utilities/plazos";

 
export default function FormularioInfoMaquina() {
    const [ isDataPreloaded, setIsDataPreloaded ] = useState(false);
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ modelo, setModelo ] = useState<FormularioInfoMaquinaPreguntasDTO>(FormularioInfoMaquinaPreguntasDTO.instancia());
    const [ isFormValid, setIsFormValid ] = useState(false);
    const [ isFileSaved, setIsFileSaved ] = useState(false);
    const { person_type, identity_type, identity_number } = useCognitoUserAttributes();
    const navigate = useNavigate();
    const { productIndex } = useParams();
    const { cognitoUUID } = useCognitoUserAttributes();
    const { currentProduct } = useGetContactWithProducts();
    const productUUID = currentProduct["product_uuid" as keyof typeof currentProduct];
    const currentDocuments = currentProduct["documents" as keyof typeof currentProduct];
    const [plazo, setPlazos] = useState<number>(48);
 
    const { fileStatus, setFileStatus, documentUrl:quotationUrl, setDocumentUrl:setQuotationUrl, handleInput, handleSubmit:handleSubmitDocument } = useDocumentState();
    
    const additional_income = (currentProduct as unknown as {
        finance: {
            additional_income: boolean
        }
    }).finance?.additional_income;

    const request_reason = (currentProduct as unknown as {
        financial_product: {
            request_reason: string
        }
    }).financial_product?.request_reason;

    const financial_amount = ( currentProduct as unknown as {
        financial_product: {
            financial_amount: string
        }
    }).financial_product?.financial_amount;

    const credit_term = ( currentProduct as unknown as {
        financial_product: {
            credit_term: number
        }
    }).financial_product?.credit_term

    const isAnyFieldFilled = useMemo(() => {
        if (isDataPreloaded && fileStatus !== uploadStatus.READY) return false;
        
        const isAdditionalIncomeValid = modelo.product?.finance?.additional_income !== undefined;
        const isRequestReasonValid = modelo.product?.financial_product?.request_reason !== undefined;
        const isFinancialAmount = modelo.product?.financial_product?.financial_amount !== undefined;
        const isCreditTerm = modelo.product?.financial_product.credit_term !== undefined
        const hasFilledFields = isAdditionalIncomeValid || isRequestReasonValid || isFinancialAmount || isCreditTerm;
    
        return (
            (fileStatus === uploadStatus.READY || hasFilledFields) && !isFileSaved
        );
    }, [fileStatus, modelo, isFileSaved, isDataPreloaded]);

    const product_state = (currentProduct as unknown as { financial_product: {
        product_state: string
    } }).financial_product?.product_state;
  
    const { handleOpenModal } = useHandleBeforeUnload(isAnyFieldFilled, ()=> setIsModalOpen(true));

    useHandleProductDone(product_state);

  
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleSubmitDocument("COTIZACION", async()=>{
            try {
                await formularioInfoMaquinaService.enviar(modelo, person_type, identity_type,identity_number);
                setIsFileSaved(true);
                handleTagManagerSteps({
                    action: "complete",
                    cognitoUserId: cognitoUUID,
                    productId: productUUID
                })
            } catch (error) {
                console.error(error)
            }
        })
    };
  
    const handleSaveAndExit = async () => {
        try {
            if (fileStatus === uploadStatus.READY || fileStatus === uploadStatus.ERROR) {
                await handleSubmitDocument("COTIZACION", async () => {
                    setIsFileSaved(true);
                });
            }

            await formularioInfoMaquinaService.enviar(modelo, person_type, identity_type, identity_number);
    
            setIsModalOpen(false);
            navigate(`/carga-documentos/${productIndex}`);
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (credit_term) {
            setPlazos(credit_term)
        }
    }, [credit_term])
  
    useEffect(() => {
        if (typeof additional_income === "boolean" || request_reason || financial_amount || credit_term)
            setIsDataPreloaded(true)
    
        setModelo(() => {
            const newModel = {
            product: {
                product_id: productUUID,
                finance: {
                additional_income: typeof additional_income === "boolean" ? additional_income : undefined,
                },
                financial_product: {
                request_reason: request_reason,
                financial_amount: financial_amount,
                credit_term: credit_term || 48,
                },
            },
            }
            return newModel
        })
    }, [additional_income, request_reason, productUUID, financial_amount, credit_term])

    useEffect(()=>{
        if(currentDocuments?.quotation?.file_path) {
            setQuotationUrl(currentDocuments.quotation.file_path);
            setFileStatus(uploadStatus.PRELOADED);
        }
    },[currentDocuments, productIndex, setQuotationUrl, setFileStatus])
  
    useEffect(() => {
        const allFieldsFilled = modelo.product?.finance?.additional_income !== undefined && modelo.product?.financial_product?.request_reason && modelo.product?.financial_product?.financial_amount && productUUID && (fileStatus === uploadStatus.READY || fileStatus === uploadStatus.PRELOADED)
        setIsFormValid(allFieldsFilled);
    }, [modelo, fileStatus, productUUID]);

    useEffect(()=>{
        if(cognitoUUID && productUUID){
            handleTagManagerSteps({
                action: "enter",
                cognitoUserId: cognitoUUID,
                productId: productUUID
            })
        }
    },[cognitoUUID, productUUID])
  
    const handleSelectChange = (value: any) => {
        const credit_term = value ? Number(value) : null
        setPlazos(credit_term)
        setModelo((prevModelo) => {
            const updatedModel = {
            ...prevModelo,
            product: {
                ...prevModelo.product,
                financial_product: {
                ...prevModelo.product.financial_product,
                credit_term: credit_term || 48,
                },
            },
            }
            return updatedModel
        })
    }
 
    return (
        <div className='w-full flex flex-col min-h-screen'>
            <form className='w-full min-h-screen lg:w-auto lg:h-auto lg:flex flex-grow flex flex-col' onSubmit={handleSubmit}>
                <div className='mt-20 mb-[10px] lg:mb-[40px] flex justify-center flex-grow'>
                    <NavBar />
                    <Card className='lg:mt-4 w-full lg:w-auto min-h-screen lg:min-h-screen w-full lg:ml-5 lg:mr-5 rounded-t-[20px] lg:rounded-t-3xl bg-white'>
                        <div className="mt-7 mr-5 ml-5 lg:mt-14 lg:mr-auto lg:ml-auto">
                            <div className="mt-7 lg:ml-28 hidden lg:block">
                                <p className="flex lg:inline-block lg:text-[#718096]">Solicitud de crédito /<span className="text-start text-[#390094] lg:text-[#000000] font-bold lg:font-normal text-xl lg:text-base ml-2 lg:ml-auto"> Información de la máquina</span></p>
                            </div>
                            <div className="flex lg:ml-[65px] lg:mt-8">
                                {isModalOpen && (
                                    <ModalGuardadoParcial onClose={()=>setIsModalOpen(false)} onHandleProp={handleSaveAndExit} />
                                )}
                                <button type="button" aria-label="volver a la pagina principal" onClick={handleOpenModal}>
                                    <svg className="text-[#390094]" width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <p className="text-start text-[#390094] font-bold lg:font-semibold text-xl lg:text-2xl ml-[8px] lg:ml-[15px]">Información de la máquina</p>
                            </div>
                            <p className="mt-6 lg:mt-1 text-md text-[#000000] lg:ml-[110px]">Responda las siguientes preguntas y suba la cotización que le envió el distribuidor de la máquina que desea financiar.</p>
                            <div className="mt-12 mb-6 lg:ml-16 lg:mt-7">
                                <div className="lg:grid lg:grid-cols-2 lg:gap-28 mt-12 lg:ml-12 lg:mr-28 mb-5">
                                    <div className="mb-16 lg:mb-0">
                                        <FormularioPreguntasInfoMaquina formulario={modelo} setFormulario={setModelo} />
                                    </div>
                                    <div>

                                    <div className="relative mb-10">
                                        <div className='flex items-center'>
                                            <div className={`text-lg text-[#171923] font-semibold`}>Plazo de crédito</div>
                                        </div>
                                        <div className="relative mb-[38px]">
                                            <div className="text-[#171923] text-sm font-normal">Valor solicitado:
                                                { " " + new Intl.NumberFormat("es-CO", {
                                                    style: "currency", 
                                                    currency: "COP", 
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }).format(Number(modelo.product?.financial_product?.financial_amount))}
                                            </div>
                                            <div className="relative bg-white pointer-events-none transition-all duration-300 text-[#171923] border-white text-[#a0aec2]">
                                                <label className={`absolute left-[11px] transition-all duration-300 pointer-events-none
                                                    ${plazo ? "top-[-10px] text-xs text-[#a0aec2] bg-white z-10" : "hidden"}`}>
                                                    <span className="px-1">Seleccione el plazo del crédito en meses:</span>
                                                </label>
                                                <DropdownSelect
                                                    options={plazos}
                                                    onChange={(values) => handleSelectChange(values[0]?.value)}
                                                    placeholder={"Plazo de crédito en meses:"}
                                                    dropdownPosition="bottom"
                                                    values={plazo !== null ? [{ value: plazo, label: plazo.toString() }] : []}
                                                    className="peer"
                                                    style={{
                                                        borderRadius: "10px",
                                                        height: "40px",
                                                        marginTop: "23px",
                                                        paddingLeft: "11px",
                                                        color: "#000"
                                                    }}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-lg font-semibold text-[#000000]">Cotización</p>
                                        <CardUploadFile
                                            fileLimit={4928307}
                                            fileStatus={fileStatus}
                                            fileName={quotationUrl}
                                            className="w-full"
                                            handleInput={handleInput}
                                            handleSubmit={handleSaveAndExit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:hidden mt-auto flex flex-col">
                            <Button
                                placeholder={""}
                                className={`w-auto mb-5 h-auto ml-10 mr-10 rounded-3xl text-md font-semibold normal-case ${isFormValid ? "bg-[#390094] text-white" : "bg-[#CBD5E0] text-[#718096]"}`}
                                type='submit'
                                disabled={!isFormValid}>
                                Guardar
                            </Button>
                        </div>
                    </Card>
                    <footer className='hidden lg:block z-50 fixed bottom-0 h-[70px] bg-gradient-to-b from-white via-[#E9EEF4] to-[#E9EEF4] w-full shadow-none border-2'>
                        <div className='text-end h-full rounded-b-[24px] bg-white ml-[18px] mr-[18px] shadow-md'>
                            <div className='mt-auto flex flex-col lg:block lg:mr-[106px]'>
                            <Button
                                className={`w-auto ${isFormValid ? "bg-[#390094] text-white" : "bg-[#CBD5E0] text-[#718096]"} mt-[8px] h-auto rounded-3xl text-md font-semibold normal-case`}
                                type='submit'
                                disabled={!isFormValid}>
                                Guardar
                            </Button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
        </div>
    );
}