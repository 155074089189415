import { useState } from "react"
import FormularioDatosDelCliente from "../scoring/formularios/datos-del-cliente"
import FormularioHistorialyRiesgo from "../scoring/formularios/historal-riesgo"
import FormularioInversionYDistribuidor from "../scoring/formularios/inversion-y-distribuidor"


const steps = [
  { label: "Datos del cliente", component: "datos-del-cliente" },
  { label: "Historial y Riesgo", component: "historal-riesgo" },
  { label: "Inversión y Distribuidor", component: "inversion-y-distribuidor" },
]

interface StepperScoringProps {
  formulario: any
  setFormulario: any
  editable: boolean
}

export default function StepperScoring({ formulario, setFormulario, editable }: StepperScoringProps) {
  const [currentStep, setCurrentStep] = useState(0)

  const renderStepContent = () => {
    const currentComponent = steps[currentStep].component

    switch (currentComponent) {
      case "datos-del-cliente":
        return <FormularioDatosDelCliente formulario={formulario} setFormulario={setFormulario} editable={editable} />
      case "historal-riesgo":
        return <FormularioHistorialyRiesgo formulario={formulario} setFormulario={setFormulario} editable={editable} />
      case "inversion-y-distribuidor":
        return (
          <FormularioInversionYDistribuidor formulario={formulario} setFormulario={setFormulario} editable={editable} />
        )
      default:
        return <div>No component found for this step</div>
    }
  }

  return (
    <div className="w-full h-full flex flex-col mt-4">
      <div className="flex grid grid-cols-3 text-center gap-8 border-b border-gray-300 py-1.5">
        {steps.map((step, index) => (
          <div key={index} className="relative cursor-pointer" onClick={() => setCurrentStep(index)}>
            <span className={`text-md font-semibold ${currentStep === index ? "text-[#390094]" : "text-[#4A5568]"}`}>
              {step.label}
            </span>
            {currentStep === index && (
              <div className="absolute mt-2 left-0 w-full h-1 bg-[#41BFFB] rounded-full mt-1"></div>
            )}
          </div>
        ))}
      </div>
      <div className="mt-8 flex-1 overflow-y-auto">{renderStepContent()}</div>
    </div>
  )
}
