import { useRef, useState, useEffect } from "react"
import FormularioCredito from "../components/formulario-credito"
import { Button, Card } from "@material-tailwind/react"
import { FormularioDTO } from "../../adapters/dto/formulario-dto"
import formularioNuevo from "../../adapters/ports/formulario_api"
import { CardReusable } from "../components/card"
import NavBar from "../../../../components-finmaq/nav-bar"
import type { FormularioRespuestaDTO } from "../../adapters/dto/resultado-dto"

const truncateToTwoDecimals = (number: number) => {
  return Math.trunc(number * 100) / 100
}

export default function Scoring() {
  const [modelo, setModelo] = useState<FormularioDTO>(FormularioDTO.instancia())
  const [respuesta, setRespuesta] = useState<FormularioRespuestaDTO | null>(null)
  const [, setEnviado] = useState(false)
  const [editable, setEditable] = useState(true)
  const [isFormValid, setIsFormValid] = useState(false)

  const initialRef = useRef(null)

  useEffect(() => {
    const validateForm = () => {
      const requiredFields = Object.keys(FormularioDTO.instancia())
      const allFieldsFilled = requiredFields.every(
        (field) =>
          modelo[field as keyof FormularioDTO] !== "" &&
          modelo[field as keyof FormularioDTO] !== null &&
          modelo[field as keyof FormularioDTO] !== undefined,
      )
      setIsFormValid(allFieldsFilled)
    }
    validateForm()
  }, [modelo])

  useEffect(() => {
    if (respuesta !== null) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [respuesta]);
  

  const handleEnviar = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!isFormValid) return

    try {
      const resp = (await formularioNuevo.enviar(FormularioDTO.compilarDTO(modelo))) as FormularioRespuestaDTO
      setRespuesta(resp)
      setEnviado(true)
      setEditable(false)

      const updatedModelo = {
        ...modelo,
        approved: resp.approved,
        resume: resp.resume,
      }

      setModelo(updatedModelo)
      initialRef.current?.scrollIntoView({ behavior: "smooth" })
    } catch (error) {
      console.error("Error al enviar el formulario:", error)
    }
  }

  const resetFormulario = () => {
    const instancia = FormularioDTO.instancia()
    const resetModelo = { ...instancia }

    resetModelo.department = ""
    resetModelo.financial_percent = 0
    resetModelo.credit_score_distributor = ""
    resetModelo.indispensable_asset = ""

    setModelo(resetModelo)
    setRespuesta(null)
    setEnviado(false)
    setEditable(true)
  }

  const handleEditForm = () => {
    setEditable(true)
    setEnviado(false)
    setRespuesta(null)
  }

  return (
    <div className="w-full flex flex-col min-h-screen">
      <NavBar />
      <form onSubmit={handleEnviar} className="flex-grow flex flex-col">
        <div ref={initialRef} className=" mt-[80px] mb-[40px] flex justify-center items-center flex-grow">
          <Card
            placeholder={""}
            className="mt-6 h-full w-full ml-[40px] xl:ml-[60px]- md:mr-[60px] lg:mr-[40px] xl:mr-[40px] rounded-t-3xl flex flex-col"
          >
            <div className="ml-[270px] mt-[46px] mr-[270px]">
              <p className="text-[#390094] text-3xl font-semibold">Scoring Automatizado</p>
              <p className="mt-[4px] text-[#A0AEC0] text-lg font-medium">V. pruebas</p>
              {respuesta !== null && (
                <CardReusable
                  title={`Resultado`}
                  description={`${respuesta.approved ? "Aprobado" : "Rechazado"}`}
                  puntaje={`${truncateToTwoDecimals(respuesta.score).toFixed(2)}`}
                  isApproved={respuesta.approved}
                />
              )}
              {respuesta === null && ( 

                <p className="mt-[57px] text-[#171923] text-sm font-normal">
                    Ingrese los datos solicitados para hacer la simulación del scoring.
                </p>
              )}
            </div>
            <div className="mt-[53px] mb-[200px] ml-[60px] mr-[60px] flex-grow">
              <FormularioCredito formulario={modelo} setFormulario={setModelo} editable={editable} />
            </div>
          </Card>
        </div>
        <footer className="fixed bottom-0 h-[70px] bg-[#F9FAFF] w-full shadow-none border-[#EAE5F3] border-2">
          <div className="text-end h-full rounded-b-[24px] bg-white ml-[30px] mr-[30px] shadow-md">
            <div className="mr-[106px]">
              <Button
                placeholder={""}
                className={`w-auto mt-[8px] h-auto rounded-3xl text-md font-semibold normal-case ${isFormValid ? "bg-[#390094] text-white hover:bg-purple" : "bg-[#CBD5E0] text-[#718096] cursor-not-allowed"}`}
                type={"submit"}
                disabled={!isFormValid || !editable}
              >
                Enviar
              </Button>
            </div>
          </div>
        </footer>
        {respuesta !== null && (
          <footer className="fixed bottom-0 h-[70px] w-full shadow-lg border-[#EAE5F3] border-2">
            <div className="text-end h-full rounded-b-[24px] bg-white ml-[38px] mr-[38px]">
              <div>
                <Button
                  placeholder={""}
                  className="mt-[8px] w-[180px] h-[45px] bg-white rounded-3xl text-[#390094] border-[#390094] border-[1px] text-sm font-semibold hover:bg-purple hover:text-[#390094] normal-case"
                  type={"button"}
                  onClick={resetFormulario}
                >
                  Nueva simulación
                </Button>
                <Button
                  placeholder={""}
                  className="mt-[8px] mr-[106px] ml-[29px] w-[180px] h-[45px] bg-[#390094] rounded-3xl text-white text-sm font-semibold hover:bg-[#280068] hover:text-white normal-case"
                  type={"button"}
                  onClick={handleEditForm}
                >
                  Editar formulario
                </Button>
              </div>
            </div>
          </footer>
        )}
      </form>
    </div>
  )
}

