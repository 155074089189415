import { useEffect, useState } from "react"

interface SortButtonProps {
    property: string
    sortDirection: "ASCENDING" | "DESCENDING"
    sortProperty: string
    handleSort: (property:string) => void
}

export const SortButton = ({property, sortDirection, sortProperty, handleSort}: SortButtonProps)=> {
    const [individualSortDirection, setIndividualSortDirection] = useState<"ASCENDING" | "DESCENDING">("DESCENDING");

    useEffect(()=>{
        sortProperty === property ? setIndividualSortDirection(sortDirection) : setIndividualSortDirection("DESCENDING")
    },[sortDirection, sortProperty, property])

    return (
        <button className="relative inline-block ml-2 top-0" onClick={()=> handleSort(property)}> 
        {/*sortProperty === property ? "#51C1E1" : "#A0AEC0"*/}
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.33366 1.33333H13.0003C13.1771 1.33333 13.3467 1.2631 13.4717 1.13807C13.5968 1.01305 13.667 0.843478 13.667 0.666667C13.667 0.489856 13.5968 0.320287 13.4717 0.195262C13.3467 0.070238 13.1771 0 13.0003 0H6.33366C6.15685 0 5.98728 0.070238 5.86225 0.195262C5.73723 0.320287 5.66699 0.489856 5.66699 0.666667C5.66699 0.843478 5.73723 1.01305 5.86225 1.13807C5.98728 1.2631 6.15685 1.33333 6.33366 1.33333ZM13.0003 6.66667H6.33366C6.15685 6.66667 5.98728 6.7369 5.86225 6.86193C5.73723 6.98695 5.66699 7.15652 5.66699 7.33333C5.66699 7.51014 5.73723 7.67971 5.86225 7.80474C5.98728 7.92976 6.15685 8 6.33366 8H13.0003C13.1771 8 13.3467 7.92976 13.4717 7.80474C13.5968 7.67971 13.667 7.51014 13.667 7.33333C13.667 7.15652 13.5968 6.98695 13.4717 6.86193C13.3467 6.7369 13.1771 6.66667 13.0003 6.66667ZM13.0003 3.33333H6.33366C6.15685 3.33333 5.98728 3.40357 5.86225 3.5286C5.73723 3.65362 5.66699 3.82319 5.66699 4C5.66699 4.17681 5.73723 4.34638 5.86225 4.4714C5.98728 4.59643 6.15685 4.66667 6.33366 4.66667H13.0003C13.1771 4.66667 13.3467 4.59643 13.4717 4.4714C13.5968 4.34638 13.667 4.17681 13.667 4C13.667 3.82319 13.5968 3.65362 13.4717 3.5286C13.3467 3.40357 13.1771 3.33333 13.0003 3.33333Z" fill={sortProperty === property ? "#51C1E1" : "#A0AEC0"}/>
                <path className={individualSortDirection === "DESCENDING" ? "rotate-180 origin-center -translate-x-[9px]" : ""} d="M2.96947 0.193427C2.90607 0.132733 2.8313 0.0851566 2.74947 0.053427C2.58716 -0.0132517 2.40511 -0.0132517 2.2428 0.053427C2.16097 0.0851566 2.0862 0.132733 2.0228 0.193427L0.689467 1.52676C0.563932 1.6523 0.493406 1.82256 0.493406 2.00009C0.493406 2.17763 0.563932 2.34789 0.689467 2.47343C0.815003 2.59896 0.985266 2.66949 1.1628 2.66949C1.34034 2.66949 1.5106 2.59896 1.63613 2.47343L1.82947 2.27343V7.33343C1.82947 7.51024 1.89971 7.67981 2.02473 7.80483C2.14975 7.92986 2.31932 8.00009 2.49613 8.00009C2.67295 8.00009 2.84251 7.92986 2.96754 7.80483C3.09256 7.67981 3.1628 7.51024 3.1628 7.33343V2.27343L3.35613 2.47343C3.41811 2.53591 3.49184 2.58551 3.57308 2.61935C3.65432 2.6532 3.74146 2.67063 3.82947 2.67063C3.91748 2.67063 4.00461 2.6532 4.08585 2.61935C4.16709 2.58551 4.24083 2.53591 4.3028 2.47343C4.36529 2.41145 4.41488 2.33772 4.44873 2.25648C4.48257 2.17524 4.5 2.0881 4.5 2.00009C4.5 1.91209 4.48257 1.82495 4.44873 1.74371C4.41488 1.66247 4.36529 1.58874 4.3028 1.52676L2.96947 0.193427Z" fill={sortProperty === property ? "#51C1E1" : "#A0AEC0"}/>
            </svg>
        </button>
    ) 
}