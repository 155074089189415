import checkFormValues from "app/formulario-solicitud/utilities/checkFormValues"; 
import { useEffect, useState} from "react";
import FormFields, { ObjectProps } from "app/formulario-solicitud/utilities/formFields"; 
import useLegalChecks from "app/formulario-solicitud/utilities/useLegalChecks";
import { useCognitoUserAttributes } from 'app/login/adapters/useCognitoUserAttributes';

interface StepperProps {
    titles: string[]
    currentStepIndex: number
    contact: {}
    currentProduct: {}
    handleStepNavigate: (step:number)=>void
}

const stepStatus = {
    done: {
        title: "text-[#718096]",
        number: "border-[#38A169] bg-[#38A169] text-white",
        container: ""
    },
    current: {
        title: "text-black",
        number: "border-[#390094] bg-[#390094] text-white before:content-[''] before:absolute before:w-[90%] before:h-[90%] before:top-[6%] before:left-[4%] before:border-solid before:border-white before:rounded-full before:border-2",
        container: "pointer-events-none"
    },
    empty:{
        title: "text-[#718096]",
        number: "text-[#718096] border-[1px] border-[#718096] bg-white",
        container: "pointer-events-none"
    }
}

export default function Stepper({titles, currentStepIndex, contact, currentProduct, handleStepNavigate } : StepperProps) {
    const [stepsStatus, setStepsStatus] = useState({})
    const { person_type } = useCognitoUserAttributes();
    const formFieldsByType = FormFields[person_type as keyof typeof FormFields];
    const { isAllLegalChecked } = useLegalChecks(currentProduct);

    useEffect(()=>{
        if(contact && formFieldsByType){
            setTimeout(()=>{
                const stepsChecked:ObjectProps = {};

                formFieldsByType.forEach((steps, stepIndex:number)=>{
                    stepsChecked[stepIndex] = checkFormValues({
                        step: stepIndex,
                        highlightErrors: false,
                        formFields: formFieldsByType
                    }).stepCompleted
                });
                
                setStepsStatus(stepsChecked);
            },1)
        }
    },[contact, formFieldsByType])

    return (
        <div className={`relative mb-5 md:mb-0 ${!titles ? `skeleton-animation` : ``}`}>
            <div className="relative z-10 flex justify-between lg:ml-[105px] lg:mr-[105px] mt-10 skeleton-pulse">
                {titles && titles.map((title,ind)=>{
                    let status;
                    if(ind > currentStepIndex) status = "empty";
                    if(ind < currentStepIndex || stepsStatus[ind as keyof typeof stepsStatus]) status = "done";
                    if(ind === 3 && isAllLegalChecked) status = "done";
                    if(ind === currentStepIndex) status = "current";

                    return <div key={ind} className={`md:max-w-[20%] lg:max-w-[15%] relative cursor-pointer flex items-center ${ind < titles.length -1 ? 'flex-1' : ''} ${stepStatus[status as keyof typeof stepStatus]?.container}`} onClick={() => handleStepNavigate(ind)}>
                        <div className={`relative md:absolute text-center top-1/2 -translate-y-1/2 left-0 w-[32px] h-[32px] rounded-full border-2 ${stepStatus[status as keyof typeof stepStatus]?.number}`}>
                            <span className={`relative rounded-full top-[2px] right-0 font-semibold text-sm`}>{ind+1}</span>
                        </div>
                        <div className={`hidden md:block ml-[40px] text-sm font-bold ${stepStatus[status as keyof typeof stepStatus]?.title}`}>{title}</div>
                    </div>
                })}
            </div>
            <div className="flex absolute z-0 px-[32px] md:px-[20%] lg:px-[13.4%] lg:left-[105px] w-full lg:w-[calc(100%-210px)] top-1/2">
                {titles && titles.map((title,ind)=>{
                    let status;
                    if(ind < currentStepIndex) status = "bg-gradient-to-r from-[#38A169] to-[#290371]"; // done
                    if(ind + 1 < currentStepIndex) status = "bg-[#38A169]"; // full
                    if(ind === currentStepIndex) status = "bg-gradient-to-r from-[#290371] to-[#718096]"; // current
                    if(ind > currentStepIndex) status = "bg-[#718096]"; // empty
                    
                    if(ind < titles.length -1){
                        return <div key={ind} className={`${status} h-0.5 w-[30%] md:w-[11%] lg:w-[19%] md:scale-x-75 ${status} ${ind < titles.length - 2 ? "mr-[5%] md:mr-[33.5%] lg:mr-[22%]" : ""}`}></div>
                    }
                    return null;
                })}
            </div>
        </div>
    );
}
