const textsByRole = {
    NATURAL: {
        identification_document_title: "Documento de identificación",
        identification_document_description: "Suba una copia clara y legible de su documento de identificación. Puede ser una foto, un escaneo o una copia digital. Esto nos ayudará a verificar su identidad.",
        commercial_product_type: "RUT",
        commercial_document_title: "Registro único tributario RUT",
        commercial_document_description_1: "Para obtener su RUT ingrese a la página de la DIAN con su usuario y contraseña, y descárguelo en formato PDF.",
        commercial_document_description_2: "Suba una copia legible de su RUT con una fecha de expedición **no mayor a 90 días**. Esto nos permitirá verificar su situación tributaria."
    },
    JURIDICA: {
        identification_document_title: "Documento de identificación representante legal",
        identification_document_description: "Suba una copia clara y legible del documento de identificación del representante legal. Puede ser una foto, un escaneo o una copia digital. Esto nos ayudará a verificar su identidad.",
        commercial_product_type: "CAMARA_COMERCIO",
        commercial_document_title: "Cámara y comercio",
        commercial_document_description_1: "Para obtener su certificado de Cámara y comercio ingrese a la página de la Cámara y comercio y descárguelo en formato PDF.",
        commercial_document_description_2: "Suba una copia legible de su Cámara y comercio con una fecha de expedición **no mayor a 90 días**."
    }
}

export default textsByRole;
