import type React from "react"
import { ConfigVariables } from "adapters/ports/config-variables"
import { useState, useEffect, useRef } from "react"
import type { SolicitudesHubspotDTO } from "../../../adapters/dto/solicitudes-hubspot-dto"

interface FiltroEscrituraProps {
  query: string
  setQuery: React.Dispatch<React.SetStateAction<string>>
  onSearch: (query: string) => Promise<SolicitudesHubspotDTO[]>
  onResultSelect: (solicitud: SolicitudesHubspotDTO) => void
  resetSearch: () => void
  onResultsFound: (solicitudes: SolicitudesHubspotDTO[]) => void
}

const isNumericSearch = (value: string): boolean => {
  return /^[\d-]+$/.test(value)
}

const normalizeDocumentNumber = (value: string): string => {
  return value.replace(/-/g, "")
}

const highlightText = (text: string, query: string) => {
  if (!query || !text) return text

  if (isNumericSearch(query)) {
    const normalizedQuery = normalizeDocumentNumber(query)
    const normalizedText = normalizeDocumentNumber(text)

    if (normalizedText === normalizedQuery) {
      return <span className="font-bold">{text}</span>
    }

    const index = normalizedText.indexOf(normalizedQuery)
    if (index >= 0) {

      return (
        <span>
          {text.split("").map((query, i) => {
            const normalizedPos = normalizeDocumentNumber(text.substring(0, i)).length
            const isPartOfMatch = normalizedPos >= index && normalizedPos < index + normalizedQuery.length
            return isPartOfMatch ? (
              <span key={i} className="font-bold">
                {query}
              </span>
            ) : (
              query
            )
          })}
        </span>
      )
    }
    return text
  }

  const parts = text.split(new RegExp(`(${query})`, "gi"))
  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <span key={index} className="font-bold">
        {part}
      </span>
    ) : (
      part
    ),
  )
}

export default function FiltroEscritura({
  query, 
  setQuery,
  onSearch,
  onResultSelect,
  resetSearch,
  onResultsFound,
}: FiltroEscrituraProps) {
  const [escritura, setEscritura] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [searchResults, setSearchResults] = useState<SolicitudesHubspotDTO[]>([])
  const [loading, setLoading] = useState(false)
  const resultsBoxRef = useRef<HTMLDivElement>(null)
  const searchComponentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (query) {
        const results = await onSearch(query)
        setSearchResults(results)

        if (results.length > 0) {
          setShowResults(true)
        }
      } else {
        setSearchResults([])
        setShowResults(false)
      }
      setLoading(false)
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [query, onSearch])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        resultsBoxRef.current &&
        !resultsBoxRef.current.contains(event.target as Node)
      ) {
        setEscritura(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [searchResults, showResults, onResultsFound])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchResults.length > 0 && showResults) {
      onResultsFound(searchResults)
      setEscritura(false)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    setEscritura(true)
    if (e.target.value) {
      setShowResults(true)
      setLoading(true)
    } else {
      setShowResults(false)
      setLoading(false)
    }
  }

  const handleOpenSearch = () => {
    setEscritura(true)
  }

  const handleClearSearch = () => {
    if(query){
      resetSearch()
    }else{
      setEscritura(false)
    }
    setQuery("")
    setSearchResults([])
    setShowResults(false)
  }

  const handleResultClick = (solicitud: SolicitudesHubspotDTO) => {
    onResultSelect(solicitud)
    setEscritura(false)
  }

  return (
    <div className="relative inline-block" ref={searchComponentRef}>
      <div className="relative">
        <div className={`bg-[#EDF2F7] rounded-[10px] ${escritura || query ? "w-[245px]" : "w-[36px]"} h-[36px] flex items-center justify-between px-2`}>
          <div className="cursor-pointer">
            <svg
              className="text-[#718096] w-[24px] h-[24px]"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleOpenSearch}
            >
              <path
                d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {query &&
              <sup className="absolute text-[7px] pt-[5px] font-bold top-[6px] left-[20px] bg-[tomato] w-[9px] h-[9px] rounded-full text-white text-center">1</sup>
            }
          </div>
          {(escritura || query) && (
            <div className="flex items-center justify-between">
              <input
                type="text"
                className="flex-1 bg-[#EDF2F7] outline-none px-2 text-sm font-normal text-[#718096] placeholder-[#718096]"
                autoFocus
                value={query}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />

              <svg
                className="text-[#718096] w-[24px] h-[24px] cursor-pointer"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleClearSearch}>
                <path
                  d="M18 6L6 18M6 6L18 18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
        {escritura &&
          <div
            ref={resultsBoxRef}
            className="absolute top-full mt-[10px] left-1/2 transform -translate-x-1/2 w-[400px] h-[258px] bg-white shadow-2xl rounded-[30px] z-50 flex flex-col items-center justify-start text-center overflow-y-auto">
            <div className="px-5 py-2 w-full h-full bg-white shadow-2xl rounded-[30px] z-50 flex flex-col items-center justify-start text-center">
              {!query && (
                <div className="w-full h-full mt-[10px] z-50 flex flex-col items-center justify-center text-center">
                  <img
                    src={ConfigVariables.BUSQUEDA_ESCRITURA_TABLA}
                    alt="imagen busqueda"
                    className="w-[128px] h-[99px] mt-[10px]"
                  />
                  <div className="mt-[14px] text-[#000000]">
                    <span className="text-lg font-bold block">Buscar</span>
                    <span className="text-sm font-normal block">Puedes buscar por</span>
                    <span className="text-sm font-normal block">Cliente / Negocio o Nit / CC</span>
                  </div>
                </div>
              )}
              {loading && (
                <div className="mt-28">Buscando...</div>
              )}
              {(!loading && query && searchResults.length >= 1) && (
                searchResults.map((result, index) => (
                  <div
                    key={index}
                    className="w-full py-[20px] cursor-pointer text-left border-b border-[#E2E8F0] hover:bg-[#F7FAFC]"
                    onClick={() => handleResultClick(result)}
                  >
                    {isNumericSearch(query) ? (
                      <>
                        <div className="text-[#171923] text-sm font-normal ml-2">
                          {highlightText(result.numero_de_identificacion_del_contacto, query)}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-[#171923] text-sm font-normal ml-2">
                          {highlightText(result.dealname, query)}
                        </div>
                      </>
                    )}
                  </div>
                )))
              }
              {(!loading && query && searchResults.length <= 0) && (
                <div className="h-full w-full flex flex-col items-center justify-center text-center">
                  <div className="">
                    <img src={ConfigVariables.ICON_ALERT_ERROR} alt="error" className="w-5 h-5" />
                  </div>
                  <div className="text-lg font-bold text-center text-[#000000]">Elemento no encontrado</div>
                </div>
              )}
            </div>
          </div>
        }
      </div>
    </div>
  )
}
