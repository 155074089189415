import { Button } from "@material-tailwind/react";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ConfigVariables } from "adapters/ports/config-variables";

export default function ComenzarCargaDocumentos() {
    const navigate = useNavigate();

    return (
        <div className='h-screen bg-[#ffffff] flex flex-col font-Poppins overflow-auto'>
            <div className="hidden lg:flex flex-grow">
                <div className="w-1/2 flex justify-center items-center mt-[140px]">
                    <div className='ml-52 mr-40 w-[400px] mt-20'>
                        <div className="font-[400px] font-normal text-[24px] leading-8 text-start">
                            <div className="text-2xl">
                                <p>Para comenzar, <span className="font-semibold">necesitamos algunos documentos esenciales para evaluar su solicitud.</span></p> 
                                <p className="mt-6">Recuerde que cuanto antes suba los documentos, más rápido podremos procesar su solicitud.</p>
                            </div>
                        </div>
                        <div className="mt-16">
                            <Button
                                placeholder={""}
                                onClick={ ()=> navigate('/beneficios-credito') }
                                className="w-[400px] h-[40px] bg-[#390094] leading-2 text-white text-center rounded-[28px] font-semibold flex items-center justify-center normal-case text-lg">
                                Continuar <FiArrowRight className="ml-3" />
                            </Button>
                        </div>
                        <div className=" relative mt-24 text-[#A0AEC0] text-sm ml-8 mb-6 text-start">
                            <p>© 2024 Finmaq. Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
                <div className="w-1/2 relative">
                    <div className='w-full h-full rounded-bl-[166px] bg-cover bg-center' style={{ backgroundImage: `url(${ConfigVariables.IMAGEN_PRINCIPAL_ONBOARDING_DESKTOP})`, backgroundSize: 'cover' }}>
                        <img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} alt="Group" className="absolute mt-20 right-0 mr-16 w-[170px] h-[47px]"/>
                    </div>
                </div>
            </div>
            <div className="lg:hidden flex flex-col items-center justify-between min-h-screen w-full md:flex md:flex-col">
                <div className="h-[225px] md:h-[550px] w-full">
                    <img
                        src={ConfigVariables.IMAGEN_PRINCIPAL_ONBOARDING_MOBILE}
                        alt="Background"
                        className="w-full h-full object-cover rounded-bl-[106px]"
                    />
                    <div className="flex justify-center items-center  w-full absolute top-0">
                        <img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} alt="Group" className="mt-4 w-[128px] h-[36px]" />
                    </div>
                </div>
                <div className="mt-20 md:mt-36 ml-7 mr-7 md:ml-12 md:mr-12 flex flex-col flex-grow">
                    <div className="leading-7 text-start">
                        <div className="text-xl font-normal">
                            <p>
                                Para comenzar, <span className="font-semibold">necesitamos algunos documentos esenciales para evaluar su solicitud.</span>
                            </p>
                            <p className="mt-6 md:mt-12">Recuerde que cuanto antes suba los documentos, más rápido podremos procesar su solicitud.</p>
                        </div>
                    </div>
                    <div className=" mt-10 mb-5 md:sticky md:bottom-32 md:mt-32">
                        <Button
                            placeholder={""}
                            onClick={ ()=> navigate('/mis-creditos') }
                            className="w-full h-[40px] bg-[#390094] leading-2 text-white text-center rounded-[28px] font-semibold flex items-center justify-center normal-case text-lg">
                            Continuar <FiArrowRight className="ml-3" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}




