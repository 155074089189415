import { useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom';
import { ConfigVariables } from "adapters/ports/config-variables";
import HttpClientService from "http/httpClientService";
import { useCognitoUserAttributes } from "app/login/adapters/useCognitoUserAttributes";
import useGetDocuments from "app/carga-documentos/utilities/useGetDocuments"
import { calculateTimezoneOffset } from "app/mis-creditos/utilities/formatToClientTimezone";

export const useGetContactWithProducts = ()=>{
    const [ products, setProducts ] = useState("");
    const [ contact, setContact ] = useState("");

    const { person_type, identity_type, identity_number } = useCognitoUserAttributes();
    const { productIndex } = useParams();
    const { documents } = useGetDocuments();

    const sortedProducts = useMemo(()=>{
        if(!products || !documents) return {}
        const productEntries = Object.entries(products);

        const productsWithDocumentsAndProductUUID = productEntries.map((value,i)=>{
            const data:any = value[1]
            data["documents"] = documents[i]
            return [value[1]["product_uuid" as keyof typeof value[1]], data]
        })

        const sortedData = productsWithDocumentsAndProductUUID.sort((a, b)=>{
            const valueA = calculateTimezoneOffset(a[1]["created_at" as keyof typeof a[1]]).valueOf();
            const valueB = calculateTimezoneOffset(b[1]["created_at" as keyof typeof b[1]]).valueOf();
            return valueB - valueA;
        })

        return Object.fromEntries(sortedData)
    },[products, documents])

    const currentProduct = useMemo(()=>{
        if(!productIndex || Object.keys(sortedProducts).length < 1) return {};
        return sortedProducts[productIndex] || {};
    },[productIndex, sortedProducts])

    useEffect(() => {
        if(products === "" && person_type && identity_type && identity_number){
            (async () => {
                try {
                    const apiBaseUrl = ConfigVariables.URL_BASE_API;

                    const getUserEndpoint = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}`;
                    const response = await HttpClientService.get<string>(getUserEndpoint);
                    const stringifytext = JSON.stringify(response);
                    const parsetext = JSON.parse(stringifytext);
                    const contact = parsetext.client;
                    
                    setProducts(parsetext.client.products);
                    setContact(contact);
                } catch (error) {
                    console.error('Error al obtener la data del usuario:', error);
                    window.location.href = "/error-500";
                    throw error;
                }
            })(); 
        }
    },[products, person_type, identity_type, identity_number])

    return { contact, sortedProducts, currentProduct }
}
