import { ConfigVariables } from "adapters/ports/config-variables"
import { useNavigate } from "react-router-dom"

interface SideBarDetalleNegocioProps {
  solicitud: any
}

export default function SideBarDetalleNegocio({ solicitud }: SideBarDetalleNegocioProps) {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <div className="h-full flex flex-col">
        <div className="bg-white shadow-2xl rounded-[24px] w-[295px] h-full flex flex-col overflow-hidden">
            <div className="flex items-start gap-x-2 py-5 px-2">
                <svg className="w-[24px] h-[24px] text-[#000000] cursor-pointer flex-shrink-0 self-start" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleBack}>
                    <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <div className="flex-1">
                    <div className="text-[#390094] text-xl font-medium leading-tight">
                        {solicitud.dealname}
                    </div>
                    <p className="text-[#4A5568] text-sm">
                    {   solicitud.person_type || "Persona natural"}
                    </p>
                </div>
            </div>
            <div className="flex-1 overflow-y-auto px-4 py-2 mb-5">
                <div className="text-[#718096] text-sm font-normal flex flex-col gap-y-2">
                    <div className="flex items-center gap-x-3">
                        <img src={ConfigVariables.IMAGEN_DOCUMENTO_NEGOCIO} alt="" className="w-5 h-5" />
                        <p>{solicitud.email || "mark_chandler@mail.com"}</p>
                    </div>
                    <div className="flex items-center gap-x-3">
                        <img src={ConfigVariables.IMAGEN_TELEFONO_NEGOCIO} alt="" className="w-5 h-5" />
                        <p>{solicitud.phone || "313 321 1234"}</p>
                    </div>
                    <div className="flex items-center gap-x-3">
                        <img src={ConfigVariables.IMAGEN_DOCUMENTO_NEGOCIO} alt="" className="w-5 h-5" />
                        <p>{solicitud.document_type || "CC"} {solicitud.numero_de_identificacion_del_contacto}</p>
                    </div>
                    <div className="flex items-center gap-x-3">
                        <img src={ConfigVariables.IMAGEN_EDAD_NEGOCIO} alt="" className="w-[19px] h-[19px]" />
                        <p>{solicitud.age || "41 años"}</p>
                    </div>
                </div>
                <div className="mt-7">
                    <p className="text-xl font-medium text-[#390094]">COP {solicitud.formattedAmount}</p>
                    <span className="text-sm font-medium text-[#4A5568]">Valor solicitado</span>

                    <div className="mt-7">
                        <p className="text-md font-medium text-[#4A5568]">Distribuidor</p>
                        <p className="text-sm font-normal text-[#718096]">
                            {solicitud.distribuidor || "INNSA"}
                        </p>
                    </div>

                    <div className="mt-7">
                        <p className="text-md font-medium text-[#4A5568]">Fecha de radicado</p>
                        <p className="text-sm font-normal text-[#718096]">{solicitud.formattedDate}</p>
                    </div>

                    <div className="mt-7">
                        <p className="text-md font-medium text-[#4A5568]">Asignado a</p>
                        <p className="text-sm font-normal text-[#718096]">
                            {solicitud.analista_de_credito_asignado || "Analista"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

