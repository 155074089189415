import { useState, useEffect } from "react";
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import HttpClientService from "http/httpClientService";
import LayoutDocumentos from "app/carga-documentos/layouts/layout-documentos";
import FormFields, { FieldsProps, FormFieldsProps } from "app/formulario-solicitud/utilities/formFields";
import renderedInputs from "app/formulario-solicitud/utilities/renderedInputs";
import checkFormValues from "app/formulario-solicitud/utilities/checkFormValues"; 
import Stepper from "app/components-finmaq/stepper";
import classNameByEvent from "app/carga-documentos/utilities/classNameByEvent";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import { useGetContactWithProducts } from "app/carga-documentos/utilities/useGetContactWithProducts";
import { ConfigVariables } from "adapters/ports/config-variables";
import ModalGuardadoParcial from "app/components-finmaq/modal-guardado-parcial";
import ToastFailCargaDocs from "app/components-finmaq/cardUploadFile/toast-fail-carga-documentos";
import ToastCargaDocs from "app/components-finmaq/cardUploadFile/toast-carga-documentos";
import { useCognitoUserAttributes } from 'app/login/adapters/useCognitoUserAttributes';
import { handleTagManager, handleTagManagerSteps } from "app/utilities/gtm";
import { useHandleBeforeUnload } from "app/carga-documentos/utilities/useHandleBeforeUnload";
import { useHandleProductDone } from "app/carga-documentos/utilities/useHandleProductDone";
import { calculateAge } from "app/utilities/calculateAge";

const FormularioSolicitud = ()=>{
    const { currentProduct, contact } = useGetContactWithProducts();
    const [ currentStepIndex, setCurrentStepIndex ] = useState(0);
    const [ buttonStatus, setButtonStatus ] = useState(classNameByEvent(uploadStatus.DISABLE));
    const [ isFinalStep, setIsFinalStep ] = useState(false);
    const [ buttonLabel, setButtonLabel ] = useState("Continuar");
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ legalChecks, setLegalChecks ] = useState(null);
    const [ isFormDirty, setIsFormDirty ] = useState(contact ? true : false);
    const [ hasSaveError, setHasSaveError ] = useState(false);
    const [ successfulToast, setSuccessfulToast ] = useState(false);

    const { cognitoUUID, person_type, identity_type, identity_number, email } = useCognitoUserAttributes();
    const { productIndex } = useParams();
    const navigate = useNavigate();

    const apiBaseUrl = ConfigVariables.URL_BASE_API;
    const productUUID = currentProduct["product_uuid" as keyof typeof currentProduct];
    const endpoint = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}`;
    const formFieldsByType = FormFields[person_type as keyof typeof FormFields];
    
    const product_state = (currentProduct as unknown as { financial_product: {
        product_state: string
    } }).financial_product?.product_state;

    const { handleOpenModal } = useHandleBeforeUnload(isFormDirty, ()=> setIsModalOpen(true));
    
    useHandleProductDone(product_state);


    const handleChange = () => {
        const {stepCompleted} = checkFormValues({
            step: currentStepIndex, 
            highlightErrors: false, 
            formFields: formFieldsByType});

            if (!isFormDirty) {
                setIsFormDirty(true);
            }

        stepCompleted ? setButtonStatus(classNameByEvent(uploadStatus.READY)) : setButtonStatus(classNameByEvent(uploadStatus.DISABLE))
    }

    const titles = formFieldsByType?.map((steps)=>{
        return steps.title
    })

    const handleStepNavigate = (step:number)=>{
        step + 1 === formFieldsByType.length ? setButtonLabel("Guardar") : setButtonLabel("Continuar");
        setCurrentStepIndex(step);
    }

    const handlePartialSaveData = async () => {
        const { fieldsCompletedByStep} = checkFormValues({
            step: currentStepIndex,
            highlightErrors: true, 
            formFields: formFieldsByType});

            (async () => {
                try {
                    await HttpClientService.patch<string>(endpoint,fieldsCompletedByStep);
                    localStorage.setItem("failedAttempts", "");
                } catch (error){
                    console.log(error);
                    const failedAttempts = localStorage.getItem("failedAttempts") || "";
                    localStorage.setItem("failedAttempts", `${failedAttempts}f`);
                    setHasSaveError(true)

                    if(error.code === "ECONNABORTED" || localStorage.getItem("failedAttempts").length >= 3){
                        localStorage.setItem("failedAttempts", "");
                        navigate("/error-connection-timed-out");
                    }
                }
            })();
        
        if( formFieldsByType.length ) navigate(`/carga-documentos/${productIndex}`);
    }
    
    const handleSaveData = (step:number)=>{ 
        const {stepCompleted, fieldsCompletedByStep} = checkFormValues({
            step, 
            highlightErrors: true, 
            formFields: formFieldsByType});

        if(stepCompleted && formFieldsByType.length > currentStepIndex + 1) {
            step + 2 === formFieldsByType.length ? setButtonLabel("Guardar") : setButtonLabel("Continuar");

            (async () => {
                try {
                    await HttpClientService.patch<string>(endpoint,fieldsCompletedByStep);
                    localStorage.setItem("failedAttempts", "");
                    setCurrentStepIndex(a => a + 1);
                } catch (error){
                    console.log(error);
                    const failedAttempts = localStorage.getItem("failedAttempts") || "";
                    localStorage.setItem("failedAttempts", `${failedAttempts}f`);
                    setHasSaveError(true)

                    if(error.code === "ECONNABORTED" || localStorage.getItem("failedAttempts").length >= 3){
                        localStorage.setItem("failedAttempts", "");
                        navigate("/error-connection-timed-out");
                    }
                }
            })();
        }

        if(stepCompleted && formFieldsByType.length === currentStepIndex + 1) {
            const born_date = document.querySelector(".field-contact__born_date") as HTMLInputElement;
            const gender = document.querySelector(".radio_input_contact__gender:checked") as HTMLInputElement;
            
            const product_uuid = (currentProduct as unknown as { product_uuid: string }).product_uuid;
            const endpointLegals = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}/approvals`;

            const legalChecks = {
                product_id: product_uuid,
                approves_funds_origins: true,
                approves_terms_finmaq_go: true,
                approves_data_handling_policy_documents: true
            };

            (async () => {
                try {
                    await HttpClientService.patch<string>(endpointLegals,legalChecks);
                    localStorage.setItem("failedAttempts", "");
                    handleTagManagerSteps({
                        action: "complete",
                        cognitoUserId: cognitoUUID,
                        productId: productUUID
                    })
                    handleTagManager({
                        event: "user_data",
                        age: calculateAge(born_date.value),
                        gender: gender.value,
                        cognitoUserId: cognitoUUID
                   })
                } catch (error){
                    console.log(error)
                    const failedAttempts = localStorage.getItem("failedAttempts") || "";
                    localStorage.setItem("failedAttempts", `${failedAttempts}f`);
                    setHasSaveError(true)

                    if(error.code === "ECONNABORTED" || localStorage.getItem("failedAttempts").length >= 3){
                        localStorage.setItem("failedAttempts", "");
                        navigate("/error-connection-timed-out");
                    }
                }
            })();
            setSuccessfulToast(true);
        }
        setHasSaveError(false);
    }

    useEffect(() => {
        if (successfulToast) {
            const timer = setTimeout(() => {
                navigate(`/carga-documentos/${productIndex}`);
                setSuccessfulToast(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successfulToast, navigate, productIndex]);

    useEffect(()=>{
        if(cognitoUUID && productUUID){
            handleTagManagerSteps({
                action: "enter",
                cognitoUserId: cognitoUUID,
                productId: productUUID
            })
        }
    },[cognitoUUID, productUUID])

    const renderedForm = formFieldsByType?.map((steps:FormFieldsProps, stepsindex:number)=>{
        if(!contact) return false;
        const renderedStep = steps.inputs.map(({label, name, fieldType, options, styleClass = "w-full lg:w-[calc(50%-20px)]", tooltip = "", subtitle = "", previousLock = false, maxLength }: FieldsProps, fieldIndex:number)=>{
            let defaultValue;

            const field = steps.inputs[fieldIndex];
            const group = field["group" as keyof typeof field] || "";
            const section = field["section" as keyof typeof field] || "";

            const getSection = section ? contact[section as keyof typeof contact] : false;
            const getGroup = (section && group) ? getSection[group as keyof typeof getSection] : false;
            const groupValue = getGroup ? getGroup[name as keyof typeof getGroup] : "";
            const value = getSection ? getSection[name as keyof typeof getSection] : "";

            if(groupValue !== ""){
                defaultValue = groupValue
            }else{
                defaultValue = value
            }

            if(name === "created_at"){
                const created_at:string = currentProduct["created_at" as keyof typeof currentProduct] || "";
                defaultValue = created_at !== "" ? created_at : "";
            }

            if(email && name === "email"){
                defaultValue = email
            }

            if(name === "first_lastname"){
                const secondLastNameGroup = contact["contact" as keyof typeof contact];
                const secondLastNameValue = secondLastNameGroup["second_lastname" as keyof typeof secondLastNameGroup] || ""

                defaultValue += ` ${secondLastNameValue}`
            }

            if(fieldType === "nested_select"){
                const city = getGroup["city" as keyof typeof getGroup];
                if(defaultValue) defaultValue += `|${city}`;
            }

            return renderedInputs({label, fieldIndex, subtitle, tooltip, fieldType, options, defaultValue, previousLock, styleClass, section: section.toString(), group: group.toString(), name, handleChange, legalChecks, maxLength})
        })

        const stepClassName = stepsindex === 0 ? "" : "hidden";
        return <div key={`step_${stepsindex}`} className={`lg:ml-[105px] lg:mt-4 lg:mr-[105px] multiform-step multiform-step-${stepsindex} ${stepClassName} flex flex-wrap justify-between`}>
            {renderedStep}
        </div>
    })

    useEffect(()=>{
        const product_uuid = (currentProduct as unknown as { product_uuid: string }).product_uuid;
        const endpointLegals = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}/approvals/${product_uuid}`;

        if(person_type && product_uuid){
            (async () => {
                try {
                    const response = await HttpClientService.get<string>(endpointLegals);
                    const stringifytext = JSON.stringify(response);
                    const parsetext = JSON.parse(stringifytext);

                    setLegalChecks(parsetext.authorizations.documents_approvals);
                } catch (error){
                    console.log(error);
                }
            })();
        }
    },[currentProduct, identity_number, identity_type, person_type, apiBaseUrl])

    useEffect(()=>{
        const currentContainer = document.querySelector(`.multiform-step:not(.hidden)`);
        const nextContainer = document.querySelector(`.multiform-step-${currentStepIndex}`);

        if(currentContainer) currentContainer.classList.add("hidden");
        if(nextContainer) nextContainer.classList.remove("hidden");

        formFieldsByType?.length === currentStepIndex + 1 ? setIsFinalStep(true) : setIsFinalStep(false);

        if(contact) {
            setTimeout(()=>{
                const {stepCompleted} = checkFormValues({
                    step: currentStepIndex, 
                    highlightErrors: false, 
                    formFields: formFieldsByType});

                stepCompleted ? setButtonStatus(classNameByEvent(uploadStatus.READY)) : setButtonStatus(classNameByEvent(uploadStatus.DISABLE))
            },1)
        }
    },[currentStepIndex, contact, formFieldsByType])

    return (
        <LayoutDocumentos 
            handleButtonClick={()=> handleSaveData(currentStepIndex)} 
            buttonLabel={buttonLabel}
            buttonClassName={buttonStatus}
            isButtonArrowInResponsive={true}
            isFinalStep={isFinalStep}
            hasFooter={true}>
            {hasSaveError &&
                <ToastFailCargaDocs onClose={()=> setHasSaveError(false)} onClick={()=> handleSaveData(currentStepIndex)} />
            }
            {successfulToast &&
                <ToastCargaDocs 
                    message={"Tu información se guardó satisfactoriamente"}
                    onTimeout={()=> setSuccessfulToast(false)}/>
            }
            <div className="hidden lg:flex text-[#718096] ml-[105px] text-md font-normal">
                <span>Solicitud de crédito </span><span className="ml-1 text-[#000000]"> / Formulario de solicitud</span>
            </div>
            <div className="lg:mt-7 lg:ml-14 flex items-center">
                {isModalOpen && (
                    <ModalGuardadoParcial onClose={()=> setIsModalOpen(false)} onHandleProp={handlePartialSaveData} /> 
                )}
                <button type="button" onClick={handleOpenModal} aria-label="Abrir modal">
                    <svg className="text-[#390094]" width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <p className="text-start text-[#390094] font-bold text-xl ml-2 lg:font-semibold lg:text-2xl lg:ml-[15px]">Formulario de solicitud</p>
            </div>
            <div className="text-[#171923] text-md font-normal lg:ml-[105px] lg:mr-[105px] lg:mt-2 mt-7 leading-6 lg:leading-normal w-full lg:w-auto">
                <p>Complete el siguiente formulario con la información requerida. Por favor, asegúrese de proporcionar información precisa y completa.</p>
            </div>
            <Stepper 
                titles={titles}
                currentStepIndex={currentStepIndex}
                contact={contact}
                currentProduct={currentProduct}
                handleStepNavigate={handleStepNavigate} />
            {!contact && 
                <div className="lg:ml-[105px] lg:mt-4 lg:mr-[105px] flex flex-wrap justify-between skeleton-animation">
                    {Array.from({length: 4}).map((_, ind) =>(
                        <div key={ind} className="h-[40px] mt-[17px] mb-4 font-Poppins font-normal p-1.5 rounded-[10px] border bg-white relative field-container-text w-full lg:w-[calc(50%-20px)]">
                            <p className="skeleton-pulse"></p>
                        </div>
                    ))}
                </div>
            }
            <div>{renderedForm}</div> 
        </LayoutDocumentos>
    )
}

export default FormularioSolicitud