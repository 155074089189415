export const calculateTimezoneOffset = (isoDate:string)=>{
    const utcDate = new Date(`${isoDate}Z`);
    const offsetMinutes = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - offsetMinutes * 60000);
    
    return localDate;
}

export const formatToClientTimezone = (isoDate:string) => {
    if(!isoDate || isoDate === "") return null
    const localDate = calculateTimezoneOffset(isoDate).toISOString();

    const [datePart, timePart] = localDate.split('T');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.split(':');

    return {year, month, day, hours, minutes, seconds};
}