import type React from "react"
import { useState, useEffect } from "react"
import type FieldProps from "./textFieldProps"
import { InputTypes } from "./textFieldProps"

interface Option {
  label: string | React.ReactElement
  value: string | number
}

const SelectField = ({
  model,
  setModel,
  campoId,
  placeholder,
  children,
  type,
  required,
  campoIdRespuesta,
  editable,
  title,
  options,
}: FieldProps & { editable: boolean; options: Option[] }) => {
  const [resumeValue, setResumeValue] = useState<any>(undefined)

  useEffect(() => {
    if (campoIdRespuesta && model.resume && model.resume[campoIdRespuesta] !== undefined) {
      setResumeValue(model.resume[campoIdRespuesta])
    }
  }, [model, campoIdRespuesta])

  const bgResp = model.approved ? "bg-[#C6F6D5] text-[#22543D]" : "bg-[#FED7D7] text-[#822727]"

  const [, setSelected] = useState(model[campoId] !== "" && model[campoId] !== null && model[campoId] !== 0)

  useEffect(() => {
    setSelected(model[campoId] !== "" && model[campoId] !== null && model[campoId] !== 0)
  }, [model, campoId])

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!editable) return
    const esNumero = type === InputTypes.Number
    const valor = esNumero ? Number.parseFloat(e.target.value) : e.target.value
    setModel((prevModel: any) => ({
      ...prevModel,
      [campoId]: valor,
    }))
    setSelected(valor !== "" && valor !== null && valor !== 0)
  }

  return (
    <div className="relative mb-4">
      <div className="flex items-center">
        <div className={`text-md text-[#4A5568] font-medium`}>{title}</div>
        {!editable && resumeValue !== undefined && (
          <div className={`${bgResp} w-auto px-1 h-[20px] text-center rounded-[2px] text-sm font-bold`}>
            {typeof resumeValue === "number" ? resumeValue.toFixed(1) : resumeValue}
          </div>
        )}
      </div>
      <div className="relative mt-[17px] mb-[38px]">
        <select
          required={required}
          className="w-full h-[48px] text-[#171923] font-sans font-normal px-3 py-2.5 rounded-2xl border"
          onChange={handleChange}
          value={model[campoId]}
          disabled={!editable}
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <label
          className={`absolute left-3.5 px-1 transition-all duration-300 pointer-events-none bg-white 
                        ${model[campoId] ? "-top-2 text-xs text-[#A0AEC0]" : "hidden"}`}
        >
          {placeholder}
        </label>
      </div>
    </div>
  )
}

export default SelectField

