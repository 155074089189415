import { useState, useEffect, useMemo } from "react";
import { IoArrowForward } from "react-icons/io5";
import LayoutDocumentos from "./layouts/layout-documentos";
import classNameByEvent from "app/carga-documentos/utilities/classNameByEvent";
import Modal from "app/components-finmaq/modal"; 
import { ConfigVariables } from "adapters/ports/config-variables";
import CardUploadFile from "app/components-finmaq/cardUploadFile";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import useWindowDimensions from "app/carga-documentos/utilities/useWindowDimensions";
import useDocumentState from "app/carga-documentos/utilities/useDocumentState";
import breakpoint from "app/carga-documentos/utilities/breakpoint";
import textsByRole from "app/carga-documentos/utilities/textsByRole";
import ModalGuardadoParcial from "app/components-finmaq/modal-guardado-parcial";
import { handleTagManagerSteps } from "app/utilities/gtm";
import { useCognitoUserAttributes } from 'app/login/adapters/useCognitoUserAttributes';
import { useGetContactWithProducts } from "app/carga-documentos/utilities/useGetContactWithProducts";
import { useHandleBeforeUnload } from "app/carga-documentos/utilities/useHandleBeforeUnload";
import { useHandleProductDone } from "app/carga-documentos/utilities/useHandleProductDone";

const statusActionsForBothButtons = {
    [`${uploadStatus.DISABLE}:${uploadStatus.DISABLE}`]: {
        arrowStatus: uploadStatus.DISABLE,
        buttonStatus: uploadStatus.HIDDEN,
    },
    [`${uploadStatus.DONE}:${uploadStatus.READY}`]: {
        arrowStatus: uploadStatus.HIDDEN,
        buttonStatus: uploadStatus.READY,
        mobileButtonStatus: uploadStatus.READY,
    },
    [`${uploadStatus.READY}:${uploadStatus.READY}`]: {
        buttonStatus: uploadStatus.READY,
    },
    [`${uploadStatus.READY}:${uploadStatus.PRELOADED}`]: {
        buttonStatus: uploadStatus.READY,
        arrowStatus: uploadStatus.READY,
        mobileButtonStatus: uploadStatus.HIDDEN
    },
    [`${uploadStatus.PRELOADED}:${uploadStatus.READY}`]: {
        buttonStatus: uploadStatus.READY,
    },
    [`${uploadStatus.READY}:${uploadStatus.DISABLE}`]: {
        arrowStatus: uploadStatus.READY,
        buttonStatus: uploadStatus.DISABLE
    },
    [`${uploadStatus.PRELOADED}:${uploadStatus.PRELOADED}`]: {
        buttonStatus: uploadStatus.HIDDEN,
    },
    [`${uploadStatus.DONE}:${uploadStatus.PRELOADED}`]: {
        buttonStatus: uploadStatus.HIDDEN,
    },
    [`${uploadStatus.DISABLE}:${uploadStatus.READY}`]: {
        buttonStatus: uploadStatus.DISABLE
    },
    [`${uploadStatus.DONE}:${uploadStatus.DISABLE}`]: {
        arrowStatus: uploadStatus.HIDDEN,
        buttonStatus: uploadStatus.DISABLE,
        mobileButtonStatus: uploadStatus.DISABLE
    },
    [`${uploadStatus.DISABLE}:${uploadStatus.PRELOADED}`]: {
        arrowStatus: uploadStatus.DISABLE,
        buttonStatus: uploadStatus.DISABLE,
        mobileButtonStatus: uploadStatus.HIDDEN
    },
    [`${uploadStatus.PRELOADED}:${uploadStatus.DISABLE}`]: {
        arrowStatus: uploadStatus.READY,
        buttonStatus: uploadStatus.DISABLE,
        mobileButtonStatus: uploadStatus.HIDDEN
    },
};

export default function CargaDocumentoIdentificacion() {
    const { cognitoUUID, person_type } = useCognitoUserAttributes();

    const { fileStatus:fileStatusOne,
            setFileStatus:setFileStatusOne,
            documentUrl:documentOneUrl, 
            setDocumentUrl:setDocumentOneUrl, 
            handleInput: handleInputOne, 
            handleSubmit: handleSubmitOne } = useDocumentState();
    const [isModalOpenOne, setIsModalOpenOne] = useState(false);
    const [classStepOne, setClassStepOne] = useState("");

    const { fileStatus:fileStatusTwo,
        setFileStatus:setFileStatusTwo,
        documentUrl:documentTwoUrl, 
        setDocumentUrl:setDocumentTwoUrl, 
        handleInput: handleInputTwo, 
        handleSubmit: handleSubmitTwo } = useDocumentState();
    const [isModalOpenTwo, setIsModalOpenTwo] = useState(false);
    const [classStepTwo, setClassStepTwo] = useState(uploadStatus.HIDDEN);

    const [arrowStatus, setArrowStatus] = useState(uploadStatus.DISABLE);
    const [buttonStatus, setButtonStatus] = useState(uploadStatus.HIDDEN);
    const [isModalOpenIntro, setIsModalOpenIntro] = useState(false);

    const { width } = useWindowDimensions();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { currentProduct } = useGetContactWithProducts();
    const productUUID = currentProduct["product_uuid" as keyof typeof currentProduct];
    const currentDocuments = currentProduct["documents" as keyof typeof currentProduct];

    const identification_document_title = textsByRole[person_type as keyof typeof textsByRole]?.identification_document_title;
    const identification_document_description = textsByRole[person_type as keyof typeof textsByRole]?.identification_document_description;
    
    const isAnyFieldFilled = useMemo(() => {
        return fileStatusOne === uploadStatus.READY || fileStatusTwo === uploadStatus.READY || (fileStatusOne === uploadStatus.PRELOADED && fileStatusTwo === uploadStatus.READY) || (fileStatusOne === uploadStatus.READY && fileStatusTwo === uploadStatus.PRELOADED);
    }, [fileStatusOne, fileStatusTwo]);

    const product_state = (currentProduct as unknown as { financial_product: {
        product_state: string
    } }).financial_product?.product_state;

    const { handleOpenModal } = useHandleBeforeUnload(isAnyFieldFilled, ()=> setIsModalOpen(true));

    useHandleProductDone(product_state);

    const handleSendDocuments = () => {
        if (fileStatusOne === uploadStatus.READY && fileStatusTwo !== uploadStatus.READY) {
            handleSubmitOne("IDENTIDAD_1");
        }
        if (fileStatusTwo === uploadStatus.READY && fileStatusOne !== uploadStatus.READY) {
            handleSubmitTwo("IDENTIDAD_2");
        }
        if (fileStatusOne === uploadStatus.READY && fileStatusTwo === uploadStatus.READY) {
            handleSubmitOne("IDENTIDAD_1");
            handleSubmitTwo("IDENTIDAD_2");
        }
    };

    const handleButtonClick = ()=>{
        const isDocOneReadyForSubmit = fileStatusOne === uploadStatus.READY || fileStatusOne === uploadStatus.ERROR;
        const isDocTwoReadyForSubmit = fileStatusTwo === uploadStatus.READY || fileStatusTwo === uploadStatus.ERROR;

        const tagManagerOnStepCompleted = ()=>{
            handleTagManagerSteps({
                action: "complete",
                cognitoUserId: cognitoUUID,
                productId: productUUID
            })
        }

        if(width <= breakpoint){
            if(isDocTwoReadyForSubmit) {
                handleSubmitTwo("IDENTIDAD_2");
                tagManagerOnStepCompleted()
            }
        }else{
            if(isDocOneReadyForSubmit && isDocTwoReadyForSubmit){
                handleSubmitOne("IDENTIDAD_1");
                handleSubmitTwo("IDENTIDAD_2");
                tagManagerOnStepCompleted()
            }else if(fileStatusOne === uploadStatus.PRELOADED && isDocTwoReadyForSubmit){
                handleSubmitTwo("IDENTIDAD_2");
                tagManagerOnStepCompleted()
            }else if(isDocOneReadyForSubmit && fileStatusTwo === uploadStatus.PRELOADED){
                handleSubmitOne("IDENTIDAD_1");
                tagManagerOnStepCompleted()
            }
        }
    }

    const handleArrowClick = ()=>{
        if(fileStatusOne !== uploadStatus.PRELOADED && fileStatusOne !== uploadStatus.READY) return false
 
        if((fileStatusOne === uploadStatus.READY || fileStatusOne === uploadStatus.ERROR)) handleSubmitOne("IDENTIDAD_1");

        setTimeout(()=>{
            setClassStepOne(uploadStatus.HIDDEN);
            setClassStepTwo("");
            setArrowStatus(uploadStatus.HIDDEN);

            if(documentOneUrl && documentTwoUrl){
                setButtonStatus(uploadStatus.HIDDEN);
            }else if(documentTwoUrl){
                setButtonStatus(uploadStatus.READY);
            }else{
                setButtonStatus(uploadStatus.DISABLE);
            }
        }, (fileStatusOne === uploadStatus.READY ? 3000 : 1))
    }

    useEffect(()=>{
        const statusKey = `${fileStatusOne}:${fileStatusTwo}`;
        const actions = statusActionsForBothButtons[statusKey];

        if (actions) {
            if(actions.arrowStatus) setArrowStatus(actions.arrowStatus);
            if(actions.buttonStatus && width >= breakpoint) setButtonStatus(actions.buttonStatus);
            if(actions.mobileButtonStatus && width < breakpoint) setButtonStatus(actions.mobileButtonStatus);
        }
    },[fileStatusOne, fileStatusTwo, width])

    useEffect(()=>{
        const isFileOnePreloadedOrReadyToUpload = currentDocuments?.identity_1?.file_path || fileStatusOne !== uploadStatus.DISABLE;
        const isFileTwoPreloadedOrReadyToUpload = currentDocuments?.identity_2?.file_path || fileStatusTwo !== uploadStatus.DISABLE;

        if(currentDocuments?.identity_1?.file_path && !documentOneUrl){
            setDocumentOneUrl(currentDocuments?.identity_1?.file_path);
            setArrowStatus(uploadStatus.READY);
            setFileStatusOne(uploadStatus.PRELOADED);
        } 

        if(currentDocuments?.identity_2?.file_path && !documentTwoUrl){
            setDocumentTwoUrl(currentDocuments?.identity_2?.file_path); 
            setFileStatusTwo(uploadStatus.PRELOADED);
        }

        if(!isFileOnePreloadedOrReadyToUpload && !isFileTwoPreloadedOrReadyToUpload && Object.keys(currentProduct).length > 0 && !localStorage.getItem("popupIntroDocuments")){
            localStorage.setItem("popupIntroDocuments","ok")
            setIsModalOpenIntro(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[documentOneUrl, documentTwoUrl, fileStatusOne, fileStatusTwo, currentProduct])

    useEffect(()=>{
        if(cognitoUUID && productUUID){
            handleTagManagerSteps({
                action: "enter",
                cognitoUserId: cognitoUUID,
                productId: productUUID
            })
        }
    },[cognitoUUID, productUUID])

    return (
        <LayoutDocumentos
            handleButtonClick={handleButtonClick} 
            buttonLabel="Guardar"
            buttonClassName={`${classNameByEvent(buttonStatus)} lg:inline-block`}
            hasFooter={true}>
            <Modal
                message="Al subir archivos, asegúrese de que la imagen esté completa, sin tachones, partes cortadas o borrosas."
                imageUrl={ConfigVariables.IMAGEN_RECOMIENDACION_COMPLETA}
                primaryButtonLabel="OK"
                isOpen={isModalOpenIntro}
                handleCloseModal={()=>setIsModalOpenIntro(false)}
                handlePrimaryButton={()=>setIsModalOpenIntro(false)}/>
            <div className={`${!currentDocuments ? `skeleton-animation` : ``}`}>
                <div className="hidden lg:flex text-[#718096] ml-[105px] text-md font-normal"><span>Solicitud de crédito </span><span className="ml-1 text-[#000000]"> / {identification_document_title}</span></div>

                <div className="lg:mt-7 lg:ml-14 flex items-center">
                    {isModalOpen && (
                        <ModalGuardadoParcial onClose={()=> setIsModalOpen(false)} onHandleProp={handleSendDocuments} /> 
                    )}
                    <button type="button" onClick={handleOpenModal} aria-label="Abrir modal">
                        <svg className="text-[#390094]" width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <p className="skeleton-pulse text-start text-[#390094] font-bold text-xl ml-2 lg:font-semibold lg:text-2xl lg:ml-[15px]">{identification_document_title}</p>
                </div>
                <div  className="text-[#171923] text-md font-normal lg:ml-[105px] lg:mr-[105px] lg:mt-2 mt-7 leading-6 lg:leading-normal w-full lg:w-auto">
                    <p className="skeleton-pulse">{identification_document_description}</p>
                </div>
                <div className="block lg:flex lg:justify-between flex-col lg:flex-row lg:pl-[105px] lg:pr-[105px] lg:gap-11 mt-[60px] lg:mt-[75px]">
                    <CardUploadFile
                        key={`documentOne_${documentOneUrl}`}
                        fileLimit={4928307}
                        fileStatus={fileStatusOne}
                        fileName={documentOneUrl}
                        className={`${classStepOne} lg:block lg:w-[40%]`}
                        description={<div>
                            <p className="text-xl font-semibold text-[#000000]">Frente de la identificación</p>
                            <p className="mt-2 font-normal text-md leading-5">Seleccione el archivo de la parte <strong>frontal</strong> de su documento de identificación.</p>
                        </div>}
                        modal={
                            <Modal
                                className="lg:hidden"
                                title="Use el lado de su documento donde está la foto"
                                message="Verifique que la imagen que suba esté enfocada y se lean los datos correctamente."
                                imageUrl={ConfigVariables.IMAGEN_CEDULA_ADELANTE}
                                isOpen={isModalOpenOne}
                                primaryButtonLabel="OK"
                                handleCloseModal={()=>setIsModalOpenOne(false)}
                                handlePrimaryButton={()=>setIsModalOpenOne(false)}/>
                            }
                        redirectAfterSubmit={width > breakpoint}
                        handleInput={handleInputOne}
                        handleSubmit={handleButtonClick}
                        handleShowModal={(e)=>setIsModalOpenOne(e)}/>
                    <CardUploadFile
                        key={`documentTwo_${documentTwoUrl}`}
                        fileLimit={4928307}
                        fileStatus={fileStatusTwo}
                        fileName={documentTwoUrl}
                        className={`${classStepTwo} lg:block lg:w-[40%]`}
                        description={<div>
                            <p className="text-xl font-semibold text-[#000000]">Reverso de la identificación</p>
                            <p className="mt-2 font-normal text-md leading-5">Seleccione el archivo de la parte de <strong>atrás</strong> de su documento de identificación.</p>
                        </div>}
                        modal={
                            <Modal
                                className="lg:hidden"
                                title="Ahora dele la vuelta a su documento"
                                message="Verifique que la imagen que suba esté enfocada y se lean los datos correctamente."
                                imageUrl={ConfigVariables.IMAGEN_CEDULA_ATRAS}
                                isOpen={isModalOpenTwo} 
                                primaryButtonLabel="OK"
                                handleCloseModal={()=>setIsModalOpenTwo(false)}
                                handlePrimaryButton={()=>setIsModalOpenTwo(false)}/>
                            }
                        handleInput={handleInputTwo}
                        handleSubmit={handleButtonClick}
                        handleShowModal={(e)=>setIsModalOpenTwo(e)}/>
                </div>
                <div className="lg:hidden flex justify-end fixed bottom-5 right-5 z-[51]">
                    
                    <button
                        className={`rounded-[33px] h-[48px] w-[48px] ${classNameByEvent(arrowStatus)}`}
                        type={'submit'}
                        aria-label="Enviar"
                        onClick={handleArrowClick}>   
                        <div className="mr-[16px] ml-[16px]">
                            <IoArrowForward size={18} />
                        </div>
                    </button>
                </div>
            </div>
        </LayoutDocumentos>               
    )
}