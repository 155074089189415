import { useState } from "react";
import SolicitudesHubspotTable from "../components/tabla/tabla-solicitudes-por-revisar";
import SideBar from "app/analista/page-analist/interfaces/components/side-bar/side-bar";
import { useCognitoUserAttributes } from "app/login/adapters/useCognitoUserAttributes";
import BannerSolicitudes from "../components/banner/banner-solicitudes";

export default function SolicitudesPorRevisar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const { analista_de_credito_asignado } = useCognitoUserAttributes()

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <SideBar isOpen={isSidebarOpen} onToggle={toggleSidebar} />
      <div className={`flex-1 p-5 ${isSidebarOpen ? "ml-24" : "ml-[320px]"} overflow-hidden flex flex-col`}>
        <BannerSolicitudes estadoSolicitud="por revisar" />
        <div className="mt-3 flex overflow-hidden">
          <SolicitudesHubspotTable analista_de_credito_asignado={analista_de_credito_asignado} />
        </div>
      </div>
    </div>
  )
}
