import { SortButton } from "./SortButton"

interface SortButtonProps {
    sortDirection: "ASCENDING" | "DESCENDING"
    sortProperty: string
    handleSort: (property:string) => void
}

const columns = [
    { label: "CLIENTE / NEGOCIO", property: "dealname" },
    { label: "NIT / CC", property: "numero_de_identificacion_del_contacto" },
    { label: "FECHA RADICADO", property: "fecha_de_radicado_definitivo" },
    { label: "VALOR CRÉDITO", property: "amount" },
    { label: "ESTADO", property: "dealstage" }
];

export const SortButtons = ({sortDirection, sortProperty, handleSort}:SortButtonProps)=>{
    return (
        <tr className="border-b border-[#E2E8F0]">
            {columns.map(({ label, property }) => (
                <th key={property} className="w-1/4">
                    <span>{label}</span>
                    <SortButton 
                        property={property}
                        sortDirection={sortDirection}
                        sortProperty={sortProperty}
                        handleSort={handleSort} 
                    />
                </th>
            ))}
        </tr>
    )
}