
import httpClientService from "http/httpClientService";
import { FormularioDTO } from "../../adapters/dto/formulario-dto";
import { FormularioRespuestaDTO } from "../../adapters/dto/resultado-dto";
import { ConfigVariables } from "adapters/ports/config-variables";

class FormularioService {
    private UrlApiScoring = `${ConfigVariables.URL_BASE_API_SCORING}/scoring`

    public async enviar (fomularioNuevo : FormularioDTO) {
        const resp = await httpClientService.post(this.UrlApiScoring, fomularioNuevo);
        console.log(resp)
        return resp;
    }

    public async obtener (formularioResultado: FormularioRespuestaDTO) {
        let Resultado = await httpClientService.get(this.UrlApiScoring, formularioResultado)
        return Resultado;
    }
}

const formularioNuevo = new FormularioService();
export default formularioNuevo