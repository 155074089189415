import { Card, CardBody, Typography } from "@material-tailwind/react";

export function CardReusable ({ title, description, isApproved, puntaje } : {title : string, description : string, isApproved : boolean, puntaje : string}) {
    
    const cardClass = isApproved ? 'bg-[#F0FFF4]' : 'bg-[#FFF5F5] ';
    const cardDescription = isApproved? 'text-[#38A169]' : 'text-[#E53E3E]';

    return (
        <Card placeholder={""} className={`mt-[57px] h-[216px] rounded-[20px] ${cardClass}`}>
            <CardBody placeholder={""} className='ml-[68px] mt-[22px]'>
                <Typography placeholder={""} className="text-lg font-bold text-[#171923]">
                    {title}
                </Typography>
                <Typography placeholder={""} className={`mb-[54px]   grid grid-cols-2 ${cardDescription}`}>
                    <div className='text-6xl font-extrabold mt-[26px]'>{description}</div>
                    <div className="flex justify-center items-start ">
                        <table>
                            <tr>
                                <td className='text-6xl font-extrabold flex justify-center'>
                                    {puntaje}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center text-2xl font-medium text-[#171923]">
                                    Scoring
                                </td>
                            </tr>
                        </table>
                    </div>
                </Typography>
            </CardBody>
        </Card>
    );
}
