import ResponsiveTooltip from "app/components-finmaq/responsiveTooltip";
import { formatToClientTimezone } from "app/mis-creditos/utilities/formatToClientTimezone";
import { useEffect, useState } from "react";

interface DateInputProps {
    lockInputClassName: string
    styleClass: string
    section: string
    group: string
    fieldIndex: number
    labelClassName: string
    defaultValue: string
    label: string
    previousLock: boolean
    tooltip: string
    name: string
    handleChange: () => void
}

const DateInput = ({lockInputClassName,styleClass,section,group,fieldIndex,labelClassName,defaultValue,label,previousLock,tooltip,name,handleChange}:DateInputProps)=>{
    const [ isoDate, setIsoDate ] = useState("");
    const [ visualDate, setVisualDate ] = useState("");

    const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>)=>{
        const selectedDate = event.target.value;
        const currentDate = new Date();
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        setIsoDate(`${selectedDate}T${hours}:${minutes}:${seconds}`);
        setVisualDate(`${selectedDate}`);

        handleChange();
    }

    useEffect(()=>{
        const {year, month, day} = formatToClientTimezone(defaultValue) || {};

        setIsoDate(defaultValue);
        setVisualDate(`${year}-${month}-${day}`)
    }, [defaultValue])
    
    return <div className={`relative h-[40px] mt-[17px] mb-4 font-Poppins font-normal p-1.5 rounded-[10px] border cursor-pointer peer bg-white text-[#A0AEC0] ${lockInputClassName} ${styleClass} field-container-date field-container-${section}_${group}_${name}`} key={`${name}_${fieldIndex}`}>
        <input id={`${name}_${fieldIndex}`} type="date" className={`w-full h-full focus:outline-none multistep-input ${labelClassName}`} placeholder={label} value={visualDate} readOnly={defaultValue !== "" && previousLock} onChange={handleChangeDate}/>
        <input className={`field-${section}_${group}_${name}`} type="hidden" defaultValue={isoDate}/>
        <label htmlFor={`${name}_${fieldIndex}`} className="absolute left-[13px] top-[5px] transition-all duration-300 text-[#A0AEC0] border-white text-[#a0aec2]">
            <span className="bg-white">{label}</span>
            {tooltip !== "" && 
                <ResponsiveTooltip text={tooltip} color="#A0AEC0"/>
            }
        </label>
        <span className="error-message absolute bottom-[-15px] left-[14px] text-[11px] bottom-[-19px] text-[#ff6347] hidden"></span>
    </div>
}

export default DateInput;