import SelectField from "../utilities/selectField";
import CompilarTextField from "../utilities/textField";
import { FormProps, InputTypes } from "../utilities/textFieldProps";

export default function FormularioDatosDelCliente({formulario, setFormulario, editable}: {formulario : any, setFormulario : any, editable: boolean}) {

    return (
        <>  
            <div>
                <div className="flex"><span className="text-md text-[#4A5568] font-medium">Tiempo de experiencia</span>
                    <div className="relative group inline-block ml-1 mt-1">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cursor-pointer"
                        >
                            <path
                                d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="absolute text-center z-50 left-[270px] top-4 bottom-full hidden py-0.5 text-sm w-[522px] h-[122px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                            Inicio de actividad: Tiempo promedio que el cliente lleva en su actividad actual. Se autocompletará con el valor ingresado en FinMaq Go.
                            <br></br><br></br>
                            Meses de experiencia en el sector: Total de meses trabajados en el sector, incluyendo experiencia en diferentes roles. Se autocompletará con el valor ingresado en FinMaq Go.
                        </div>
                    </div> 
                </div>
                <div className="flex grid grid-cols-2 gap-4">
                    <MesesIncorporacion model={formulario} setModel={setFormulario} editable={editable}/>
                    <MesesExperienciaSector model={formulario} setModel={setFormulario} editable={editable}/>
                </div>
            </div>
            <div>
                <div className="text-md text-[#4A5568] font-medium">Ingresos por extractos</div>
                <div className="flex grid grid-cols-2 gap-4">
                    <IngresosReportados model={formulario} setModel={setFormulario} editable={editable}/>
                    <IngresosPorExtractos model={formulario} setModel={setFormulario} editable={editable}/>      
                </div>
            </div>
            <div className="flex grid grid-cols-2 gap-4">
                <div>
                    <div className="flex"><span className="text-md text-[#4A5568] font-medium">Experiencia en montos similares</span>
                        <div className="relative group inline-block ml-1 mt-1">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[275px] top-4 bottom-full hidden py-0.5 text-sm w-[539px] h-[82px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Valor aproximado de las obligaciones ya saldadas o en caso de no tenerlas, <br></br>es posible tomarlo de las obligaciones vigentes. <br></br>Consulta el PDF de Información comercial de TransUnion en la sección<br></br>de Informe detallado
                            </div>
                        </div> 
                    </div>
                    <ExperienciaMontos model={formulario} setModel={setFormulario} editable={editable}/>
                </div>
                <div>
                    <div className="flex"><span className="text-md text-[#4A5568] font-medium">Ubicación</span>
                        <div className="relative group inline-block ml-1 mt-1">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[190px] top-4 bottom-full hidden py-0.5 text-sm w-[363px] h-[22px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Opción seleccionada en el formulario de FinMaq Go
                            </div>
                        </div> 
                    </div>
                    <CiudadCliente model={formulario} setModel={setFormulario} editable={editable}/> 
                </div>
            </div>
        </>
    );
}

const MesesIncorporacion = ({ model, setModel, editable }: FormProps) => {

    return (
        <CompilarTextField
            model={model}
            setModel={setModel}
            campoId='date_of_incorporation'
            campoIdRespuesta='months_of_incorporation'
            type={InputTypes.Date}
            editable={editable}
        />
    )
};

const MesesExperienciaSector = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {

    return(
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='arrears_amount'  
            campoIdRespuesta={'score_arrears'}
            placeholder={'Meses de experiencia en el sector'}
            className={'w-[262px]'}
            type={InputTypes.Number}
            min={0}
            editable={editable}
        />

        
    )
}

const IngresosReportados = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='preapproved_incomes'
            campoIdRespuesta='incomes_score'
            placeholder={
                <>
                    <div className="flex">
                        <div>
                        Ingresos mensuales aproximados
                        </div>
                        <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[200px] bottom-full hidden py-0.5 text-sm w-[383px] h-[44px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Valor diligenciado en el campo de Ingresos Mensuales<br></br> Aproximados de Pre-Aprobados
                            </div>
                        </div>
                    </div>
                </>
            }
            type={InputTypes.Money}
            editable={editable}
        />
    )
}

const IngresosPorExtractos = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='incomes_extracts'
            placeholder={
                <>
                    <div className="flex">
                        <div>
                            Promedio mensual (últimos 3 meses)
                        </div>
                        <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 -left-[185px] bottom-full hidden py-0.5 text-sm w-[387px] h-[44px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Promedio del valor expuesto en los extractos bancarios <br></br>y/o recibos de los últimos tres meses
                            </div>
                        </div>
                    </div>
                </>
            }
            type={InputTypes.Money}
            editable={editable}
        />
    )
}

const ExperienciaMontos = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
        model={formulario}
        required={true}
        setModel={setFormulario}
        campoId='transunion_amounts'
        campoIdRespuesta='amounts_score'
        placeholder='Valor en experiencias similares'
        type={InputTypes.Money}
        min={0}
        editable={editable}
        />
    )
}

const CiudadCliente = ({model : formulario, setModel : setFormulario, editable}: FormProps) => {
    return (
        <SelectField
            required={true}
            model={formulario}
            setModel={setFormulario}
            campoId='department'
            campoIdRespuesta='department_score'
            type={InputTypes.Text}
            placeholder='Departamento'
            editable={editable}
            options={[
                {label:"Departamento", value:""},
                {label:"Amazonas", value:"amazonas"},
                {label:"Antioquía", value:"antioquía"},
                {label:"Arauca", value:"arauca"},
                {label:"Atlántico", value:"atlántico"},
                {label:"Bolívar", value:"bolívar"},
                {label:"Boyacá", value:"boyacá"},
                {label:"Caldas", value:"caldas"},
                {label:"Caquetá", value:"caquetá"},
                {label:"Casanare", value:"casanare"},
                {label:"Cauca", value:"cauca"},
                {label:"Cesar", value:"cesar"},
                {label:"Chocó", value:"chocó"},
                {label:"Córdoba", value:"córdoba"},
                {label:"Cundinamarca", value:"cundinamarca"},
                {label:"Bogotá", value:"bogotá"},
                {label:"Guainia", value:"guainia"},
                {label:"Guaviare", value:"guaviare"},
                {label:"Huila", value:"huila"},
                {label:"Guajira", value:"guajira"},
                {label:"Magdalena", value:"magdalena"},
                {label:"Meta", value:"meta"},
                {label:"Nariño", value:"nariño"},
                {label:"Norte de Santander", value:"santander"},
                {label:"Putumayo", value:"putumayo"},
                {label:"Quindío", value:"quindío"},
                {label:"Risaralda", value:"risaralda"},
                {label:"San Andres y Providencia", value:"san andres y providencia"},
                {label:"Santander", value:"santander"},
                {label:"Sucre", value:"sucre"},
                {label:"Tolima", value:"tolima"},
                {label:"ValledelCauca", value:"cauca"},
                {label:"Vaupés", value:"vaupés"},
                {label:"Vichada", value:"vichada"}
            ]}
        >
        </SelectField>
    )
}
