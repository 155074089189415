import TagManager from "react-gtm-module";

declare global {
    interface Window {
      dataLayer: any[];
    }
  }

export interface TagManagerProps {
    event?: string
    cognitoUserId?: string
    type?: string
    elementName?: string
    step?: string
    action?: "enter" | "complete"
    productId?: string
    timeStamp?: number
    timeStampString?: string
    loadTime?: number
    age?: number
    gender?: string
    alertName?: string
    pagePath?: string
    pageLoadTime?: string
}
 
export const handleTagManager = (props: TagManagerProps)=>{
    return TagManager.dataLayer({
        dataLayer: props
    })
}

export const handleTagManagerSteps = ({action, cognitoUserId, productId}: TagManagerProps)=>{
    handleTagManager({
        event: "step_action",
        step: window.location.pathname.split("/")[1],
        action,
        cognitoUserId,
        productId,
        timeStamp: new Date().valueOf(),
        timeStampString: new Date().toLocaleString('sv-SE', {
            timeZone: 'America/Bogota',
            hour12: false
        })
    });
}
