import { useState, useRef, useEffect, useCallback } from "react";
import BtnUploadFile from "./btnUploadFile"; 
import StatusUploadFile from "./statusUploadFile";
import useWindowDimensions from "app/carga-documentos/utilities/useWindowDimensions";
import breakpoint from "app/carga-documentos/utilities/breakpoint";
import ModalEliminarArchivos from "../modal-eliminar-archivos";
import { useCognitoUserAttributes } from "app/login/adapters/useCognitoUserAttributes";
import deleteFilesService from "app/carga-documentos/adapters/ports/http-delete-files-service";
import { handleTagManager } from "app/utilities/gtm";
import { useGetContactWithProducts } from "app/carga-documentos/utilities/useGetContactWithProducts";
import ToastCargaDocs from "./toast-carga-documentos";
import ToastFailCargaDocs from "./toast-fail-carga-documentos";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";

interface CardProps {
  fileLimit: number;
  fileStatus: string;
  fileName?: string;
  className?: string;
  description?: React.ReactNode;
  buttonLabel?: string;
  modal?: React.ReactNode;
  redirectAfterSubmit?: boolean,
  handleInput: (e: {}) => void;
  handleShowModal?: (e: boolean) => void;
  setFileStatus?: (e:string) => void;
  handleSubmit: ()=>void
}

const CardUploadFile = ({
  fileName = "",
  fileLimit,
  fileStatus,
  className = "",
  description = "",
  buttonLabel = "Elija un archivo",
  modal = null,
  redirectAfterSubmit = true,
  handleInput,
  handleShowModal,
  setFileStatus,
  handleSubmit
}: CardProps) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [extension, setExtension] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [isModalClicked, setIsModalClicked] = useState(false);
  const [fetchFilename, setFetchFilename] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [closeToast, setCloseToast] = useState(false);
  const [deletedFile, setDeletedFile] = useState(false);
  
  const { person_type, identity_type, identity_number, cognitoUUID } = useCognitoUserAttributes();
  const { currentProduct } = useGetContactWithProducts();
  const productUUID = currentProduct["product_uuid" as keyof typeof currentProduct];
  const fileNameToShow = selectedFile ? selectedFile.name : fileName ? fileName.split("/").pop() : "";

  const { width } = useWindowDimensions();
  const inputRef = useRef<HTMLInputElement>(null);

  const onChooseFile = () => {
    if (modal && width <= breakpoint && !isModalClicked) {
      handleShowModal(true);
      setIsModalClicked(true);
    } else {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const extension = file?.name.split(".").pop();

      const isCorrectFileSize = file.size <= fileLimit;
      const isCorrectExtension =
        extension === "pdf" || extension === "jpg" || extension === "jpeg";

      if (isCorrectExtension) setExtension(extension);
      if (isCorrectFileSize) setFileSize(file.size);

      setSelectedFile(file);

      if (isCorrectExtension && isCorrectFileSize) handleInput(file);
    }
  };

  const resetInputStates = useCallback(()=>{
    inputRef.current.value = "";
    setSelectedFile(null);
    setExtension("");
    setFileSize(0);
    setFetchFilename("");
    handleInput(null);

    handleTagManager({
      event: "element_clicked",
      type: "button",
      elementName: "eliminar_archivo",
      cognitoUserId: cognitoUUID
    })
  },[handleInput,cognitoUUID])

  const clearFileInput = useCallback(() => {
    if (fetchFilename) {
      setIsModalOpen(true);
    } else {
      resetInputStates();
    }
  },[fetchFilename, resetInputStates]);

  const handleOnProp = async () => {

    if (!person_type || !identity_type || !identity_number || !productUUID) {
      return;
    }

    try {
      const parts = fileName.split("/");
      let document_type = parts[parts.length - 1];
      document_type = document_type.split(".")[0];

      await deleteFilesService.delete(person_type, identity_type, identity_number, document_type, productUUID);
      setDeletedFile(true)
      resetInputStates();
    } catch (error) {
      setFileStatus(uploadStatus.ERROR)
      console.error("Error al eliminar el archivo:", error);
    }

    setIsModalOpen(false)
  };

  const onTimeoutDeletedFile = ()=>{
    setDeletedFile(false)
    setCloseToast(true)
  }

  useEffect(() => {
    if (fileName !== "") {
      const parts = fileName.split("/"); 
      const fileNameFromBackend = parts[parts.length - 1]; 
      setFetchFilename(fileNameFromBackend);
      setExtension(fileNameFromBackend.split(".").pop());
      setFileSize(1);
    }
  }, [fileName]);

  return (
    <div className={`${className}`}>
      {deletedFile && !closeToast &&
        <ToastCargaDocs 
          message={"Archivo borrado satisfactoriamente"}
          onTimeout={()=> onTimeoutDeletedFile()}></ToastCargaDocs>
      }
      {fileStatus === uploadStatus.DONE && !closeToast &&
        <ToastCargaDocs 
          message={"Tu información se guardó satisfactoriamente"}
          onTimeout={()=> setCloseToast(true)}></ToastCargaDocs>
      }
      {fileStatus === uploadStatus.ERROR && !closeToast &&
        <ToastFailCargaDocs onClose={()=> setCloseToast(true)} onClick={handleSubmit} />
      }
      <div className="w-auto">
        {modal}
        {isModalOpen && (
          <ModalEliminarArchivos
            onClose={()=> setIsModalOpen(false)}
            onHandleProp={handleOnProp}
          />
        )}
        <input
          ref={inputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        {description}

        {(!selectedFile && fetchFilename === "") && (
          <div onClick={onChooseFile}>
            <BtnUploadFile
              buttonLabel={buttonLabel}
              fileLimit={fileLimit}
            ></BtnUploadFile>
          </div>
        )}

        {(fetchFilename !== "" || selectedFile) && (
          <StatusUploadFile
            fileName={fileNameToShow}
            extension={extension}
            fileSize={fileSize}
            fileLimit={fileLimit}
            fileStatus={fileStatus}
            redirectAfterSubmit={redirectAfterSubmit}
            clearFileInput={clearFileInput}
          ></StatusUploadFile>
        )}
      </div>
    </div>
  );
};

export default CardUploadFile;

