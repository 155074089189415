import { useEffect } from "react";
import { ConfigVariables } from "adapters/ports/config-variables";
import { createPortal } from "react-dom";
import { handleTagManager } from "app/utilities/gtm";

interface ToastFailCargaDocsProps {
    onClick: () => void;
    onClose: () => void;
}

export default function ToastFailCargaDocs ({ onClick, onClose }: ToastFailCargaDocsProps) {
    useEffect(()=>{
        handleTagManager({
            event: "alert_showed",
            type: "toast",
            alertName: "error_guardado_cambios",
            step: window.location.pathname.split("/")[1]
        })
    },[])
    return (
        createPortal(
            <div className="fixed bottom-0 left-0 w-full h-auto bg-[#000000] bg-opacity-0 z-[53] animate-appear opacity-0">
                <div className="flex justify-end lg:mb-2 lg:mr-3 gap-3">
                    <div className="flex bg-[#FED7D7] w-[450px] text-md text-end text-[#2D3748] p-2">
                        <img src={ConfigVariables.ICON_ALERT_ERROR} width={20} height={20} alt='' className="mr-3 ml-3" />
                        <p className='text-start ml-2'>No hemos podido guardar sus cambios. Por favor, intente de nuevo.</p>
                        <div className="flex justify-center items-center gap-3 text-[#E53E3E] mr-4 cursor-pointer">
                            <button onClick={onClick}>Reintentar</button>
                            <div className="w-[20px] h-[20px]" onClick={onClose}>
                                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>, document.body
        )
    )
}
