const uploadStatus = {
    DISABLE: "disable",
    READY: "ready",
    UPLOADING: "uploading",
    DONE: "done",
    HIDDEN: "hidden",
    ERROR: "error",
    PRELOADED: "preloaded"
}

export default uploadStatus