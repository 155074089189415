export class SolicitudesHubspotDTO {
  dealname: string
  numero_de_identificacion_del_contacto: string
  fecha_de_radicado_definitivo: Date
  amount: string
  dealstage: string
  ingresar_a_standby: string

  constructor(data: any) {
    this.dealname = data.properties.dealname
    this.numero_de_identificacion_del_contacto = data.properties.numero_de_identificacion_del_contacto
    this.fecha_de_radicado_definitivo = data.properties.fecha_de_radicado_definitivo ? new Date(`${data.properties.fecha_de_radicado_definitivo}T00:00:00`) : null
    this.amount = data.properties.amount
    this.dealstage = data.properties.dealstage
    this.ingresar_a_standby = data.properties.ingresar_a_standby
  }

  static fromApiResponse(apiResponse: any): {
    solicitudes: SolicitudesHubspotDTO[]
    total: number
    nextPage: number | null
  } {
    const solicitudes = apiResponse.results.map((result: any) => new SolicitudesHubspotDTO(result))
    const total = apiResponse.total
    const nextPage = apiResponse.paging?.next?.after || null
    return { solicitudes, total, nextPage }
  }
}
