import fileIcons from "app/carga-documentos/utilities/fileIcons";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface StatusProps {
    fileName?: string,
    fileStatus: string,
    extension: string,
    fileSize: number,
    fileLimit: number,
    redirectAfterSubmit?: boolean,
    clearFileInput: ()=>void,
}

interface FileIconsProps{
    pdf: string,
    jpg: string,
    jpeg: string,
    error: string
}

const StatusUploadFile = ({fileName = "", fileStatus, extension,fileSize, fileLimit, redirectAfterSubmit = true, clearFileInput}: StatusProps)=>{
    const navigate = useNavigate();
    const { productIndex } = useParams();
    const validExtension = fileIcons[extension as keyof FileIconsProps]; 
    const validFileSize = fileSize > 0;

    useEffect(() => {
        if (fileStatus === uploadStatus.DONE && redirectAfterSubmit) {
            const timer = setTimeout(() => {
                navigate(`/carga-documentos/${productIndex}`);
            }, 3000); 
            return () => clearTimeout(timer);
        }
    }, [fileStatus, navigate, productIndex, redirectAfterSubmit]);

    return(
        <div className="rounded-[16px] mt-7">
            <div className="bg-[#F9FAFF] p-2 h-full w-full rounded-[16px] flex items-center">
                <div className="border-[1px] border-[#CBD5E0] w-16 h-16 rounded-xl bg-white flex justify-center items-center relative">
                    { fileStatus === uploadStatus.DONE &&
                        <svg className="absolute icon-verified__done opacity-0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
                            <circle cx="24" cy="24" r="20" fill="#38A169"></circle><path fill="#fff" d="M22.491,30.69c-0.576,0-1.152-0.22-1.591-0.659l-6.083-6.084c-0.879-0.878-0.879-2.303,0-3.182 c0.878-0.879,2.304-0.879,3.182,0l6.083,6.084c0.879,0.878,0.879,2.303,0,3.182C23.643,30.47,23.067,30.69,22.491,30.69z"></path><path fill="#fff" d="M22.491,30.69c-0.576,0-1.152-0.22-1.591-0.659c-0.879-0.878-0.879-2.303,0-3.182l9.539-9.539 c0.878-0.879,2.304-0.879,3.182,0c0.879,0.878,0.879,2.303,0,3.182l-9.539,9.539C23.643,30.47,23.067,30.69,22.491,30.69z"></path>
                        </svg>
                    }
                  <img className="h-12" src={ validExtension || fileIcons.error} alt="Icon PDF file"/>
                </div>
                <div className="ml-2 max-w-[70%]">
                    <p className="truncate">{validExtension ? fileName : ""}</p>

                    { !validExtension &&
                        <p className="text-[#E53E3E] text-sm mb-1">No se puede cargar el archivo<span className="text-xs block">formatos aceptados: .JPG, .PDF</span></p>
                    }

                    { !validFileSize &&
                      <p className="text-[#E53E3E] text-sm">El archivo es muy grande<span className="text-xs block">(máx: {Math.round(fileLimit / 1024 / 1024 * 10)/10}MB)</span></p>
                    }

                    { (validFileSize && validExtension && fileStatus !== uploadStatus.DONE && fileStatus !== uploadStatus.DISABLE && fileStatus !== uploadStatus.PRELOADED) &&
                        <div className={"w-[80%] max-w-56 bg-[#EDF2F7] rounded-md h-1 my-1 "+(fileStatus === uploadStatus.READY ? "upload-bar--vanish" : "")}>
                            <div className={"bg-[#38A169] h-1 rounded-md upload-file--preload" + (fileStatus === uploadStatus.UPLOADING ? ' upload-file--loading' : '')}></div>
                        </div>
                    }
                    { (validFileSize && validExtension && fileSize > 1) &&
                        <p className="text-[#A0AEC0] text-xs">{fileSize > (1048576) ? `${Math.round(fileSize / 1024 / 1024 * 10) / 10}MB` : `${Math.round(fileSize/1024)}KB`}</p>
                    }
                </div>
                <svg onClick={clearFileInput} className="ml-auto cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 10.8891L15.8891 7L17 8.11094L13.1109 12L17 15.8891L15.8891 17L12 13.1109L8.11094 17L7 15.8891L10.8891 12L7 8.11094L8.11094 7L12 10.8891Z" fill="#2D3748"/>
                </svg>
            </div>
        </div>  
    )
}

export default StatusUploadFile
