import { useState } from "react";
import StepperScoring from "../stepper/stepper-scoring";

interface CardScoringProps {
    solicitud: any
  }
  
  export default function CardScoring({ solicitud }: CardScoringProps) {
    
    const [formulario, setFormulario] = useState(solicitud || {})
    const [editable, ] = useState(true)
  
    return (
        <div className="w-full bg-white rounded-[24px] p-5 flex flex-col overflow-hidden">
            <div className="flex justify-between items-center">
                <div className="text-[#4A5568] text-2xl font-semibold">Análisis de variables</div>
                <a href="https://fsmsas.sharepoint.com/sites/FSM/Documentos%20compartidos/Forms/AllItems.aspx?csf=1&web=1&e=f3CZSu&CID=31f0b6ef%2Dbe67%2D45f8%2Dab7f%2D5ee9901a45e6&FolderCTID=0x012000E2E523EBE8E1EB4AA6D36045B168895C&id=%2Fsites%2FFSM%2FDocumentos%20compartidos%2FFSM%2FClientes%20Estudio1%2FFinMaq%20GO" target="_blank" rel="noreferrer">
                    <div className="bg-[#EDF2F7] rounded-[26px] px-4 py-2 flex items-center justify-center">
                        <span className="text-[#390094] text-sm font-semibold">Ver carpeta de documentos</span>
                    </div>
                </a> 
            </div>
            <div className="flex-1 overflow-hidden">
                <StepperScoring formulario={formulario} setFormulario={setFormulario} editable={editable} />
            </div>
            <div className="mt-6 flex justify-end">
                <footer className="flex justify-end items-end">
                    <div className="w-[233px] h-[40px] bg-[#CBD5E0] rounded-[28px]">
                        <div className="text-md text-[#718096] font-semibold text-center py-2">Calcular</div>
                    </div>
                </footer>
            </div> 
        </div>
    );
}
