import type React from "react"
import { useEffect, useRef, useState, useCallback, useMemo } from "react"
import Flatpickr from "react-flatpickr"
import flatpickr from "flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import "flatpickr/dist/flatpickr.css"
import "./calendar.css"

interface DateRangePickerProps {
  onDateRangeChange: (dateRange: string | null) => void
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ onDateRangeChange }) => {
  const today = useMemo(() => new Date(), []);
  const [dateRange, setDateRange] = useState<Date[] | null>(null)
  const [isRangeComplete, setIsRangeComplete] = useState<boolean>(false)
  const flatpickrRef = useRef<any>(null)
  const okButtonRef = useRef<HTMLButtonElement | null>(null)

  const resetCalendar = useCallback(() => {
    setDateRange(null)
    setIsRangeComplete(false)
    onDateRangeChange(null)
    if (flatpickrRef.current?.flatpickr) {
      flatpickrRef.current.flatpickr.clear()
      flatpickrRef.current.flatpickr.setDate([today, today], true)
      flatpickrRef.current.flatpickr.close()
    }
  }, [onDateRangeChange, today])

  const handleCancel = useCallback(() => {
    resetCalendar()
    if (okButtonRef.current) {
      okButtonRef.current.disabled = true
      okButtonRef.current.classList.remove("flatpickr-ok-button")
      okButtonRef.current.classList.add("flatpickr-ok-button-disabled")
      setDateRange(null)
    }
  }, [resetCalendar])

  const handleOk = useCallback(() => {
    if (dateRange && dateRange.length === 2) {
      const formattedRange = `${flatpickr.formatDate(dateRange[0], "Y-m-d")}/${flatpickr.formatDate(dateRange[1], "Y-m-d")}`
      onDateRangeChange(formattedRange)
      if (flatpickrRef.current?.flatpickr) {
        flatpickrRef.current.flatpickr.close()
      }
    }
  }, [dateRange, onDateRangeChange])

  useEffect(() => {
    setIsRangeComplete(dateRange && dateRange.length === 2)
    if (okButtonRef.current) {
      okButtonRef.current.disabled = !isRangeComplete
      okButtonRef.current.classList.toggle("flatpickr-ok-button", isRangeComplete)
      okButtonRef.current.classList.toggle("flatpickr-ok-button-disabled", !isRangeComplete)
    }
  }, [dateRange, isRangeComplete])

  useEffect(() => {
    if (flatpickrRef.current?.flatpickr) {
      const calendar = flatpickrRef.current.flatpickr
      const existingButtons = calendar.calendarContainer.querySelector(".flatpickr-buttons")
      if (existingButtons) existingButtons.remove()
      const buttonsContainer = document.createElement("div")
      buttonsContainer.className = "flatpickr-buttons"
      const cancelButton = document.createElement("button")
      cancelButton.className = "flatpickr-cancel-button"
      cancelButton.textContent = dateRange && dateRange.length === 2 ? "Limpiar filtro" : "Cancelar"
      cancelButton.onclick = handleCancel 
      const okButton = document.createElement("button")
      okButton.className = isRangeComplete ? "flatpickr-ok-button" : "flatpickr-ok-button-disabled"
      okButton.textContent = "Ok"
      okButton.disabled = !isRangeComplete
      okButton.onclick = handleOk
      okButtonRef.current = okButton
      buttonsContainer.appendChild(cancelButton)
      buttonsContainer.appendChild(okButton)
      calendar.calendarContainer.appendChild(buttonsContainer)
    }
  }, [handleCancel, handleOk, isRangeComplete, dateRange])

  return (
    <div className="inline-block max-w-md relative">
      <label className="block text-gray-700 text-sm font-bold mb-2">
        <div className="bg-[#EDF2F7] rounded-[10px] w-[260px] h-9 flex justify-start items-center gap-1 px-2">
          <svg className="text-[#718096] w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Flatpickr
            ref={flatpickrRef}
            options={{
              mode: "range",
              dateFormat: "M-d, Y",
              defaultDate: [today, today],
              locale: {
                ...Spanish,
                rangeSeparator: " - ",
                firstDayOfWeek: 0,
                weekdays: {
                  shorthand: ["D", "L", "M", "M", "J", "V", "S"],
                  longhand: ["D", "L", "M", "M", "J", "V", "S"],
                },
              },
              showMonths: 2,
              minDate: null,
              maxDate: "today",
              closeOnSelect: false,
            }}
            value={dateRange}
            onChange={(selectedDates) => {
              if (selectedDates.length === 2) {
                setDateRange(selectedDates)
              }
            }}            
            className="text-sm text-[#718096] font-medium bg-[#EDF2F7] w-full outline-none placeholder-[#718096]"
            placeholder="Escoja rango de fechas"
          />
        </div>
      </label>
      {dateRange &&
        <svg
          className="text-[#718096] w-[24px] h-[24px] cursor-pointer absolute top-[5px] right-[5px]"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={resetCalendar}>
          <path
            d="M18 6L6 18M6 6L18 18"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
    </div>
  )
}

export default DateRangePicker

