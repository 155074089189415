export interface FilterStageProps {
    label: string
    property: string
    value: string
    color: string
}

export const filterStages: FilterStageProps[] = [
    {
        label: "Estudio de crédito",
        property: "dealstage",
        value: "24016549",
        color: "#E9D8FD"
    },
    {
        label: "Listo para estudio",
        property: "dealstage",
        value: "79591717",
        color: "#BEE3F8"
    },
    {
        label: "Standby",
        property: "ingresar_a_standby",
        value: "true",
        color: "#FED7D7"
    }
];
