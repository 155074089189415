import httpClientService from "http/httpClientService"
import { SolicitudesHubspotDTO } from "../dto/solicitudes-hubspot-dto"
import { ConfigVariables } from "adapters/ports/config-variables"

export interface RequestParams {
  analista_de_credito_asignado: string
  after?: string | null
  query?: string
  date_range?: string
  sort?: string
  numero_de_identificacion_del_contacto?: string
  dealstage?: any[] | string
  ingresar_a_standby?: string
}

interface ApiResponse {
  results: any[]
  total: number
  paging?: {
    next?: {
      after?: string
    }
  }
}

class SolicitudesHubspotService {
  private UrlHubspotApi = `${ConfigVariables.URL_BASE_API}hubspot-handler/deals`
  

  public async obtener(
    params: RequestParams,
  ): Promise<{ solicitudes: SolicitudesHubspotDTO[]; total: number; nextAfter: string | null }> {
    const { analista_de_credito_asignado, after, query, date_range, numero_de_identificacion_del_contacto, dealstage, ingresar_a_standby, sort } = params

    const requestBody: RequestParams = {
      analista_de_credito_asignado,
      ...(after !== null && after !== undefined && { after }),
      ...(query && { query }),
      ...(dealstage && { dealstage }),
      ...(date_range && { date_range }),
      ...(numero_de_identificacion_del_contacto && { numero_de_identificacion_del_contacto }),
      ...(ingresar_a_standby && { ingresar_a_standby }),
      ...(sort && { sort })
    }

    const response: ApiResponse = await httpClientService.post(this.UrlHubspotApi, requestBody)

    return {
      solicitudes: response.results.map((result) => new SolicitudesHubspotDTO(result)),
      total: response.total,
      nextAfter: response.paging?.next?.after ?? null,
    }
  }
}

const solicitudHubspotNuevo = new SolicitudesHubspotService()
export default solicitudHubspotNuevo
