import { useMemo, useEffect, useState } from "react";
import LayoutDocumentos from "./layouts/layout-documentos";
import CardUploadFile from "app/components-finmaq/cardUploadFile";
import ResponsiveTooltip from "app/components-finmaq/responsiveTooltip";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import useDocumentState from "app/carga-documentos/utilities/useDocumentState";
import ModalGuardadoParcial from "app/components-finmaq/modal-guardado-parcial";
import { useCognitoUserAttributes } from 'app/login/adapters/useCognitoUserAttributes';
import { useGetContactWithProducts } from "app/carga-documentos/utilities/useGetContactWithProducts";
import { handleTagManagerSteps } from "app/utilities/gtm";
import classNameByEvent from "app/carga-documentos/utilities/classNameByEvent";
import { useHandleBeforeUnload } from "app/carga-documentos/utilities/useHandleBeforeUnload";
import { useHandleProductDone } from "app/carga-documentos/utilities/useHandleProductDone";

export default function ExtractosBancarios() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [buttonStatus, setButtonStatus] = useState(uploadStatus.DISABLE);

    const { fileStatus:fileStatusOne, 
        documentUrl:documentOneUrl, 
        setDocumentUrl:setDocumentOneUrl, 
        handleInput: handleInputOne, 
        handleSubmit: handleSubmitOne } = useDocumentState();

    const { fileStatus:fileStatusTwo, 
        documentUrl:documentTwoUrl, 
        setDocumentUrl:setDocumentTwoUrl, 
        handleInput: handleInputTwo, 
        handleSubmit: handleSubmitTwo } = useDocumentState();

    const { fileStatus:fileStatusThree, 
        documentUrl:documentThreeUrl, 
        setDocumentUrl:setDocumentThreeUrl, 
        handleInput: handleInputThree, 
        handleSubmit: handleSubmitThree } = useDocumentState();

    const { cognitoUUID } = useCognitoUserAttributes();
    const { currentProduct } = useGetContactWithProducts();
    const productUUID = currentProduct["product_uuid" as keyof typeof currentProduct];

    const isCardOneFileHolder = fileStatusOne === uploadStatus.READY || fileStatusOne === uploadStatus.UPLOADING || fileStatusOne === uploadStatus.DONE || documentOneUrl;
    const isCardTwoFileHolder = fileStatusTwo === uploadStatus.READY || fileStatusTwo === uploadStatus.UPLOADING || fileStatusTwo === uploadStatus.DONE || documentTwoUrl;
    const isCardThreeFileHolder = fileStatusThree === uploadStatus.READY || fileStatusThree === uploadStatus.UPLOADING || fileStatusThree === uploadStatus.DONE || documentThreeUrl;

    const isCardTwoActive = isCardOneFileHolder || isCardTwoFileHolder;
    const isCardThreeActive = (isCardOneFileHolder && isCardTwoFileHolder) || isCardThreeFileHolder;

    const currentDocuments = currentProduct["documents" as keyof typeof currentProduct];

    const isAnyFieldFilled = useMemo((): boolean => {
        return fileStatusOne === uploadStatus.READY || 
            fileStatusTwo === uploadStatus.READY || 
            fileStatusThree === uploadStatus.READY;
    }, [fileStatusOne, fileStatusTwo, fileStatusThree]);

    const product_state = (currentProduct as unknown as { financial_product: {
        product_state: string
    } }).financial_product?.product_state;

    const { handleOpenModal } = useHandleBeforeUnload(isAnyFieldFilled, ()=> setIsModalOpen(true));

    useHandleProductDone(product_state);

    const handleButtonClick = () => {
        const tagManagerOnStepCompleted = ()=>{
            handleTagManagerSteps({
                action: "complete",
                cognitoUserId: cognitoUUID,
                productId: productUUID
            })
        }

        if (fileStatusOne === uploadStatus.READY || fileStatusOne === uploadStatus.ERROR) {
            handleSubmitOne("EXTRACTO_1");
            tagManagerOnStepCompleted()
        }
        if (fileStatusTwo === uploadStatus.READY || fileStatusTwo === uploadStatus.ERROR) {
            handleSubmitTwo("EXTRACTO_2");
            tagManagerOnStepCompleted()
        }
        if (fileStatusThree === uploadStatus.READY || fileStatusThree === uploadStatus.ERROR){
            handleSubmitThree("EXTRACTO_3");
            tagManagerOnStepCompleted()
        };
    };

    const cardWidth = () => {
        if(isCardOneFileHolder && isCardTwoFileHolder){
            return "lg:w-[30%] mx-[1%]";
        }else if(isCardOneFileHolder || isCardTwoFileHolder){
            return "lg:w-[45%] mx-[1%]";
        }
        return "lg:w-[calc(93vw-160px)]";
    };

    useEffect(()=>{
        if(currentDocuments?.extract_1 && currentDocuments?.extract_1.file_path) setDocumentOneUrl(currentDocuments?.extract_1.file_path); 
        if(currentDocuments?.extract_2 && currentDocuments?.extract_2.file_path) setDocumentTwoUrl(currentDocuments?.extract_2.file_path); 
        if(currentDocuments?.extract_3 && currentDocuments?.extract_3.file_path) setDocumentThreeUrl(currentDocuments?.extract_3.file_path);
    },[currentDocuments, setDocumentOneUrl, setDocumentTwoUrl, setDocumentThreeUrl])

    useEffect(()=>{
        if(cognitoUUID && productUUID){
            handleTagManagerSteps({
                action: "enter",
                cognitoUserId: cognitoUUID,
                productId: productUUID
            })
        }
    },[cognitoUUID, productUUID])

    useEffect(()=>{
        if(fileStatusOne === uploadStatus.READY || 
            fileStatusTwo === uploadStatus.READY || 
            fileStatusThree === uploadStatus.READY){
            setButtonStatus(uploadStatus.READY)
        }
    },[fileStatusOne, fileStatusTwo, fileStatusThree])

    return (
        <LayoutDocumentos 
            handleButtonClick={handleButtonClick} 
            buttonLabel="Guardar"
            buttonClassName={classNameByEvent(buttonStatus)}
            hasFooter={true}>
            <div className="hidden lg:flex text-[#718096] ml-[105px] text-md font-normal"><span>Solicitud de crédito </span><span className="ml-1 text-[#000000]"> / Extractos bancarios</span></div>
            <div className="lg:mt-7 lg:ml-14 flex items-center">
                {isModalOpen && (
                    <ModalGuardadoParcial onClose={()=> setIsModalOpen(false)} onHandleProp={handleButtonClick} /> 
                )}
                <button onClick={handleOpenModal}>
                    <svg className="text-[#390094]" width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <p className="text-start text-[#390094] font-bold text-xl ml-2 lg:font-semibold lg:text-2xl lg:ml-[15px]">Extractos bancarios</p>
            </div>
            <div className="text-[#171923] text-md font-normal lg:ml-[105px] lg:mr-[105px] lg:mt-2 mt-7 leading-6 lg:leading-normal w-full lg:w-auto">
                <p className="mb-4 lg:mb-0">Suba los <strong>últimos tres extractos bancarios</strong> de su cuenta principal. Esto nos ayudará a evaluar su historial financiero.</p>
                <p>Verifique que toda la información relevante, como el nombre del titular de la cuenta y el saldo actual, sea claramente visible.</p>
            </div>
            <div className="relative lg:pl-[105px] lg:pr-[105px] mt-10 mt-[60px] lg:mt-[75px]">
                <strong>Últimos 3 extractos bancarios</strong>
                <ResponsiveTooltip text="Puede subir un archivo con los últimos tres extractos bancarios o cada uno por separado" color="black"/>
            </div>
            <div className="flex lg:w-[97vw] flex-col-reverse justify-center lg:flex-row lg:pl-[67px] lg:pr-[67px]">
                <CardUploadFile
                    key={`documentOne_${documentOneUrl}`}
                    fileLimit={4928307}
                    fileStatus={fileStatusOne}
                    fileName={documentOneUrl}
                    className={cardWidth()}
                    buttonLabel="Elija los archivos"
                    handleSubmit={handleButtonClick}
                    handleInput={handleInputOne}/>
                {(isCardTwoActive || documentTwoUrl) && (
                    <CardUploadFile
                        key={`documentTwo_${documentTwoUrl}`}
                        fileLimit={4928307}
                        fileStatus={fileStatusTwo}
                        fileName={documentTwoUrl}
                        className={cardWidth()}
                        buttonLabel="Elija los archivos"
                        handleSubmit={handleButtonClick}
                        handleInput={handleInputTwo}/>
                )}
                {(isCardThreeActive || documentThreeUrl) && (
                    <CardUploadFile
                        key={`documentThree_${documentThreeUrl}`}
                        fileLimit={4928307}
                        fileStatus={fileStatusThree}
                        fileName={documentThreeUrl}
                        className={cardWidth()}
                        buttonLabel="Elija los archivos"
                        handleSubmit={handleButtonClick}
                        handleInput={handleInputThree}/>
                )}
            </div>
        </LayoutDocumentos>
    );
}
