import CheckboxField from "../utilities/radioButom";
import CompilarTextField from "../utilities/textField";
import FieldProps, { FormProps, InputTypes } from "../utilities/textFieldProps";

export default function FormularioHistorialyRiesgo({formulario, setFormulario, editable}: {formulario : any, setFormulario : any, editable: boolean}) {

    return (
        <>
            <div className="flex-1 grid grid-cols-2 gap-4">
                <div>
                    <div className="flex"><span className="text-md text-[#4A5568] font-medium">Puntaje en centrales de riesgo</span>
                        <div className="relative group inline-block ml-1 mt-1">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[130px] top-4 bottom-full hidden py-0.5 text-sm w-[246px] h-[44px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Valor que arroja TransUnion desde <br></br>el proceso de Pre-aporbados
                            </div>
                        </div> 
                    </div>
                    <PuntajeCentrales model={formulario} setModel={setFormulario} editable={editable}/>
                </div>
                <div>
                    <div className="flex"><span className="text-md text-[#4A5568] font-medium">Puntaje Truora</span>
                        <div className="relative group inline-block ml-1 mt-1">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[130px] top-4 bottom-full hidden py-0.5 text-sm w-[246px] h-[44px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Puntaje arrojado por Truora desde <br></br>el proceso de Pre-aporbados
                            </div>
                        </div> 
                    </div>
                    <PuntajeTruora model={formulario} setModel={setFormulario} editable={editable}/>
                </div>
            </div>
            <div className="-mt-[20px]">
                <div className="flex"><span className="text-md text-[#4A5568] font-medium">Moras</span>
                    <div className="relative group inline-block ml-1 mt-1">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cursor-pointer"
                        >
                            <path
                                d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="absolute text-center z-50 left-[215px] top-4 bottom-full hidden py-0.5 text-sm w-[414px] h-[64px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                            Retrasos que tenga el cliente en sus obligaciones actuales.<br></br>Consulta el PDF de Información comercial de TransUnion<br></br>en la sección de Resumen Endeudamiento
                        </div>
                    </div> 
                </div>
                <div className="flex grid grid-cols-2 gap-4">
                    <Moras model={formulario} setModel={setFormulario}  editable={editable}/>
                    <AntiguedadMoras model={formulario} setModel={setFormulario}  editable={editable}/>
                    <div className="-mt-[68px]">
                        <NumeroObligaciones model={formulario} setModel={setFormulario}  editable={editable}/>
                    </div>
                </div>
            </div>
            <div className="-mt-[20px]">
                <div className="flex"><span className="text-md text-[#4A5568] font-medium ">Cuentas embargadas</span>
                    <div className="relative group inline-block ml-1 mt-1">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cursor-pointer"
                        >
                            <path
                                d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="absolute text-center z-50 left-[215px] top-4 bottom-full hidden py-0.5 text-sm w-[415px] h-[48px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                            Consulta el PDF de Información comercial de TransUnion<br></br>en la sección de Informe detallado
                        </div>
                    </div> 
                </div>
                <CuentasEmbargadas model={formulario} setModel={setFormulario} editable={editable}/> 
            </div>
        </>
    );
}

const PuntajeCentrales = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='score_credit_centers'
            placeholder='Puntaje en centrales'
            campoIdRespuesta='centers_score'
            type={InputTypes.Number}
            min={0}
            max={1000}
            editable={editable}
        />
    )
}

const PuntajeTruora = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='truora_score'
            placeholder='Puntaje Truora'
            campoIdRespuesta='score_truora'
            type={InputTypes.Number}
            min={0}
            max={10}
            editable={editable}
        />
    )
}

const Moras = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {

    return(
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='arrears_amount'           
            campoIdRespuesta={'score_arrears'}
            placeholder={'Valor de la mora'}
            className={'w-[262px]'}
            type={InputTypes.Money}
            min={0}
            editable={editable}
        />

        
    )
}

const AntiguedadMoras = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    const fieldProps : FieldProps = {
        model:formulario,
        required:true,
        setModel:setFormulario,
        campoId:'arrears_oldness',
        placeholder:'Meses de mora',
        type:InputTypes.Number,
        className:'w-[262px]',
        min:0,
        max:6,
        editable
    }
    return CompilarTextField(fieldProps);
}

const NumeroObligaciones = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    const fieldProps : FieldProps = {
        model:formulario,
        required:true,
        setModel:setFormulario,
        campoId:'liabilities',
        placeholder:'N° de obligaciones',
        className:'w-[262px]',
        type:InputTypes.Number,
        min:0,
        max:20,
        editable:editable
    }
    return CompilarTextField(fieldProps);
}

const CuentasEmbargadas = ({ model, setModel, editable }: FormProps) => {
    return (
        <div>
            <CheckboxField
                type={InputTypes.Boolean}
                required={true}
                model={model}
                setModel={setModel}
                campoId='seized_accounts'
                campoIdRespuesta='accounts_seized_denied'
                opcionTrue='Sí'
                opcionFalse='No'
                editable={editable}
            />
        </div>
    );
};