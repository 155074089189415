import { useState, useEffect, useMemo } from "react";
import LayoutDocumentos from "app/carga-documentos/layouts/layout-documentos"
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { useGetContactWithProducts } from "app/carga-documentos/utilities/useGetContactWithProducts";
import Modal from "app/components-finmaq/modal"; 
import { ConfigVariables } from "adapters/ports/config-variables";
import { useNavigate } from "react-router-dom";
import FormFields, {FieldsProps} from "app/formulario-solicitud/utilities/formFields"; 
import { formatToClientTimezone } from "app/mis-creditos/utilities/formatToClientTimezone";
import { useCognitoUserAttributes } from 'app/login/adapters/useCognitoUserAttributes';
import { dotsFormat } from "app/utilities/dataFormatter";

const responsesByStatus = (status:string)=>{
    switch(status){
        case "POR_EMPEZAR":
            return {
                label: "Por empezar",
                color: "#DD6B20"
            }
        case "CREADO":
        case "EN_RADICACION":
        case "PREAPROBADO":
            return {
                label: "Por terminar",
                color: "#D69E2E"
            }
        case "RADICADO":
            return {
                label: "Radicado",
                color: "#01B574"
            }
        default:
            return {
                label: status,
                color: "#171923"
            }
    }
}

export default function MisCreditos() {
    const [ isModalOpenIntro, setIsModalOpenIntro ] = useState(true);
    const [ isMoreThanOne, setIsMoreThanOne ] = useState(false);
    const [ isFirstStepCompleted, setIsFirstStepCompleted ] = useState(false);
    const [ showModal, setShowModal ] = useState(false)
    const { person_type } = useCognitoUserAttributes();
    const { contact, sortedProducts } = useGetContactWithProducts();
    const navigate = useNavigate();

    const formFieldsByType =  useMemo(()=>FormFields[person_type as keyof typeof FormFields] || [],[person_type]);
    const firstStep = useMemo(()=>formFieldsByType[0]?.inputs || [], [formFieldsByType])

    const renderedProducts = Object.entries(sortedProducts).map((e)=>{
        const product = e[1];
        const productUUID = product["product_uuid" as keyof typeof product];
        const currentDocuments = product["documents" as keyof typeof product];
        const financial_amount = (product as unknown as { financial_product: {
            financial_amount: number
        } }).financial_product?.financial_amount;

        const product_state = (product as unknown as { financial_product: {
            product_state: number
        } }).financial_product?.product_state;

        const created_at = (product as unknown as { created_at: string }).created_at;
        const { year, month, day, hours, minutes, seconds } = formatToClientTimezone(created_at) || {};

        const statusColor = (Object.keys(currentDocuments).length > 1 || isFirstStepCompleted) ? responsesByStatus(product_state.toString()).color : responsesByStatus("POR_EMPEZAR").color;

        const statusLabel = (Object.keys(currentDocuments).length > 1 || isFirstStepCompleted) ? responsesByStatus(product_state.toString()).label : responsesByStatus("POR_EMPEZAR").label;

        return <Link key={productUUID} className="border-t border-[#CBD5E0] py-5 lg:py-2 relative block lg:flex" to={`/carga-documentos/${productUUID}`}>
            <div className="flex py-2 w-[calc(100%-20px)] px-1 lg:px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Distribuidor</span><span className="text-[#171923] flex-1 pl-1">INSSA</span></div>
            <div className="flex py-2 w-[calc(100%-20px)] px-1 lg:px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Valor del crédito</span><span className="text-[#171923] flex-1 pl-1">${dotsFormat(financial_amount)}</span></div>
            <div className="flex py-2 w-[calc(100%-20px)] px-1 lg:px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Fecha</span><span className="text-[#171923] flex-1 pl-1">{year}/{month}/{day} {hours}:{minutes}:{seconds.substring(0, 2)}</span></div>
            <div className="flex py-2 w-[calc(100%-20px)] px-1 lg:px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Estado</span><span className="text-[#171923] flex-1 pl-1" style={{color: statusColor}}>{statusLabel}</span></div>
            <IoIosArrowForward className="absolute top-7 lg:top-4 right-0 w-[22px] h-[22px]"/>
        </Link>
    })

    useEffect(()=>{
        const isModalOpened = localStorage.getItem('isModalOpened')
        if (!isModalOpened) {
            setShowModal(true)
            localStorage.setItem('isModalOpened', 'true')
        }

        if(Object.keys(sortedProducts).length === 1) navigate(`/carga-documentos/${Object.keys(sortedProducts)[0]}`);
        if(Object.keys(sortedProducts).length > 1) setIsMoreThanOne(true);

        if(contact){
            const valuesFirstStep = firstStep.filter(({name, previousLock}:FieldsProps)=>{
                if(previousLock || !name) return false;
                return true
            }).map(({section, group, name}:FieldsProps)=> ({section, group, name}));

            const firstStepValuesCompleted = valuesFirstStep.filter((field)=>{
                const currentSection = contact[field.section as keyof typeof contact] || {};
                const currentGroup = currentSection[field.group as keyof typeof currentSection] || null;
                const currentValue = currentGroup ? currentGroup[field.name as keyof typeof currentGroup] : currentSection[field.name as keyof typeof currentSection];
                return currentValue !== undefined
            }).length;

            const firstStepCompleted = firstStepValuesCompleted === valuesFirstStep.length;
            setIsFirstStepCompleted(firstStepCompleted)
        }
    },[sortedProducts, navigate, isMoreThanOne, contact, firstStep])

    return (
        <LayoutDocumentos
            hasFooter={false}>
             <>
                {(isMoreThanOne && showModal) &&
                    <Modal
                        boldmessage="Si tiene créditos con Finmaq de alto monto, no podrá visualizarlos en esta plataforma."
                        message="Para obtener información sobre ellos, por favor contacte a su asesor de confianza."
                        imageUrl={ConfigVariables.ALERTA_CREDITOS_MAYORES}
                        isOpen={isModalOpenIntro}
                        primaryButtonLabel="Entendido"
                        handleCloseModal={() => setIsModalOpenIntro(false)}
                        handlePrimaryButton={() => setIsModalOpenIntro(false)}/>
                }
                <div className={`block justify-between ml-2 mr-2 mt-[40px] lg:mt-[80px] xl:mr-[210px] xl:ml-[210px] lg:mt-[116px] ${!isMoreThanOne ? `skeleton-animation` : ``}`}>
                    <div>
                        <p className="text-[#390094] text-3xl lg:text-4xl font-semibold lg:font-bold leading-10 skeleton-opacity">Mis créditos</p>
                        <p className="text-[#718096] text-base lg:text-base skeleton-pulse">INSSA - Créditos de bajo monto</p>
                    </div>
                    <div className="my-16 border-b border-[#CBD5E0]">
                        <div className="hidden lg:flex mb-7">
                            <div className="flex-1 text-left text-[#718096] font-medium px-4">Distribuidor</div>
                            <div className="flex-1 text-left text-[#718096] font-medium px-4">Valor del crédito</div>
                            <div className="flex-1 text-left text-[#718096] font-medium px-4">Fecha</div>
                            <div className="flex-1 text-left text-[#718096] font-medium px-4">Estado</div>
                        </div>
                        {renderedProducts}
                        {renderedProducts.length === 0 && 
                            <div className="border-t border-[#CBD5E0] py-5 lg:py-2 relative block lg:flex">
                                <div className="flex py-2 w-[calc(100%-20px)] px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Distribuidor</span><span className="text-[#171923] flex-1 skeleton-pulse">-----------</span></div>
                                <div className="flex py-2 w-[calc(100%-20px)] px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Valor del crédito</span><span className="text-[#171923] flex-1 skeleton-pulse">-----------</span></div>
                                <div className="flex py-2 w-[calc(100%-20px)] px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Fecha</span><span className="text-[#171923] flex-1 skeleton-pulse">-----------</span></div>
                                <div className="flex py-2 w-[calc(100%-20px)] px-4 flex-1"><span className="text-[#718096] flex-1 font-medium lg:hidden">Estado</span><span className="text-[#171923] flex-1 skeleton-pulse">-----------</span></div>
                            </div>
                        }
                    </div>
                </div>
            </>
        </LayoutDocumentos>
    )
}