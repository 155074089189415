import { useState } from 'react'
import CompilarTextField from './textField';
import FieldProps, { FormProps, InputTypes } from './textFieldProps';
import CheckboxField from './radioButom';
import SelectField from './selectField';

export default function FormularioCredito({formulario, setFormulario, editable}: {formulario : any, setFormulario : any, editable: boolean}) {
    const [tipoPersona, setTipoPersona] = useState(false);

    return (
        <>  
            <div className='mr-[210px] ml-[210px]'>
            <TipoPersona model={formulario} setModel={setFormulario} setTipoPersona={setTipoPersona} tipoPersona={tipoPersona}  editable={editable}/>
                <div>
                    <div className="flex grid grid-cols-3 gap-[17px] ">
                        <Moras model={formulario} setModel={setFormulario}  editable={editable}/>
                        <div className='mt-5'>
                            <AntiguedadMoras model={formulario} setModel={setFormulario}  editable={editable}/>
                        </div>

                        <div className='mt-5'>
                            <NumeroObligaciones model={formulario} setModel={setFormulario}  editable={editable}/>
                        </div>
                    </div>
                </div>

                <PuntajeCentrales model={formulario} setModel={setFormulario} editable={editable}/>
                <MesesIncorporacion model={formulario} setModel={setFormulario} tipoPersona={tipoPersona} editable={editable}/>
                <ExperienciaMontos model={formulario} setModel={setFormulario} editable={editable}/>
                
                <div>
                    <div className='flex grid grid-cols-2 gap-[17px]'>
                        <IngresosReportados model={formulario} setModel={setFormulario} editable={editable}/>
                        <div className='mt-5'>
                            <IngresosPorExtractos model={formulario} setModel={setFormulario} editable={editable}/>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='flex grid grid-cols-2 gap-[17px]'>
                        <Valor model={formulario} setModel={setFormulario} editable={editable}/>
                        <div className='mt-5'>
                            <ValorSolicitado model={formulario} setModel={setFormulario}  editable={editable}/>
                        </div>
                    </div>
                </div>
                
                <IngresosAdicionales model={formulario} setModel={setFormulario} editable={editable}/>
                <NecesidadAdquisicion model={formulario} setModel={setFormulario} editable={editable}/>
                <Coberturas model={formulario} setModel={setFormulario} editable={editable}/>
                <VentasAnualesDistribuidor model={formulario} setModel={setFormulario} editable={editable}/>
                <ComportamientoCarteraDistribuidor model={formulario} setModel={setFormulario} editable={editable}/>
                <PuntajeTruora model={formulario} setModel={setFormulario} editable={editable}/>
                <CuentasEmbargadas model={formulario} setModel={setFormulario} editable={editable}/>
                <CiudadCliente model={formulario} setModel={setFormulario} editable={editable}/>
            </div>            
            
            
        </>
    );
}


const Moras = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {

    return(
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='arrears_amount'
            title={
                <>
                    <div className="flex">
                        <div>Mora</div>
                        <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-56 bottom-full hidden py-0.5 text-sm w-[434px] h-[64px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Retrasos que tenga el cliente en sus obligaciones actuales.
                                <br />
                                Consulta el PDF de Información comercial de TransUnion
                                <br />
                                en la sección de Resumen Endeudamiento.
                            </div>
                        </div>
                    </div>
                </>
            }            
            campoIdRespuesta={'score_arrears'}
            placeholder={'Valor de la mora'}
            className={'w-[262px]'}
            type={InputTypes.Money}
            min={0}
            editable={editable}
        />

        
    )
}

const AntiguedadMoras = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    const fieldProps : FieldProps = {
        model:formulario,
        required:true,
        setModel:setFormulario,
        campoId:'arrears_oldness',
        placeholder:'Meses de mora',
        title: '',
        type:InputTypes.Number,
        className:'w-[262px]',
        min:0,
        max:6,
        editable
    }
    return CompilarTextField(fieldProps);
}

const NumeroObligaciones = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    const fieldProps : FieldProps = {
        model:formulario,
        required:true,
        setModel:setFormulario,
        campoId:'liabilities',
        title: '',
        placeholder:'N° de obligaciones',
        className:'w-[262px]',
        type:InputTypes.Number,
        min:0,
        max:20,
        editable:editable
    }
    return CompilarTextField(fieldProps);
}

const PuntajeCentrales = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='score_credit_centers'
            title={
                <>
                    <div className="flex">
                        <div>
                        Puntaje en centrales de riesgo
                        </div>
                        <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-44 bottom-full hidden py-0.5 text-sm w-[337px] h-[24px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                            Consulta el PDF de CreditVision de TransUnion
                            </div>
                        </div>
                    </div>
                </>
            }
            placeholder='Puntaje en centrales'
            campoIdRespuesta='centers_score'
            type={InputTypes.Number}
            min={0}
            max={1000}
            editable={editable}
        />
    )
}

const MesesIncorporacion = ({ model, setModel, tipoPersona, editable }: FormProps & { tipoPersona: boolean }) => {
    const placeholderText = tipoPersona ? 'Tiempo inicio de actividad' : 'Fecha de constitución';

    return (
        <CompilarTextField
            model={model}
            setModel={setModel}
            campoId='date_of_incorporation'
            campoIdRespuesta='months_of_incorporation'
            title={
                <>
                    <div className="flex">
                        <div>
                        {placeholderText}
                        </div>
                        <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-48 bottom-full hidden py-0.5 text-sm w-[372px] h-[44px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Tiempo que el cliente lleva ejerciendo la actividad. Consulta el RUT/Cámara y comercio del cliente
                            </div>
                        </div>
                    </div>
                </>
            }
            type={InputTypes.Date}
            editable={editable}
        />
    )
};

const ExperienciaMontos = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
        model={formulario}
        required={true}
        setModel={setFormulario}
        campoId='transunion_amounts'
        campoIdRespuesta='amounts_score'
        title={
            <>
                <div className="flex">
                    <div>
                    Experiencia en montos similares
                    </div>
                    <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-48 bottom-full hidden py-0.5 text-sm w-[372px] h-[104px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                            Valor aproximado de las obligaciones ya<br></br> saldadas o en caso de no tenerlas, es<br></br> posible tomarlo de las obligaciones vigentes.<br></br> Consulta el PDF de Información comercial<br></br> de TransUnion en la sección de Informe detallado
                        </div>
                    </div>
                </div>
            </>
        }
        placeholder='Valor en experiencias similares'
        type={InputTypes.Money}
        min={0}
        editable={editable}
        />
    )
}

const IngresosReportados = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='preapproved_incomes'
            campoIdRespuesta='incomes_score'
            title='Ingresos por extractos'
            placeholder={
                <>
                    <div className="flex">
                        <div>
                        Ingresos totales
                        </div>
                        <div className="relative group inline-block ml-1">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[170px] bottom-full hidden py-0.5 text-sm w-[323px] h-[44px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Valor diligenciado en el campo de Ingresos <br></br>Mensuales Aproximados de Pre-Aprobados
                            </div>
                        </div>
                    </div>
                </>
            }
            type={InputTypes.Money}
            editable={editable}
        />
    )
}
const IngresosPorExtractos = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='incomes_extracts'
            placeholder={
                <>
                    <div className="flex">
                        <div>
                            Promedio mensual (últimos 3 meses)
                        </div>
                        <div className="relative group inline-block ml-1">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[170px] bottom-full hidden py-0.5 text-sm w-[323px] h-[44px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Promedio del valor expuesto en los extractos <br></br>bancarios de los últimos tres meses
                            </div>
                        </div>
                    </div>
                </>
            }
            type={InputTypes.Money}
            editable={editable}
        />
    )
}
const Coberturas = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
        model={formulario}
        required={true}
        setModel={setFormulario}
        campoId='coverage'
        title={
            <>
                <div className="flex">
                    <div>
                        Cobertura
                    </div>
                    <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[173px] bottom-full hidden py-0.5 text-sm w-[330px] h-[84px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                            Cálculo aproximado de dinero libre después <br></br>de cubrir costos y gastos. Gestiona el Excel <br></br>del Scoring y consulta el valor calculado <br></br>en la hoja de Coberturas
                        </div>
                    </div>
                </div>
            </>
        }
        placeholder='Riesgo de cobertura'
        campoIdRespuesta='coverage_score'
        type={InputTypes.Number}
        min={0}
        max={10}
        editable={editable}
        />
    )
}
const PuntajeTruora = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='truora_score'
            placeholder='Puntaje Truora'
            title={
                <>
                    <div className="flex">
                        <div>
                            Puntaje Truora
                        </div>
                        <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[183px] bottom-full hidden py-0.5 text-sm w-[349px] h-[24px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Consulta Truora y diligencia el puntaje arrojado
                            </div>
                        </div>
                    </div>
                </>
            }
            campoIdRespuesta='score_truora'
            type={InputTypes.Number}
            min={0}
            max={10}
            editable={editable}
        />
    )
}
const Valor = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='value'
            campoIdRespuesta='finance_percentage_score'
            title=
            {
                <>
                    <div className="flex">
                        <div>
                            Exposición Final
                        </div>
                        <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[208px] bottom-full hidden px-1 text-sm w-[400px] h-[125px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                El valor del activo corresponde al monto ingresado en el campo "Valor Máquina" dentro de Pre-Aprobados o al consultar la cotización.<br></br><br></br>
                                El valor solicitado es el valor seleccionado en el campo "Porcentaje a Financiar" dentro de Pre-Aprobados.
                            </div>
                        </div>
                    </div>
                </>
            }
            placeholder='Valor del activo'
            type={InputTypes.Money}
            editable={editable}
        />
    )
}

const VentasAnualesDistribuidor = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='annual_sales_distributor'
            placeholder='Valor de ventas del Distribuidor'
            title={
                <>
                    <div className="flex">
                        <div>
                            Tamaño del distribuidor
                        </div>
                        <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[123px] bottom-full hidden py-0.5 text-sm w-[231px] h-[44px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Consulta la hoja Distribuidores <br></br>del Excel del Scoring
                            </div>
                        </div>
                    </div>
                </>
            }
            campoIdRespuesta='distributor_sells_score'
            type={InputTypes.Money}
            editable={editable}
        />
    )
}

const CuentasEmbargadas = ({ model, setModel, editable }: FormProps) => {
    return (
        <div>
            <CheckboxField
                title={
                    <>
                        <div className="flex">
                            <div>
                                Cuentas embargadas
                            </div>
                            <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[193px] bottom-full hidden py-0.5 text-sm w-[366px] h-[44px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                    Consulta el PDF de Información comercial <br></br>de TransUnion en la sección de Informe detallado
                                </div>
                            </div>
                        </div>
                    </>
                }
                type={InputTypes.Boolean}
                required={true}
                model={model}
                setModel={setModel}
                campoId='seized_accounts'
                campoIdRespuesta='accounts_seized_denied'
                opcionTrue='Sí'
                opcionFalse='No'
                editable={editable}
            />
        </div>
    );
};

const TipoPersona = ({ model, setModel, setTipoPersona, tipoPersona, editable }: FormProps & { setTipoPersona: (tipoPersona: boolean) => void, tipoPersona: boolean }) => {
    const handleTipoPersonaChange = (value: boolean) => {
        setTipoPersona(value);
    };

    return (
        <div>
            <CheckboxField
                title='Tipo de persona'
                type={InputTypes.Boolean}
                required={true}
                model={model}
                setModel={setModel}
                campoId='type_person'
                opcionTrue='Persona natural'
                opcionFalse='Persona jurídica'
                onChange={handleTipoPersonaChange} 
                checked={tipoPersona}
                editable={editable}
            />
        </div>
    );
};



const ComportamientoCarteraDistribuidor = ({model : formulario, setModel : setFormulario, editable}: FormProps) => {
    return (
        <SelectField
            required={true}
            model={formulario}
            setModel={setFormulario}
            campoId='credit_score_distributor'
            campoIdRespuesta='distributor_portfolio_behaviour_score'
            title={
                <>
                    <div className="flex">
                        <div>
                            Comportamiento cartera activa distribuidor
                        </div>
                        <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[190px] bottom-full hidden py-0.5 text-sm w-[366px] h-[64px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Selecciona el riesgo correspondiente de acuerdo <br></br>al informe de cartera actualizado en el <br></br>Excel del Scoring en la hoja Cartera CR
                            </div>
                        </div>
                    </div>
                </>
            }
            type={InputTypes.Text}
            placeholder='Seleccione el comportamiento cartera activa distribuidor'
            editable={editable}
            options={[
                {label: "Seleccione el comportamiento cartera activa distribuidor", value: ""},
                {label: "Riesgo Bajo", value: "Riesgo Bajo"},
                {label: "Riesgo Medio", value: "Riesgo Medio"},
                {label: "Riesgo Alto", value: "Riesgo Alto"}
            ]}
        />
    )
}


const ValorSolicitado = ({model : formulario, setModel : setFormulario, editable}: FormProps) => {
    return (
        <SelectField
            required={true}
            model={formulario}
            setModel={setFormulario}
            campoId='financial_percent'
            type={InputTypes.Number}
            placeholder='Valor Solicitado'
            editable={editable}
            options={[
                {label: "Valor solicitado", value: ""},
                {label: "10%", value: 10 },
                {label: "20%", value: 20 },
                {label: "30%", value: 30 },
                {label: "40%", value: 40 },
                {label: "50%", value: 50 },
                {label: "60%", value: 60 },
                {label: "70%", value: 70 }
            ]}
        />
    )
}

const IngresosAdicionales = ({ model, setModel, editable }: FormProps) => {
    return (
        <div>
            <CheckboxField
                title={
                    <>
                        <div className="flex">
                            <div>
                                ¿Está invertido en el negocio?
                            </div>
                            <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[200px] bottom-full hidden py-0.5 text-sm w-[389px] h-[84px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Si el cliente selecciona 'Si' en la pregunta de ingresos <br></br> adicionales mayores al 50% en el paso de <br></br>Información de la máquina de FinMaq Go <br></br>debe elegir la opción 'No' y visceversa
                                </div>
                            </div>
                        </div>
                    </>
                }
                type={InputTypes.Boolean}
                required={true}
                model={model}
                setModel={setModel}
                campoId='invested_in_deal'
                campoIdRespuesta='invested_score'
                opcionTrue='Sí'
                opcionFalse='No'
                editable={editable}
            />
        </div>
    );
};



const NecesidadAdquisicion = ({model : formulario, setModel : setFormulario, editable}: FormProps) => {
    return (
        <SelectField
            required={true}
            model={formulario}
            setModel={setFormulario}
            campoId='indispensable_asset'
            campoIdRespuesta='indispensable_asset_score'
            title={
                <>
                    <div className="flex">
                        <div>
                            ¿El activo productivo es indispensable?
                        </div>
                        <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[185px] bottom-full hidden py-0.5 text-sm w-[354px] h-[44px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Opción seleccionada en el paso de Información<br></br> de la máquina de FinMaq Go
                            </div>
                        </div>
                    </div>
                </>
            }
            type={InputTypes.Text}
            placeholder='Seleccione una opción'
            editable={editable}
            options={[
                {label: "Seleccione una opción", value: ""},
                {label: "Reemplazar equipos o maquinaria que cumplió su vida útil", value: 'REPLACEMENT'},
                {label: "Integrar un proceso que actualmente se terceriza a través de un equipo alquilado o un tercero", value: "INTEGRATE_THIRD_PARTY_SERVICE"},
                {label: "Nueva línea de negocio o servicio relacionada a su actividad actual", value: "NEW_DEAL_LINE"},
                {label: "Aumento de capacidad instalada", value: "CAPACITY_INCREASE"},
                {label: "Nueva actividad comercial diferente a la que desarrolla actualmente", value: "NEW_ACTIVITY"}
            ]}
        />
    )
}

const CiudadCliente = ({model : formulario, setModel : setFormulario, editable}: FormProps) => {
    return (
        <SelectField
            required={true}
            model={formulario}
            setModel={setFormulario}
            campoId='department'
            campoIdRespuesta='department_score'
            type={InputTypes.Text}
            placeholder='Departamento'
            title={
                <>
                    <div className="flex">
                        <div>
                            Ubicación
                        </div>
                        <div className="relative group inline-block ml-1 mt-0.5">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[200px] bottom-full hidden py-0.5 text-sm w-[378px] h-[24px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Opción seleccionada en el formulario de FinMaq Go
                            </div>
                        </div>
                    </div>
                </>
            }
            editable={editable}
            options={[
                {label:"Departamento", value:""},
                {label:"Amazonas", value:"amazonas"},
                {label:"Antioquía", value:"antioquía"},
                {label:"Arauca", value:"arauca"},
                {label:"Atlántico", value:"atlántico"},
                {label:"Bolívar", value:"bolívar"},
                {label:"Boyacá", value:"boyacá"},
                {label:"Caldas", value:"caldas"},
                {label:"Caquetá", value:"caquetá"},
                {label:"Casanare", value:"casanare"},
                {label:"Cauca", value:"cauca"},
                {label:"Cesar", value:"cesar"},
                {label:"Chocó", value:"chocó"},
                {label:"Córdoba", value:"córdoba"},
                {label:"Cundinamarca", value:"cundinamarca"},
                {label:"Bogotá", value:"bogotá"},
                {label:"Guainia", value:"guainia"},
                {label:"Guaviare", value:"guaviare"},
                {label:"Huila", value:"huila"},
                {label:"Guajira", value:"guajira"},
                {label:"Magdalena", value:"magdalena"},
                {label:"Meta", value:"meta"},
                {label:"Nariño", value:"nariño"},
                {label:"Norte de Santander", value:"santander"},
                {label:"Putumayo", value:"putumayo"},
                {label:"Quindío", value:"quindío"},
                {label:"Risaralda", value:"risaralda"},
                {label:"San Andres y Providencia", value:"san andres y providencia"},
                {label:"Santander", value:"santander"},
                {label:"Sucre", value:"sucre"},
                {label:"Tolima", value:"tolima"},
                {label:"ValledelCauca", value:"cauca"},
                {label:"Vaupés", value:"vaupés"},
                {label:"Vichada", value:"vichada"}
            ]}
        >
        </SelectField>
    )
}
