import type React from "react"
import { useState, useEffect, useCallback } from "react"
import solicitudHubspotNuevo, {RequestParams} from "../../../adapters/ports/solicitudes-hubspot"
import type { SolicitudesHubspotDTO } from "../../../adapters/dto/solicitudes-hubspot-dto"
import { Card } from "@material-tailwind/react"
import DateRangePicker from "../calendar/calendar"
import FiltroEscritura from "./filtro_escritura"
import VariosFiltros from "./filtro-varios-filtros"
import { useCognitoUserAttributes } from "app/login/adapters/useCognitoUserAttributes"
import { ConfigVariables } from "adapters/ports/config-variables"
import { useNavigate } from "react-router-dom"
import { filterStages } from "app/analista/page-analist/infrastructure/dealstages"
import { SortButtons } from "../SortButtons/SortButtons" 

interface SolicitudesHubspotTableProps {
  analista_de_credito_asignado: string | null
}

const SolicitudesHubspotTable: React.FC<SolicitudesHubspotTableProps> = ({ analista_de_credito_asignado }) => {
  const [solicitudes, setSolicitudes] = useState<SolicitudesHubspotDTO[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [total, setTotal] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [dateRange, setDateRange] = useState<string | null>(null)
  const [previousAfters, setPreviousAfters] = useState<string[]>([])
  const [query, setQuery] = useState<string>("")
  const [dealstage, setDealstage] = useState<string[]>([])
  const [ingresarAStandby, setIngresarAStandby] = useState<string | null>(null)
  const [sortProperty, setSortProperty] = useState<string | null>("fecha_de_radicado_definitivo")
  const [sortDirection, setSortDirection] = useState<"ASCENDING" | "DESCENDING">("DESCENDING")
  const { nameUser } = useCognitoUserAttributes()
  const pageSize = 7
  const navigate = useNavigate()

  const isNumericSearch = (value: string): boolean => /^[\d-]+$/.test(value)

  const normalizeDocumentNumber = (value: string): string => value.replace(/-/g, "")

  interface fetchSolicitudesProps extends Omit<RequestParams, 'analista_de_credito_asignado'>{
    isNextPage?: boolean
  }

  const fetchSolicitudes = useCallback(
    async ({after, isNextPage, date_range, dealstage, ingresar_a_standby, query, sort}: fetchSolicitudesProps) => {
      if (!analista_de_credito_asignado) {
        setLoading(false)
        return
      }

      try {
        setLoading(true)
        const params = {
          analista_de_credito_asignado,
          ...(ingresar_a_standby && { ingresar_a_standby: ingresar_a_standby }),
          ...(after !== null && { after }),
          ...(date_range && { date_range }),
          ...(dealstage?.length >= 1 && { dealstage }),
          ...(query && {query}),
          ...(sort && {sort})
        }

        const {
          solicitudes: newSolicitudes,
          total: newTotal,
          nextAfter,
        } = await solicitudHubspotNuevo.obtener(params)

        setSolicitudes(newSolicitudes)
        setTotal(newTotal)

        if(nextAfter && isNextPage){
          setPreviousAfters(previousAfters => 
            previousAfters.includes(nextAfter) ? previousAfters : [...previousAfters, nextAfter]
          );
        }
      } finally {
        setLoading(false)
      }
    },
    [analista_de_credito_asignado],
  )

  const handleNextPage = () => {
    const nextAfter = previousAfters[currentPage - 1];
    if(nextAfter){
      setCurrentPage(currentPage + 1)
      fetchSolicitudes({
        after: nextAfter, 
        isNextPage: true, 
        date_range: dateRange,
        dealstage,
        ingresar_a_standby: ingresarAStandby,
        sort: `${sortProperty}/${sortDirection}`,
        query
      })
    }
  }

  const handlePreviousPage = () => {
    if (currentPage <= 1) return

    const prevAfter = previousAfters[currentPage - 3];

    setCurrentPage(currentPage - 1)
    fetchSolicitudes({
      after: prevAfter, 
      isNextPage: false, 
      date_range: dateRange,
      dealstage,
      ingresar_a_standby: ingresarAStandby,
      sort: `${sortProperty}/${sortDirection}`,
      query
    })
  }

  const handleSearch = async (query: string): Promise<SolicitudesHubspotDTO[]> => {
    const isNumeric = isNumericSearch(query)
    const normalizedQuery = isNumeric ? normalizeDocumentNumber(query) : query

    const params = {
      analista_de_credito_asignado,
      date_range: dateRange,
      ...(ingresarAStandby && { ingresar_a_standby: ingresarAStandby }),
      ...(isNumeric ? { numero_de_identificacion_del_contacto: normalizedQuery } : { query }),
      ...(dealstage?.length >= 1 && { dealstage }),
    }

    const { solicitudes: results, total: newTotal } = await solicitudHubspotNuevo.obtener(params)

    setTotal(newTotal)

    return isNumeric
      ? results.filter((solicitud) => {
          if (!solicitud.numero_de_identificacion_del_contacto) return false

          const normalizedDocNumber = normalizeDocumentNumber(solicitud.numero_de_identificacion_del_contacto)
          return normalizedDocNumber === normalizedQuery || normalizedDocNumber.includes(normalizedQuery)
        })
      : results
  }

  const handleSearchStages = async (dealstage?: any[], ingresar_a_standby?: string): Promise<SolicitudesHubspotDTO[]> => {
    const { solicitudes: results, total: newTotal } = await solicitudHubspotNuevo.obtener({
      analista_de_credito_asignado,
      ...(ingresar_a_standby && { ingresar_a_standby }),
      ...(dealstage?.length >= 1 && { dealstage }),
      ...(dateRange && {date_range: dateRange}),
      ...(query && {query})
    })

    setSolicitudes(results)
    setDealstage(dealstage)
    setIngresarAStandby(ingresar_a_standby)
    setTotal(newTotal)
    
    return results
  }

  
  const handleSort = async (property:string) => {
    let direction: "ASCENDING" | "DESCENDING" = "DESCENDING";
    
    if(property === sortProperty){
      sortDirection === "ASCENDING" ? direction = "DESCENDING": direction = "ASCENDING";
    }else{
      direction = "DESCENDING";
    }
    
    const newSort = `${property}/${direction}`

    const { solicitudes: results, total: newTotal } = await solicitudHubspotNuevo.obtener({
      analista_de_credito_asignado,
      ...(ingresarAStandby && { ingresar_a_standby: ingresarAStandby }),
      ...(dealstage?.length >= 1 && { dealstage }),
      ...(dateRange && {date_range: dateRange}),
      ...(query && {query}),
      sort: newSort,
      after: "0"
    })

    setSortProperty(property)
    setSortDirection(direction)
    setCurrentPage(1)
    setTotal(newTotal)
    setSolicitudes(results)

  }

  const handleResultSelect = (solicitud: SolicitudesHubspotDTO) => {
    setSolicitudes([solicitud])
    setTotal(1)
    setPreviousAfters([])
    setCurrentPage(1)
  }

  const handleResultsFound = (results: SolicitudesHubspotDTO[]) => {
    setSolicitudes(results)
    setTotal(results.length)
    setPreviousAfters([])
    setCurrentPage(1)
  }

  const handleDateRangeChange = (newDateRange: string | null) => {
    setDateRange(newDateRange)
    setCurrentPage(1)
    setPreviousAfters([])

    fetchSolicitudes({
      isNextPage: true, 
      date_range: newDateRange,
      dealstage,
      ingresar_a_standby: ingresarAStandby,
      query
    })
  }

  const resetSearch = useCallback(() => {
    setCurrentPage(1)
    setPreviousAfters([])
    
    fetchSolicitudes({
      isNextPage: true, 
      date_range: dateRange,
      dealstage,
      ingresar_a_standby: ingresarAStandby
    })
    
  }, [fetchSolicitudes, dateRange, dealstage, ingresarAStandby])

  const startIndex = total > 0 ? (currentPage - 1) * pageSize + 1 : 0
  const endIndex = Math.min(currentPage * pageSize, total)

  const handleRowClick = (solicitud: SolicitudesHubspotDTO) => {
    
    const formattedDate = solicitud.fecha_de_radicado_definitivo
      ? solicitud.fecha_de_radicado_definitivo
          .toLocaleString("es-CO", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })
          .replace(",", "")
      : "Sin fecha"

    const formattedAmount = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(Number(solicitud.amount))
      .replace("COP", "")
      .trim()

    navigate(`/detalle-solicitud-${solicitud.numero_de_identificacion_del_contacto}`, {
      state: {
        solicitud: {
          ...solicitud,
          formattedDate,
          formattedAmount,
        },
      },
    })
  }

  useEffect(() => {
    analista_de_credito_asignado && fetchSolicitudes({isNextPage: true, sort: `${sortProperty}/${sortDirection}`})
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [analista_de_credito_asignado, fetchSolicitudes])

  return (
    <Card className="w-full flex flex-col h-[calc(100vh-236px)] shadow-2xl p-4 mb-5 rounded-[24px]">
      <div className="flex justify-between items-center">
        <div>
          <div className="text-[#718096] text-xs font-normal">Estás viendo Solicitudes asignadas a</div>
          <div className="text-[#51C1E1] text-base font-normal">{nameUser}</div>
        </div>
        <div className="flex gap-3 mt-2">
          <FiltroEscritura
            onSearch={handleSearch}
            onResultSelect={handleResultSelect}
            resetSearch={resetSearch}
            onResultsFound={handleResultsFound}
            query={query}
            setQuery={setQuery}
          />
          <VariosFiltros 
            onSearchStages={handleSearchStages}/>
          <DateRangePicker onDateRangeChange={handleDateRangeChange} />
        </div>
      </div>
      <div className="flex flex-col flex-grow mt-2 overflow-hidden">
        {loading ? (
          <div className="flex-grow flex items-center justify-center">
            <div className="text-center py-4">Cargando...</div>
          </div>
        ) : solicitudes.length > 0 ? (
          <div className="flex flex-col h-full">
            <div className="w-full">
              <table className="table-fixed w-full border-collapse">
                <thead className="text-[#4A5568] text-xs font-bold h-8 text-center">
                  <SortButtons 
                    sortDirection={sortDirection} 
                    sortProperty={sortProperty} 
                    handleSort={handleSort}/>
                </thead>
              </table>
            </div>
            <div className="flex-grow overflow-y-auto">
              <table className="table-fixed w-full border-collapse">
                <tbody className="text-[#2D3748] text-xs font-normal">
                  {solicitudes.map((solicitud, index) => (
                    <tr key={index} className="border-b border-[#E2E8F0] hover:bg-[#F0F9FC] cursor-pointer" onClick={() => handleRowClick(solicitud)}>
                      <td className="w-1/4 h-8 text-start px-2">{solicitud.dealname}</td>
                      <td className="w-1/4 h-8 text-center">{solicitud.numero_de_identificacion_del_contacto}</td>
                      <td className="w-1/4 h-8 text-center">
                        {solicitud.fecha_de_radicado_definitivo
                          ? solicitud.fecha_de_radicado_definitivo
                              .toLocaleString("es-CO", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                                hour12: false,
                              })
                              .replace(",", "")
                          : "Sin fecha"}
                      </td>
                      <td className="w-1/4 h-8 text-center">
                        {"COP " +
                          new Intl.NumberFormat("es-CO", {
                            style: "currency",
                            currency: "COP",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })
                            .format(Number(solicitud.amount))
                            .replace("COP", "")
                            .trim()}
                      </td>
                      <td className="w-1/4 h-14">
                        <div className="flex justify-start items-center gap-2">
                          <span 
                            style={{backgroundColor: filterStages.find((stage) => stage.value === solicitud.dealstage).color}}
                            className={`px-2 rounded-[2px] font-medium`}>
                            {filterStages.find((stage) => stage.value === solicitud.dealstage).label}
                          </span>
                          {solicitud.ingresar_a_standby === "true" && (
                            <div className="relative group inline-block">
                              <img
                                src={ConfigVariables.TOOLTIP_STAND_BY || "/placeholder.svg"}
                                alt="stand by"
                                className="w-4 h-4"
                              />
                              <div className="absolute right-full mr-2 top-1/2 -translate-y-1/2 text-center z-50 hidden py-0.5 px-2 text-sm w-44 h-6 leading-5 text-white bg-[#171923] rounded-lg group-hover:block">
                                Solicitud en standby
                                <div className="absolute right-[1px] translate-x-full top-1/2 -translate-y-1/2 border-y-8 border-y-transparent border-l-8 border-l-[#171923]"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="flex-grow flex items-center justify-center">
            <div className="w-full h-full flex flex-col items-center justify-center text-center">
              <div className="mb-2">
                <img src={ConfigVariables.ICON_ALERT_ERROR || "/placeholder.svg"} alt="error" className="w-8 h-8" />
              </div>
              <div className="text-lg font-bold text-[#000000]">No se han encontrado coincidencias</div>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end items-center gap-8 mt-4">
        <div className="text-sm text-[#2B3674]">
          {startIndex}-{endIndex} <span className="text-[#707EAE]">of {total}</span>
        </div>
        <div className="flex items-center gap-[24px] mr-10">
          <button onClick={handlePreviousPage} disabled={currentPage === 1} className="disabled:opacity-50">
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 18L9 12L15 6"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button onClick={handleNextPage} disabled={typeof previousAfters[currentPage - 1] === "undefined"} className="disabled:opacity-50"> 
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 18L15 12L9 6"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </Card>
  )
}

export default SolicitudesHubspotTable
