import SideBar from "app/analista/page-analist/interfaces/components/side-bar/side-bar"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import SideBarDetalleNegocio from "../components/sidebar/sidebar-detalle-negocio"
import CardScoring from "../components/card/card-scoring"
import Logout from "app/analista/page-analist/interfaces/components/banner/logout"

export default function DetalleSolicitudPorRevisar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  const solicitudData = location.state?.solicitud

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const handleBack = () => {
    navigate(-1)
  }

  if (!solicitudData) {
    navigate("/solicitudes-por-revisar")
    return null
  }

  return (
    <div className="flex h-screen overflow-hidden">
        <SideBar isOpen={isSidebarOpen} onToggle={toggleSidebar} />
        <div className={`flex-1 p-5 ${isSidebarOpen ? "ml-[85px]" : "ml-[315px]"} overflow-hidden relative`}>
            <div className="absolute top-5 w-full flex justify-between px-10">
                <div className="text-xs text-[#718096] mt-1.5 -ml-4">
                    <span className="cursor-pointer" onClick={handleBack}> Solicitudes por revisar </span>
                    <span className="text-xs font-bold">/ Detalle de solicitud</span>
                </div>
                <Logout />
            </div>
            <div className="pt-16 flex h-full">
                <div className="w-[320px] flex-shrink-0">
                    <SideBarDetalleNegocio solicitud={solicitudData} />
                </div>
                <div className="flex-1 flex -ml-4 mr-2">
                    <CardScoring solicitud={solicitudData} />
                </div>
            </div>
        </div>
    </div>
  )
}

