"use client"

import type React from "react"
import { useState, useEffect } from "react"
import CurrencyInput from "react-currency-input-field"
import type FieldProps from "./textFieldProps"
import { InputTypes } from "./textFieldProps"
import { HiOutlineCalendarDays } from "react-icons/hi2"

const CompilarTextField = ({
  model,
  setModel,
  campoId,
  placeholder,
  type,
  required,
  onBlur,
  icon,
  min,
  max,
  campoIdRespuesta,
  editable,
  title,
}: FieldProps) => {
  const [resumeValue, setResumeValue] = useState<any>(undefined)

  useEffect(() => {
    if (campoIdRespuesta && model.resume && model.resume[campoIdRespuesta] !== undefined) {
      setResumeValue(model.resume[campoIdRespuesta])
    }
  }, [model, campoIdRespuesta])

  const bgResp = model.approved ? "bg-[#C6F6D5] text-[#22543D]" : "bg-[#FED7D7] text-[#822727]"

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e)
  }

  const [isEmpty, setIsEmpty] = useState(
    model[campoId] === undefined || model[campoId] === null || model[campoId] === "",
  )

  useEffect(() => {
    setIsEmpty(model[campoId] === undefined || model[campoId] === null || model[campoId] === "")
  }, [model, campoId])

  return (
    <div className="mb-4 w-auto relative bg-white">
      <div className="flex items-center">
        <div className="text-sm text-[#171923] font-semibold mr-2">{title}</div>
        {!editable && resumeValue !== undefined && (
          <div className={`${bgResp} w-auto px-1 h-[20px] text-center rounded-[2px] text-sm font-bold`}>
            {typeof resumeValue === "number" ? resumeValue.toFixed(1) : resumeValue}
          </div>
        )}
      </div>
      <div className="relative w-full">
        {type === InputTypes.Money || type === InputTypes.Percent ? (
          <FormatInput
            campoId={campoId}
            model={model}
            setModel={setModel}
            placeholder={placeholder}
            type={type}
            onBlur={handleBlur}
            required={required}
            min={min}
            max={max}
            editable={editable}
          />
        ) : type === InputTypes.Date ? (
          <div className="relative w-full">
            <input
              autoComplete="true"
              required={required}
              title={title}
              type="date"
              className={`w-full h-[48px] mt-[17px] mb-[38px] font-sans font-normal p-3 rounded-2xl border cursor-pointer peer text-[#171923]`}
              placeholder={placeholder}
              value={model[campoId] !== undefined ? model[campoId] : ""}
              onChange={(e) => {
                setModel((prevModel: any) => ({
                  ...prevModel,
                  [campoId]: e.target.value,
                }))
              }}
              onBlur={handleBlur}
              disabled={!editable}
            />
            <div className="absolute w-[18px] h-[18px] mt-[-72px] right-[13px] bg-white flex items-center pointer-events-none">
              <HiOutlineCalendarDays className="text-[#171923] cursor-pointer" />
            </div>
          </div>
        ) : (
          <BasicInput
            campoId={campoId}
            model={model}
            setModel={setModel}
            placeholder={placeholder}
            type={type}
            onBlur={handleBlur}
            required={required}
            min={min}
            max={max}
            editable={editable}
          />
        )}
        <label
          htmlFor={campoId}
          className={`absolute left-[14px] mt-[8px] transition-all duration-300 cursor-pointer ${isEmpty ? "top-[20px] text-base text-gray-400" : "top-[2px] text-xs text-[#A0AEC0] bg-white px-1"}`}
        >
          {placeholder}
        </label>
      </div>
    </div>
  )
}

const BasicInput = ({
  model,
  setModel,
  campoId,
  placeholder,
  type,
  required,
  onBlur,
  min,
  max,
  editable,
}: FieldProps & { editable: boolean }) => {
  return (
    <input
      id={campoId}
      autoComplete="true"
      required={required}
      type={type}
      inputMode="numeric"
      className="w-full h-[48px] mt-[17px] mb-[38px] font-sans font-normal text-[#171923] p-3 rounded-2xl border cursor-pointer peer"
      placeholder=""
      value={model[campoId] !== undefined ? model[campoId] : ""}
      onChange={(e) => {
        const inputValue = e.target.value
        if (inputValue === "-0") {
          setModel((prevModel: any) => ({
            ...prevModel,
            [campoId]: "",
          }))
          return
        }

        if (inputValue === "" || (!isNaN(Number.parseFloat(inputValue)) && Number.parseFloat(inputValue) >= 0)) {
          const limitedValue =
            inputValue === ""
              ? ""
              : Math.min(
                  max !== undefined ? max : Number.parseFloat(inputValue),
                  Math.max(min !== undefined ? min : Number.parseFloat(inputValue), Number.parseFloat(inputValue)),
                )
          setModel((prevModel: any) => ({
            ...prevModel,
            [campoId]: limitedValue,
          }))
        }
      }}
      onBlur={onBlur}
      disabled={!editable}
    />
  )
}

const FormatInput = ({
  model,
  setModel,
  campoId,
  placeholder,
  type,
  required,
  onBlur,
  min,
  max,
  editable,
}: FieldProps & { editable: boolean }) => {
  return (
    <CurrencyInput
      id={campoId}
      name={campoId}
      prefix={type === InputTypes.Money ? "$" : ""}
      suffix={type === InputTypes.Percent ? "%" : ""}
      allowDecimals={type === InputTypes.Money}
      value={model[campoId] !== undefined && model[campoId] !== null ? model[campoId] : ""}
      onValueChange={(value) => {
        if (value === "" || (!isNaN(Number.parseFloat(value)) && Number.parseFloat(value) >= 0)) {
          const limitedValue =
            value === ""
              ? ""
              : Math.min(
                  max !== undefined ? max : Number.parseFloat(value),
                  Math.max(min !== undefined ? min : Number.parseFloat(value), Number.parseFloat(value)),
                )
          setModel((prevModel: any) => ({
            ...prevModel,
            [campoId]: limitedValue,
          }))
        } else {
          setModel((prevModel: any) => ({
            ...prevModel,
            [campoId]: "",
          }))
        }
      }}
      className="w-full h-[48px] mt-[17px] mb-[38px] text-[#171923] font-sans font-normal p-3 rounded-2xl border cursor-pointer peer"
      placeholder=""
      required={required}
      onBlur={onBlur}
      disabled={!editable}
    />
  )
}

export default CompilarTextField

