import { ConfigVariables } from "adapters/ports/config-variables";
import { useCognitoUserAttributes } from "app/login/adapters/useCognitoUserAttributes";
import { userPool } from "app/login/adapters/userPoolCognito";
import { clearLocalStorageCustom } from "app/utilities/clearLocalStorageCustom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
    const [openUser, setUser] = useState(false);
    const navigate = useNavigate();
    const { email } = useCognitoUserAttributes()

    const handleUser = (e: any) => {
        e.preventDefault();
        setUser(!openUser);
    }

    const handleLogout = () => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.getSession((err : any, result : any)=>{
                if(result){
                cognitoUser.globalSignOut({
                    onSuccess: (result) => {
                        clearLocalStorageCustom()
                        navigate('/', { state: { message: 'Ha cerrado su sesión exitosamente!' } });
                    },
                    onFailure: () => {
                    },
                });
                }
            });   
        }
    }

    return (
        <div className="relative flex items-center justify-center w-14 h-14 bg-white rounded-full mr-8 shadow-2xl">
            <button onClick={handleUser} className="flex items-center justify-center">
                <img 
                    src={openUser ? ConfigVariables.IMAGEN_AVATAR_LOGOUT : ConfigVariables.IMAGEN_AVATAR_NAVBAR} 
                    alt="Avatar" 
                    className="w-11 h-11 rounded-full hover:cursor-pointer" 
                />
            </button>
            {openUser && (
                <div className="absolute right-0 top-[65px] z-10 w-72 bg-white border border-[#E2E8F0] rounded-[6px]">
                    <div className="text-[#A0AEC0] p-2 text-lg border-b border-[#E2E8F0]">
                        {email}
                    </div>
                    <div 
                        onClick={handleLogout} 
                        className="text-[#2D3748] hover:bg-gray-100 cursor-pointer p-2 text-lg rounded-b-[6px]"
                    >
                        Cerrar Sesión
                    </div>
                </div>
            )}
        </div>
    )
}