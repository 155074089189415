import CheckboxField from "../utilities/radioButom";
import SelectField from "../utilities/selectField";
import CompilarTextField from "../utilities/textField";
import { FormProps, InputTypes } from "../utilities/textFieldProps";

export default function FormularioInversionYDistribuidor({formulario, setFormulario, editable}: {formulario : any, setFormulario : any, editable: boolean}) {

    return (
        <>  
            <div>
                <div className="flex"><span className="text-md text-[#4A5568] font-medium">Exposición Final</span>
                    <div className="relative group inline-block ml-1 mt-1">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cursor-pointer"
                        >
                            <path
                                d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="absolute text-center z-50 left-[130px] top-4 bottom-full hidden py-0.5 text-sm w-[396px] h-[105px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                            Valor del activo: Valor diligenciado en el campo de<br></br> Valor Máquina de Pre-Aprobados o consultar cotización.<br></br><br></br> Valor solicitado: Valor seleccionado en el campo<br></br>de Porcentaje a Financiar de Pre-Aprobados
                        </div>
                    </div> 
                </div>
            </div>
            <div className="flex grid grid-cols-2 gap-4">
                <Valor model={formulario} setModel={setFormulario} editable={editable}/>
                <ValorSolicitado model={formulario} setModel={setFormulario}  editable={editable}/>
            </div>
            <div className="flex grid grid-cols-2 gap-4 -mt-[8px]">
                <PlazoSolicitado model={formulario} setModel={setFormulario} editable={editable}/>
                <PlazoFinal model={formulario} setModel={setFormulario} editable={editable}/>
            </div>
            <div className="flex grid grid-cols-2 gap-4">
                <div>
                    <div className="flex"><span className="text-md text-[#4A5568] font-medium">¿El activo productivo es indispensable?</span>
                        <div className="relative group inline-block ml-1 mt-1">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[195px] top-4 bottom-full hidden py-0.5 text-sm w-[375px] h-[44px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Opción seleccionada en el paso de Información de la<br></br>máquina de FinMaq Go
                            </div>
                        </div> 
                    </div>
                    <NecesidadAdquisicion model={formulario} setModel={setFormulario} editable={editable}/>
                </div>
                <div>
                    <div className="flex"><span className="text-md text-[#4A5568] font-medium">¿Está invertido en el negocio?</span>
                        <div className="relative group inline-block ml-1 mt-1">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 -left-[180px] top-4 bottom-full hidden py-2 text-sm w-[383px] h-[95px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Si el cliente selecciona 'Si' en la pregunta de ingresos<br></br>adicionales mayores al 50% en el paso de Información<br></br>de la máquina de FinMaq Go, debe elegir la opción <br></br>'No' y visceversa
                            </div>
                        </div> 
                    </div>
                    <IngresosAdicionales model={formulario} setModel={setFormulario} editable={editable}/>
                </div>
            </div>
            <div className="flex grid grid-cols-2 gap-4">
                <div>
                    <div className="flex"><span className="text-md text-[#4A5568] font-medium">Tamaño del distribuidor</span>
                        <div className="relative group inline-block ml-1 mt-1">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[195px] top-4 bottom-full hidden py-0.5 text-sm w-[383px] h-[25px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Consulta la hoja Distribuidores del Excel del Scoring
                            </div>
                        </div> 
                    </div>
                    <VentasAnualesDistribuidor model={formulario} setModel={setFormulario} editable={editable}/>
                </div>
                <div>
                    <div className="flex"><span className="text-md text-[#4A5568] font-medium">Comportamiento cartera activa distribuidor</span>
                        <div className="relative group inline-block ml-1 mt-1">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 -left-[200px] top-4 bottom-full hidden py-2 text-sm w-[419px] h-[74px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Selecciona el riesgo correspondiente de acuerdo al informe<br></br>de cartera actualizado en el Excel del Scoring en la hoja<br></br>Cartera CR
                            </div>
                        </div> 
                    </div>
                    <ComportamientoCarteraDistribuidor model={formulario} setModel={setFormulario} editable={editable}/>
                </div>
                <div className="-mt-[35px]">
                    <div className="flex"><span className="text-md text-[#4A5568] font-medium">Cobertura</span>
                        <div className="relative group inline-block ml-1 mt-1">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                            >
                                <path
                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div className="absolute text-center z-50 left-[200px] top-4 bottom-full hidden py-0.5 text-sm w-[391px] h-[65px] leading-5 text-white bg-[#171923] rounded-lg group-hover:block -translate-x-1/2">
                                Cálculo aproximado de dinero libre después de cubrir<br></br>costos y gastos. Gestiona el Excel del Scoring y consulta<br></br>el valor calculado en la hoja de Coberturas
                            </div>
                        </div> 
                    </div>
                    <Coberturas model={formulario} setModel={setFormulario} editable={editable}/>
                </div>
            </div>
        </>
    );
}

const Valor = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='value'
            campoIdRespuesta='finance_percentage_score'
            placeholder='Valor del activo'
            type={InputTypes.Money}
            editable={editable}
        />
    )
}

const ValorSolicitado = ({model : formulario, setModel : setFormulario, editable}: FormProps) => {
    return (
        <SelectField
            required={true}
            model={formulario}
            setModel={setFormulario}
            campoId='financial_percent'
            type={InputTypes.Number}
            placeholder='Valor Solicitado'
            editable={editable}
            options={[
                {label: "Valor solicitado", value: ""},
                {label: "10%", value: 10 },
                {label: "20%", value: 20 },
                {label: "30%", value: 30 },
                {label: "40%", value: 40 },
                {label: "50%", value: 50 },
                {label: "60%", value: 60 },
                {label: "70%", value: 70 }
            ]}
        />
    )
}

const PlazoSolicitado = ({model : formulario, setModel : setFormulario, editable}: FormProps) => {
    return (
        <SelectField
            required={true}
            model={formulario}
            setModel={setFormulario}
            campoId='financial_percent'
            type={InputTypes.Number}
            title='Plazo Solicitado'
            placeholder='Plazo Solicitado'
            editable={editable}
            options={[
                {label: "Plazo solicitado", value: ""},
                {label: "10%", value: 10 },
                {label: "20%", value: 20 },
                {label: "30%", value: 30 },
                {label: "40%", value: 40 },
                {label: "50%", value: 50 },
                {label: "60%", value: 60 },
                {label: "70%", value: 70 }
            ]}
        />
    )
}

const PlazoFinal = ({model : formulario, setModel : setFormulario, editable}: FormProps) => {
    return (
        <SelectField
            required={true}
            model={formulario}
            setModel={setFormulario}
            campoId='financial_percent'
            type={InputTypes.Number}
            placeholder='Plazo Final'
            title='Plazo Final'
            editable={editable}
            options={[
                {label: "Plazo Final", value: ""},
                {label: "10%", value: 10 },
                {label: "20%", value: 20 },
                {label: "30%", value: 30 },
                {label: "40%", value: 40 },
                {label: "50%", value: 50 },
                {label: "60%", value: 60 },
                {label: "70%", value: 70 }
            ]}
        />
    )
}

const NecesidadAdquisicion = ({model : formulario, setModel : setFormulario, editable}: FormProps) => {
    return (
        <SelectField
            required={true}
            model={formulario}
            setModel={setFormulario}
            campoId='indispensable_asset'
            campoIdRespuesta='indispensable_asset_score'
            type={InputTypes.Text}
            placeholder='Seleccione una opción'
            editable={editable}
            options={[
                {label: "Seleccione una opción", value: ""},
                {label: "Reemplazar equipos o maquinaria que cumplió su vida útil", value: 'REPLACEMENT'},
                {label: "Integrar un proceso que actualmente se terceriza a través de un equipo alquilado o un tercero", value: "INTEGRATE_THIRD_PARTY_SERVICE"},
                {label: "Nueva línea de negocio o servicio relacionada a su actividad actual", value: "NEW_DEAL_LINE"},
                {label: "Aumento de capacidad instalada", value: "CAPACITY_INCREASE"},
                {label: "Nueva actividad comercial diferente a la que desarrolla actualmente", value: "NEW_ACTIVITY"}
            ]}
        />
    )
}

const IngresosAdicionales = ({ model, setModel, editable }: FormProps) => {
    return (
        <div>
            <CheckboxField
                type={InputTypes.Boolean}
                required={true}
                model={model}
                setModel={setModel}
                campoId='invested_in_deal'
                campoIdRespuesta='invested_score'
                opcionTrue='Sí'
                opcionFalse='No'
                editable={editable}
            />
        </div>
    );
};

const VentasAnualesDistribuidor = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
            model={formulario}
            required={true}
            setModel={setFormulario}
            campoId='annual_sales_distributor'
            placeholder='Valor de ventas del Distribuidor'
            campoIdRespuesta='distributor_sells_score'
            type={InputTypes.Money}
            editable={editable}
        />
    )
}

const ComportamientoCarteraDistribuidor = ({model : formulario, setModel : setFormulario, editable}: FormProps) => {
    return (
        <SelectField
            required={true}
            model={formulario}
            setModel={setFormulario}
            campoId='credit_score_distributor'
            campoIdRespuesta='distributor_portfolio_behaviour_score'
            type={InputTypes.Text}
            placeholder='Seleccione el comportamiento cartera activa distribuidor'
            editable={editable}
            options={[
                {label: "Seleccione el comportamiento cartera activa distribuidor", value: ""},
                {label: "Riesgo Bajo", value: "Riesgo Bajo"},
                {label: "Riesgo Medio", value: "Riesgo Medio"},
                {label: "Riesgo Alto", value: "Riesgo Alto"}
            ]}
        />
    )
}

const Coberturas = ({model: formulario, setModel : setFormulario, editable} : FormProps) => {
    
    return (
        <CompilarTextField
        model={formulario}
        required={true}
        setModel={setFormulario}
        campoId='coverage'
        placeholder='Riesgo de cobertura'
        campoIdRespuesta='coverage_score'
        type={InputTypes.Number}
        min={0}
        max={10}
        editable={editable}
        />
    )
}