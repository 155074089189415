import axios, { AxiosInstance, AxiosResponse } from "axios";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { userPool } from "../app/login/adapters/userPoolCognito";
import {jwtDecode} from 'jwt-decode'


export class AuthService {
    static getAccessToken(): string | null {
        return localStorage.getItem('accessToken');
    }
    
    static setAccessToken(accessToken: string): void {
        localStorage.setItem('accessToken', accessToken);
    }

    static getIdToken(): string | null {
        return localStorage.getItem('idToken');
    }
    
    static setIdToken(idToken: string): void {
        localStorage.setItem('idToken', idToken);
    }

    static clearTokens(): void {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('idToken');
        localStorage.removeItem('refreshToken');
    }

    static async updateAccessToken(): Promise<void> {
        try {
            const cognitoUser = userPool.getCurrentUser();
            
            if (cognitoUser) {
                await new Promise<void>((resolve, reject) => {
                    cognitoUser.getSession((error: any, session: CognitoUserSession) => {
                        if (error) {
                            console.error('Error al obtener sesión:', error);
                            window.location.href = "/#revoked-token";
                            reject(error);
                        } else if (session) {
                            const accessToken = session.getAccessToken().getJwtToken();
                            const idToken = session.getIdToken().getJwtToken();
                            this.setAccessToken(accessToken);
                            this.setIdToken(idToken);
                            resolve();
                        } else {
                            console.error('No se pudo obtener el token de sesión.');
                            reject(new Error('No se pudo obtener el token de sesión.'));
                        }
                    });
                });
            } else {
                throw new Error('No se encontró un usuario de Cognito.');
            }
        } catch (error) {
            console.error('Error al actualizar el token de acceso:', error);
            window.location.href = "/#revoked-token";
            throw error;
        }
    }

    static decodeToken(token: string): any {
        try {
            return jwtDecode(token);
        } catch (error) {
            console.error('Error al decodificar el token:', error);
            throw error;
        }
    }
}

class HttpClientService {
    private client: AxiosInstance;
    private accessToken: string | null;
    private tokenRefreshPromise: Promise<void> | null;

    constructor(baseUrl: string, accessToken: string | null) {
        this.client = axios.create({ baseURL: baseUrl || "http://localhost:3001" });
        this.accessToken = accessToken;
        this.setupRequestInterceptor();
        this.setupResponseInterceptor();
    }

    private setupRequestInterceptor() {
        this.client.interceptors.request.use((config: any) => {
            if (this.accessToken) {
                config.headers.Authorization = `Bearer ${this.accessToken}`;
            }
            return config;
        });
    }

    private setupResponseInterceptor() {
        this.client.interceptors.response.use(
            (response) => response,
            (error) => {
                const status = error.response?.status;
                
                if (status === 404) {
                    window.location.href = "/*";
                } else if (status === 500) {
                    window.location.href = "/error-500";
                }

                return Promise.reject(error);
            }
        );
    }

    private isAccessTokenExpired(): boolean {
        if (!this.accessToken) {
            return true;
        }
        const tokenPayload = this.accessToken.split('.')[1];
        const decodedTokenPayload = JSON.parse(atob(tokenPayload));
        const expirationTime = decodedTokenPayload.exp * 1000;
        const currentTime = Date.now();
        return expirationTime < currentTime;
    }

    private async ensureAccessToken(): Promise<void> {
        this.accessToken = AuthService.getAccessToken();
        const cognitoUser = userPool.getCurrentUser();

        if(this.accessToken && this.isAccessTokenExpired()){   
            if (cognitoUser) {
                cognitoUser.getSession((err:any, result:any)=>{
                    if(result){
                    cognitoUser.globalSignOut({
                        onSuccess: (result) => {
                            window.location.href = "/#revoked-token";
                        },
                        onFailure: (err) => {
                            console.log(err)
                        },
                    });
                    }
                });
            }
        }

        if (!this.accessToken || this.isAccessTokenExpired()) {
            if (!this.tokenRefreshPromise) {
                this.tokenRefreshPromise = AuthService.updateAccessToken();
            }
            await this.tokenRefreshPromise;
            this.tokenRefreshPromise = null;
            this.accessToken = AuthService.getAccessToken(); 
        }
    }

    async get<T>(url: string, params?: any): Promise<T> {
        await this.ensureAccessToken();
        try {
            const response: AxiosResponse<T> = await this.client.get(url, { params, headers: {Authorization: `Bearer ${this.accessToken}`} });
            return response.data;
        } catch (error) {
            
            throw error;
        }
    }

    async post<T>(url: string, data: any, params?: any): Promise<T> {
        await this.ensureAccessToken();
        try {
            const response: AxiosResponse<T> = await this.client.post(url, data, { params, headers: {Authorization: `Bearer ${this.accessToken}`}, timeout: 10000 });
            return response.data;
        } catch (error) {

            throw error;
        }
    }

    async patch<T>(url: string, data: any): Promise<T> {
        await this.ensureAccessToken();
        try {
            const response: AxiosResponse<T> = await this.client.patch(url, data, { headers: {Authorization: `Bearer ${this.accessToken}`} });
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async delete<T>(url: string, data: any): Promise<T> {
        await this.ensureAccessToken();
        try {
            const response: AxiosResponse<T> = await this.client.delete(url, { data, headers: {Authorization: `Bearer ${this.accessToken}`} });
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    static instance(baseUrl: string, accessToken: string | null) { 
        return new HttpClientService(baseUrl, accessToken); 
    }

    private async errorHandler(error: any) {
        console.error('Error:', error);
        throw error;
    }
}

const accessToken = AuthService.getAccessToken();
const httpClientService = HttpClientService.instance(process.env.REACT_APP_DIGITAL_LOANS_URL_STAGING, accessToken);

export default httpClientService;
