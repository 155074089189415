export class FormularioDTO {
    type_person: boolean
    department : string
    invested_in_deal: boolean
    financial_percent: number
    indispensable_asset: string
    arrears_amount: number
    arrears_oldness: number
    liabilities: number
    score_credit_centers: number
    date_of_incorporation: Date
    transunion_amounts: number
    preapproved_incomes: number 
    incomes_extracts: number
    coverage: number
    truora_score: number
    seized_accounts: boolean
    credit_score_distributor: string
    annual_sales_distributor: number
    value: number

    static compilarDTO(formularioDto: FormularioDTO): FormularioDTO {
        formularioDto.annual_sales_distributor = Number(formularioDto.annual_sales_distributor) || 0;
        formularioDto.incomes_extracts = Number(formularioDto.incomes_extracts) || 0;
        formularioDto.preapproved_incomes = Number(formularioDto.preapproved_incomes) || 0;
        formularioDto.value = Number(formularioDto.value) || 0;
    
        return formularioDto;
    }
    

    static instancia () {
        
        return new FormularioDTO();
    }
}

    