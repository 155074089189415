import { useState, useEffect } from "react";
import HttpClientService from "http/httpClientService";
import { ConfigVariables } from "adapters/ports/config-variables";
import { useCognitoUserAttributes } from 'app/login/adapters/useCognitoUserAttributes';
 
const useGetDocuments = ()=>{
    const [documents, setDocuments] = useState(null);
    const {person_type, identity_type, identity_number} = useCognitoUserAttributes();

    useEffect(()=>{
        if(!documents && person_type && identity_type && identity_number){
            (async () => {
                try {
                    const apiBaseUrl = ConfigVariables.URL_BASE_API;

                    const getUserEndpoint = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}/documents`;
                    const response = await HttpClientService.get<string>(getUserEndpoint);
                    const stringifytext = JSON.stringify(response);
                    const parsetext = JSON.parse(stringifytext);

                    setDocuments(parsetext.documents);
                } catch (error) {
                    console.error('Error al obtener los documentos del usuario:', error);
                    window.location.href = "/error-500";
                    throw error;
                }
            })();
        }
    },[documents, person_type, identity_type, identity_number])

    return {documents};
}

export default useGetDocuments;
