import React from 'react';
import FieldProps from './textFieldProps';

const CheckboxField = ({ model, setModel, campoId, campoIdRespuesta, title, required, opcionTrue, opcionFalse, editable, onChange = () => {} }: FieldProps) => {
    const resume = model[campoIdRespuesta];
    const bgResp = model.approved ? 'bg-[#C6F6D5] text-[#22543D] w-auto px-1 h-[20px] text-center rounded-[2px]' : 'bg-[#FED7D7] text-[#822727] w-auto px-1 h-[20px] text-center rounded-[2px]';

    const handleChange = (value: boolean) => {
        setModel((prevModel : any) => ({
            ...prevModel,
            [campoId]: value
        }));
        onChange(value); 
    };

    return (
        <div className='w-auto'>
            <div className='flex items-center w-[820px]'>
                <div className='text-sm text-[#171923] font-semibold'>{title}</div>
                {!editable && resume !== undefined && (
                    <div className={`text-sm font-bold ml-2 ${bgResp}`}>{resume.toFixed(1)}</div>
                )}
            </div>
            <div className='mt-[30px] mb-[38px] text-md flex items-center'>
                <label className="flex items-center mr-[40px]">
                    <input
                        className='peer hidden'
                        type="radio"
                        name={campoId}
                        required={required}
                        checked={model[campoId] === true}
                        onChange={() => handleChange(true)}
                        disabled={!editable}
                    />
                    <div className={`w-[16px] h-[16px] border border-gray-300 rounded-full peer-checked:border-[5px] peer-checked:border-[#390094]`}></div>
                    <span className='ml-2 text-[#171923]'>{opcionTrue}</span>
                </label>
                <label className='flex items-center ml-24'>
                    <input
                        className='peer hidden'
                        type="radio"
                        required={required}
                        name={campoId}
                        checked={model[campoId] === false}
                        onChange={() => handleChange(false)}
                        disabled={!editable}
                    />
                    <div className={`w-[16px] h-[16px] border border-gray-300 rounded-full peer-checked:border-[5px] peer-checked:border-[#390094]`}></div>
                    <span className='ml-2 text-[#171923]'>{opcionFalse}</span>
                </label>
            </div>
        </div>
    );
};

export default CheckboxField;
