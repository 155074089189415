import LayoutDocumentos from "./layouts/layout-documentos";
import { useEffect, useState, useMemo } from "react";
import CardUploadFile from "app/components-finmaq/cardUploadFile";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import useDocumentState from "app/carga-documentos/utilities/useDocumentState";
import classNameByEvent from "app/carga-documentos/utilities/classNameByEvent";
import textsByRole from "app/carga-documentos/utilities/textsByRole";
import ModalGuardadoParcial from "app/components-finmaq/modal-guardado-parcial";
import { useCognitoUserAttributes } from 'app/login/adapters/useCognitoUserAttributes';
import { useGetContactWithProducts } from "app/carga-documentos/utilities/useGetContactWithProducts";
import { handleTagManagerSteps } from "app/utilities/gtm";
import { useHandleBeforeUnload } from "app/carga-documentos/utilities/useHandleBeforeUnload";
import { useHandleProductDone } from "app/carga-documentos/utilities/useHandleProductDone";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";

export default function DocumentosFiscales() {
    const { cognitoUUID, person_type } = useCognitoUserAttributes();
    const { fileStatus, setFileStatus, documentUrl:documentoFiscalUrl, setDocumentUrl:setDocumentoFiscalUrl, handleInput, handleSubmit } = useDocumentState();
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ hasChanges ] = useState(false);
    const [ isFileSaved, setIsFileSaved ] = useState(false);
    const { currentProduct } = useGetContactWithProducts();
    const productUUID = currentProduct["product_uuid" as keyof typeof currentProduct];
    const currentDocuments = currentProduct["documents" as keyof typeof currentProduct];

    const commercial_product_type =
        textsByRole[person_type as keyof typeof textsByRole]?.commercial_product_type;
    const commercial_document_title =
        textsByRole[person_type as keyof typeof textsByRole]?.commercial_document_title;
    const commercial_document_description_1 =
        textsByRole[person_type as keyof typeof textsByRole]?.commercial_document_description_1;
    const commercial_document_description_2 =
        textsByRole[person_type as keyof typeof textsByRole]?.commercial_document_description_2;

    const isAnyFieldFilled = useMemo(() => {
        return (fileStatus === uploadStatus.READY && !isFileSaved) || hasChanges;
    }, [fileStatus, hasChanges, isFileSaved]);

    const product_state = (currentProduct as unknown as { financial_product: {
        product_state: string
    } }).financial_product?.product_state;

    const { handleOpenModal } = useHandleBeforeUnload(isAnyFieldFilled, ()=> setIsModalOpen(true));

    useHandleProductDone(product_state);


    const handleButtonClick = () => {
        if (fileStatus === uploadStatus.READY || fileStatus === uploadStatus.ERROR) {
            handleSubmit(commercial_product_type);
            setIsFileSaved(true);

            handleTagManagerSteps({
                action: "complete",
                cognitoUserId: cognitoUUID,
                productId: productUUID
            })
        }
    };

    useEffect(() => {
        if(currentDocuments?.rut?.file_path || currentDocuments?.camara_comercio?.file_path) setDocumentoFiscalUrl(currentDocuments?.rut?.file_path || currentDocuments?.camara_comercio?.file_path); 

    }, [currentDocuments, setDocumentoFiscalUrl]);

    useEffect(()=>{
        if(cognitoUUID && productUUID) {
            handleTagManagerSteps({
                action: "enter",
                cognitoUserId: cognitoUUID,
                productId: productUUID
            })
        }
    },[cognitoUUID, productUUID])

    return (
        <LayoutDocumentos
            handleButtonClick={handleButtonClick}
            buttonLabel="Guardar"
            buttonClassName={classNameByEvent(fileStatus)}
            hasFooter={true}>
            <div className={`${!currentDocuments ? `skeleton-animation` : ``}`}>
                <div className="hidden lg:flex text-[#718096] ml-[105px] mr-[105px] text-md font-normal">
                    <span className="skeleton-pulse">Solicitud de crédito </span>
                    <span className="ml-1 text-[#000000] skeleton-pulse"> / {commercial_document_title}</span>
                </div>
                <div className="lg:mt-7 lg:ml-14 flex items-center">
                    {isModalOpen && (
                        <ModalGuardadoParcial
                            onClose={()=> setIsModalOpen(false)}
                            onHandleProp={handleButtonClick}
                        />
                    )}
                    <button aria-label="Abrir modal" onClick={handleOpenModal}>
                        <svg
                            className="text-[#390094]"
                            width={32}
                            height={32}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15 18L9 12L15 6"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <p className="skeleton-pulse text-start text-[#390094] font-bold text-xl ml-2 lg:font-semibold lg:text-2xl lg:ml-[15px]">
                        {commercial_document_title}
                    </p>
                </div>
                <div className="text-[#171923] text-md font-normal lg:ml-[105px] lg:mr-[105px] lg:mt-2 mt-7 mb-7 lg:mb-0 leading-6 lg:leading-normal w-full lg:w-auto">
                    <p className="skeleton-pulse">{commercial_document_description_1}</p>
                </div>
                
                <div className="flex flex-col-reverse lg:flex-row lg:pl-[105px] lg:pr-[105px] lg:gap-11">
                    <CardUploadFile
                        key={documentoFiscalUrl}
                        fileLimit={4928307}
                        fileStatus={fileStatus}
                        fileName={documentoFiscalUrl}
                        className={"lg:w-full"}
                        description={
                            <ReactMarkdown 
                                children={commercial_document_description_2} 
                                remarkPlugins={[remarkGfm]} 
                                className="lg:mt-[75px] skeleton-pulse"/>
                        }
                        handleInput={handleInput}
                        setFileStatus={setFileStatus}
                        handleSubmit={handleButtonClick}
                    />
                </div>
            </div>
        </LayoutDocumentos>
    );
}
